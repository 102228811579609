import axios from 'axios';

const handleLogout = async () => {
  try {
    const promises = [];
    promises.push(
      axios.get(`${process.env.REACT_APP_SSO_URL}/api/auth/logout`, {
        withCredentials: true,
      }),
      axios.get('/api/auth/logout'),
    );
    await Promise.all(promises);
  } finally {
    window.location.assign(setRedirectURL());
  }
};

const setRedirectURL = () => {
  switch (process.env.NODE_ENV) {
    case 'development':
      return 'http://localhost:3601';
    case 'staging':
      return 'https://student-portal-staging.devmountain.com';
    default:
      return 'https://www.devmountain.com';
  }
};

export { handleLogout };
