import React from 'react';
import StyledSection from '../Styles/StyledSection';
import styled from 'styled-components';
import { SectionContainer, Content } from './Contact';
import SaveAndContinue from '../Shared/SaveAndContinue';

export default function ConsultationSkeleton({ children, allowProgress }) {
  return (
    <ModifiedSection>
      <SectionContainer>{children}</SectionContainer>
      <SectionContainer>
        <Content>
          <SaveAndContinue
            asLink
            to={allowProgress ? '../skill-review' : `/dashboard`}
            closeVariant={!allowProgress}
          />
        </Content>
      </SectionContainer>
    </ModifiedSection>
  );
}

const ModifiedSection = styled(StyledSection)`
  @media (min-width: 1024px) {
    padding-right: 0;
  }
`;
