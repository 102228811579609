import styled from 'styled-components';

const StyledP = styled.p`
  line-height: 1.75rem;
  color: ${props => (props.error ? 'red' : 'rgba(85, 85, 85, 1)')};
  ${props =>
    props.bold
      ? `
  font-family: ${props.theme.typography.primary};
  font-weight: 600;
  `
      : null}
  
  ${props => (props.spacing ? `letter-spacing: ${props.spacing}` : null)}
  ${props => (props.fontSize ? `font-size: ${props.fontSize};` : null)}
  ${({ variant }) =>
    variant === 'paragraph'
      ? `
  margin-bottom: 1rem;
    line-height: 1.25rem;
    letter-spacing: .00625rem;
  `
      : null}
  ${props =>
    props.marginBottom ? `margin-bottom: ${props.marginBottom}` : null}
`;

export const Paragraph = styled(StyledP)`
  b {
    font-weight: 700;
  }
`;

export default StyledP;
