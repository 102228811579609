import gql from 'graphql-tag';

export const GET_COHORT = gql`
  query GetCohort($id: String!) {
    cohort(id: $id) {
      Type__c
      Id
      Date_End__c
      Date_Start__c
      Subject__c
      Name
      formattedStartDate
      formattedEndDate
      Location_City__c
      Location_State__c
      locationName
      isRemote
      Price__c
      programName
      Admissions_Consultation_Booking_Link__c
    }
  }
`;

export const GET_COHORTS = gql`
  query GetCohorts {
    cohorts {
      Id
      Subject__c
      Name
      formattedStartDate
      formattedEndDate
      Location_City__c
      Location_State__c
      Price__c
      programName
    }
  }
`;

export const GET_USER = gql`
  query GetUser {
    user {
      id
      email
      first_name
      last_name
      sfId
      slackUsername
    }
  }
`;

export const GET_APPLICATION = gql`
  query GetApplication($id: String!) {
    user {
      id
      sfId
      slackUsername
      first_name
      last_name
      email
    }
    application(id: $id) {
      approachDashboard
      Status__c
      viewedDecision
      skillReviewLink
      currentStep
      complete {
        personalInfo
        consultation
        skillReviewSubmitted
        skillReviewGraded
      }
      consultation {
        link
        email
        time
        timezone
        held
        scheduled
      }
      enrollment {
        id
        applicationId
        cohort {
          Type__c
          Id
          Date_End__c
          Date_Start__c
          Subject__c
          Name
          formattedStartDate
          formattedEndDate
          Location_City__c
          Location_State__c
          Price__c
          programName
          Admissions_Consultation_Booking_Link__c
        }
        precourseCompleted
        housingStatus
        signedStudentAgreement
        signedHousingAgreement
        joinedDiscord
        createdDate
        payments {
          method
          tuition {
            acknowledged
            complete
            amount
            due
            outstanding
          }
          deposit {
            acknowledged
            complete
            due
            amount
          }
          history {
            id
            date
            category
            amount
            method
          }
        }
      }
      cohortId
      cohort {
        Type__c
        Id
        Date_End__c
        Date_Start__c
        Subject__c
        Name
        formattedStartDate
        formattedEndDate
        Location_City__c
        Location_State__c
        Price__c
        programName
        locationName
        Admissions_Consultation_Booking_Link__c
        isRemote
      }
      id
      ## Contact
      applicantType
      mailingAddress1
      city
      state
      region
      postalCode
      zipCode
      country
      phone
      birthday
      genderIdentityAcknowledgement
      ## education
      highestEducation
      fieldOfStudy
      experience {
        htmlCss
        javascript
        python
        react
        sql
        flask
        computerScience
      }
      hoursSpent
      hoursSpentType
      ## experience
      linkedIn
      workExperience {
        position
        duration
        description
      }
      ## goals
      hearAbout {
        currentStudent
        alumna
        instructorStaff
        womenWhoCode
        google
        articleBlog
        event
        advertisement
        facebookTwitter
        linkedIn
        currentEmployer
        other
        otherDetail
      }
      goals
      acknowledgement
    }
    user {
      id
      sfId
    }
  }
`;

export const INITIAL_DATA = gql`
  query {
    cohorts {
      Id
      Subject__c
      Name
      formattedStartDate
      formattedEndDate
      Location_City__c
      Location_State__c
      Price__c
      programName
    }
    user {
      id
      first_name
      last_name
      email
      sfId
    }
  }
`;

export const GET_APPLICATION_COMPLETION = gql`
  query GetApplicationCompletion($id: String!) {
    application(id: $id) {
      id
      complete {
        personalInfo
        consultation
        skillReviewGraded
      }
    }
  }
`;

export const GET_SEAT_DEPOSIT_DUE_DATE = gql`
  query GetSeatDepositDueDate($id: String!) {
    application(id: $id) {
      enrollment {
        payments {
          deposit {
            due
          }
        }
      }
    }
  }
`;

export const GET_AGREEMENT = gql`
  query GetAgreement($enrollmentId: String!) {
    document(enrollmentId: $enrollmentId) {
      isComplete
      signatureId
      signatureRequestId
      template {
        templateName
        templateId
        description
        state
        type
      }
    }
  }
`;

export const GET_SIGNATURE_URL = gql`
  query GetSignatureUrl($signatureId: String!) {
    embeddedHelloSign(signatureId: $signatureId) {
      url
    }
  }
`;

export const VIEW_STUDENT_AGREEMENT = gql`
  query ViewStudentAgreement($signatureRequestId: String!) {
    helloSignAgreement(signatureRequestId: $signatureRequestId) {
      fileUrl
    }
  }
`;

export const GET_APPLICATIONS = gql`
  query GetApplications {
    user {
      id
      email
      first_name
      last_name
      sfId
      applications {
        id
        cohortId
        cohort {
          Id
          formattedEndDate
          formattedStartDate
          Location_City__c
          Location_State__c
          Name
          programName
          Subject__c
          isRemote
          locationName
        }
        complete {
          personalInfo
          consultation
          skillReviewSubmitted
          skillReviewGraded
        }
        Status__c
        enrollment {
          id
          createdDate
          signedHousingAgreement
          signedStudentAgreement
          onboardingUpdated
          precourseCompleted
          joinedDiscord
          payments {
            method
            deposit {
              acknowledged
              complete
            }
            tuition {
              acknowledged
              complete
            }
          }
        }
        viewedDecision
        skillReviewLink
      }
    }
  }
`;

export const fragments = {
  application: {
    contact: gql`
      fragment Contact on Application {
        ## Contact
        applicantType
        mailingAddress1
        city
        state
        region
        postalCode
        zipCode
        country
        phone
        birthday
        genderIdentityAcknowledgement
      }
    `,
    education: gql`
      fragment Education on Application {
        ## education
        highestEducation
        fieldOfStudy
        experience {
          htmlCss
          javascript
          python
          react
          sql
          flask
          computerScience
        }
      }
    `,
    experience: gql`
      fragment Experience on Application {
        ## experience
        linkedIn
        workExperience {
          position
          duration
          description
        }
      }
    `,
    goals: gql`
      fragment Goals on Application {
        ## goals
        hearAbout {
          currentStudent
          alumna
          instructorStaff
          womenWhoCode
          google
          articleBlog
          event
          advertisement
          facebookTwitter
          linkedIn
          currentEmployer
          other
          otherDetail
        }
        goals
        acknowledgement
      }
    `,
    completion: gql`
      fragment Completion on Application {
        complete {
          personalInfo
          consultation
          skillReviewGraded
        }
      }
    `,
  },
};

export const GET_APPLICATION_DRAFT = gql`
  query GET_APPLICATION_DRAFT {
    applicationDraft
  }
`;
