import React from 'react';
// import { genRandId } from '../utils/jsFunctions';

export default function DropPin(props) {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 14 21"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      //   xmlns:xlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <title>location-pin-2</title>
      <desc>Created with Sketch.</desc>
      <g
        id="svgs"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        style={{ color: props.active ? 'rgba(14,182,254,1)' : 'inherit' }}
      >
        <g
          id="not-yet-uploaded"
          transform="translate(-157.000000, -216.000000)"
        >
          <g id="location-pin-2" transform="translate(152.000000, 214.000000)">
            <g id="Frame_-_24px">
              <rect
                id="Rectangle"
                x="0.001"
                y="0.001"
                width="24"
                height="24.001"
              />
            </g>
            <g
              id="Line_Icons"
              transform="translate(5.000000, 2.000000)"
              fill="currentcolor"
              fillRule="nonzero"
            >
              <g id="Group">
                <path
                  d="M7,10 C5.346,10 4,8.654 4,7 C4,5.346 5.346,4 7,4 C8.654,4 10,5.346 10,7 C10,8.654 8.654,10 7,10 Z M7,6 C6.449,6 6,6.449 6,7 C6,7.552 6.449,8 7,8 C7.551,8 8,7.552 8,7 C8,6.449 7.551,6 7,6 Z"
                  id="Shape"
                />
                <path
                  d="M7,20.667 C4.641,17.52 0,10.936 0,7 C0,3.14 3.14,0 7,0 C10.86,0 14,3.14 14,7 C14,10.936 9.359,17.521 7,20.667 Z M7,2 C4.243,2 2,4.243 2,7 C2,9.591 5.107,14.583 7,17.291 C8.892,14.582 12,9.589 12,7 C12,4.243 9.757,2 7,2 Z"
                  id="Shape"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
