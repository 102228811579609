import * as Sentry from '@sentry/browser';

export const reportCustomMessage = (
  message,
  level = 'info',
  customTags = {},
) => {
  // ignore envs but prod
  if (process.env.NODE_ENV !== 'production')
    return console.log(`Ignoring sentry message: ${message}`);
  customTags.environment = process.env.NODE_ENV;
  // ‘fatal’, ‘error’, ‘warning’, ‘info’, and ‘debug’.
  // 'warning', 'error' and 'fatal' are reported to slack channels
  Sentry.withScope(scope => {
    scope.setLevel(level);
    // add any custom tags that were sent through
    Object.keys(customTags).forEach(key => {
      scope.setTag(key, customTags[key]);
    });
    Sentry.captureMessage(message);
  });
};
