import React, { useContext, useState } from 'react';

export const ValidationContextValues = React.createContext({
    errors: {},
    completion: {},
});

export const ValidationContextDispatch = React.createContext({
    setErrors: () => false,
    setCompletion: () => false,
});

export default function ValidationProvider(props) {
    const [errors, setErrors] = useState({});
    const [completion, setCompletion] = useState({});



    return <ValidationContextDispatch.Provider value={{
        setCompletion,
        setErrors: (section, value) =>
            setErrors({ ...errors, [section]: value })
    }}>
        <ValidationContextValues.Provider value={{ errors, completion }}>
            {props.children}</ValidationContextValues.Provider>
    </ValidationContextDispatch.Provider>
}

export function useValidation() {
    const values = useContext(ValidationContextValues)
    const dispatch = useContext(ValidationContextDispatch)
    return { ...values, ...dispatch }
}