import React from 'react';
import PreCourseTodo from './PreCourseTodo';
import DashboardNavigation from '../Shared/DashboardNavigation';
import PreCourseWebDevCard from './PreCourseWebDevCard';
import FirstDay from './FirstDay';
import EquipmentCard from '../Onboarding/EquipmentCard';
import StyledSection from '../Styles/StyledSection';
import { Row } from '../FinanceCenter/FinanceCenter';
import { useApplicationId } from '../Shared/ApplicationProvider';
import { useCurrentApplication } from '../utils/hooks';
import _get from 'lodash/get';

export default function PrecourseWork() {
  const id = useApplicationId();
  const { cohort } = useCurrentApplication();
  const isRemote = _get(cohort, 'data.isRemote');
  return (
    <StyledSection variant="dashboard">
      <DashboardNavigation section="Pre-Course Work" />
      <Row>
        <PreCourseTodo applicationId={id} />
      </Row>
      <Row>
        <PreCourseWebDevCard applicationId={id} />
        <EquipmentCard remote={isRemote} />
      </Row>
      {isRemote && (
        <Row>
          <FirstDay />
        </Row>
      )}
    </StyledSection>
  );
}
