import React from 'react';
import StyledH2 from '../Styles/StyledH2';
import { Dmlink } from '../Styles/StyledA';
import { Paragraph } from '../Styles/StyledP';
import { Content } from './Contact';

export default function ApplicationWithdrawn() {
  return (
    <Content>
      <StyledH2 desktopAlign="left">Application Withdrawn</StyledH2>
      <Paragraph variant="paragraph">
        We were sad to see you go, but these things happen.
        <br />
        Applications are usually withdrawn at the request of an applicant, when
        a deadline passes, or when we've lost contact. If you believe your
        application was withdrawn in error or you want to reactivate it, please
        contact{' '}
        <Dmlink href="mailto:admissions@hackbrightacademy.com">
          admissions@hackbrightacademy.com
        </Dmlink>
        .
        <br />
        <br />
        Stay safe out there.
      </Paragraph>
    </Content>
  );
}
