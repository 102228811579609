import React from 'react';
import Card from '../Shared/Card';
import CardSection from '../Styles/CardSection';
import FinanceAcknowledgement from './FinanceAcknowledgement';
import PaymentMethod from './PaymentMethod';

export default function AcknowledgementsAndPaymentMethod(props) {
  return (
    <Card
      span={1 / 2}
      header="acknowledgements"
      defaultExpanded
      id="acknowledgements"
    >
      <CardSection>
        <FinanceAcknowledgement id={props.id} />
        <PaymentMethod id={props.id} />
      </CardSection>
    </Card>
  );
}
