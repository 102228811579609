import React, { useEffect } from 'react';
import _get from 'lodash/get';
import StyledSection from '../Styles/StyledSection';
import { useCurrentApplication } from '../utils/hooks';

export default function ApplicationLanding(props) {
  const { loading, data, error } = useCurrentApplication();

  const application = _get(data, 'application', {});
  const complete = _get(application, 'complete', {});
  const {
    personalInfo,
    consultation,
    skillReviewSubmitted,
    skillReviewGraded,
  } = complete;

  useEffect(() => {
    // don't redirect until we know where to go
    if (error || loading || !complete) {
      return;
    }

    // default to personal-info if it's not complete
    if (!personalInfo) {
      props.navigate('personal-info', { replace: true });
      return;
    }

    // personal-info is complete, so consultation should be "next"
    // if it isn't already done yet too
    if (!consultation) {
      props.navigate('consultation', { replace: true });
      return;
    }

    // personal-info and consultation are complete, so skill-review is the only thing left
    if (!skillReviewSubmitted && !skillReviewGraded) {
      props.navigate('skill-review', { replace: true });
      return;
    }

    // if everything is complete, it doesn't really matter where we redirect
    // as long as it is something after the :id/
    props.navigate('you-did-it', { replace: true });
    return;
  });

  if (error) {
    return (
      <StyledSection>
        An unknown error occurred. Please try again.
      </StyledSection>
    );
  }

  return null;
}
