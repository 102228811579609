import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import Card from '../Shared/Card';
import SFCampusInfo from './SFCampusInfo';
import Loading from '../Shared/Loading';
import _get from 'lodash/get';

export const GET_CAMPUS_INFO = gql`
  query GetCampusInfo($id: String!) {
    application(id: $id) {
      id
      enrollment {
        id
        cohort {
          Id
          Location_City__c
        }
      }
    }
  }
`;

export default function CampusInfoWidget({ applicationId }) {
  const { loading, error, data } = useQuery(GET_CAMPUS_INFO, {
    variables: { id: applicationId },
  });
  let displayCampus;
  if (loading) {
    return <Loading />;
  }
  if (error) console.dir(error);
  const city = _get(data, 'application.enrollment.cohort.Location_City__c', '');

  switch (city) {
    case 'San Francisco':
      displayCampus = <SFCampusInfo />;
      break;
    default:
      return <Loading />;
  }
  return (
    <Card span={1 / 3} header={`campus information - ${city}`} defaultExpanded>
      {displayCampus}
    </Card>
  );
}
