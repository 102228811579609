import styled from 'styled-components';
import SuccessIconComponent from '../img/SuccessIcon';

export const SuccessIcon = styled(SuccessIconComponent)`
  color: ${({ theme }) => theme.colors.midgreen};
  @media (min-width: 1024px) {
    height: 12px;
    width: 12px;
  }
`;

export const IconDiv = styled.div`
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0.5rem;
`;
