import React from 'react';
import styled from 'styled-components';
import { Dmlink } from '../Styles/StyledA';
import StyledP from '../Styles/StyledP';
import { useLocation } from '@reach/router';

export default function SupportWidget() {
  const location = useLocation();
  if (['/', '/dashboard'].indexOf(location.pathname) === -1) return null;
  return (
    <SecondaryHeader>
      <StyledP fontSize="13px">
        Hey there! This site's been improved recently for your benefit, but if
        you do happen to run into a bug, please reach out to{' '}
        <Dmlink href="mailto:support@devmounta.in">support@devmounta.in</Dmlink>{' '}
        so we can help.
      </StyledP>
    </SecondaryHeader>
  );
}

const SecondaryHeader = styled.div`
  padding: 1rem;
  background-color: transparent;
`;
