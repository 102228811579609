import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import AccountStatus from './AccountStatus';
import AccountPersonalInfo from './AccountPersonalInfo';
import AccountProfileOptions from './AccountProfileOptions';
import Card from '../Shared/Card';
import StyledSection from '../Styles/StyledSection';
import DashboardNavigation from '../Shared/DashboardNavigation';
import _get from 'lodash/get';

export const GET_USER_NAME = gql`
  query GetUserName {
    user {
      id
      first_name
      last_name
    }
  }
`;

export default function Account(props) {
  const { data } = useQuery(GET_USER_NAME);
  const firstName = _get(data, 'user.first_name', '');
  const lastName = _get(data, 'user.last_name', '');
  return (
    <StyledSection>
      <DashboardNavigation section="Account Center" />
      <Card header={`${firstName} ${lastName}`} collapsable={false}>
        <AccountStatus />
        <AccountPersonalInfo />
        {/* Account logout and reset password */}
        <AccountProfileOptions />
      </Card>
    </StyledSection>
  );
}
