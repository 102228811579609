import gql from 'graphql-tag';

export const CREATE_APPLICATION = gql`
  mutation CreateApplication($application: ApplicationInput!) {
    createApplication(application: $application)
  }
`;

export const CREATE_USER = gql`
  mutation CreateUser(
    $firstName: String!
    $lastName: String!
    $email: String!
    $password: String!
  ) {
    createUser(
      firstName: $firstName
      lastName: $lastName
      email: $email
      password: $password
    ) {
      id
      first_name
      last_name
      email
      sfId
    }
  }
`;

export const SAVE_APPLICATION = gql`
  mutation SaveApplication($application: ApplicationInput!) {
    saveApplication(application: $application) {
      skillReviewLink
      complete {
        personalInfo
        consultation
        skillReviewSubmitted
        skillReviewGraded
      }
      enrollment {
        id
      }
      id
      ## Contact
      applicantType
      mailingAddress1
      city
      state
      region
      postalCode
      zipCode
      country
      phone
      birthday
      genderIdentityAcknowledgement
      ## education
      highestEducation
      fieldOfStudy
      experience {
        htmlCss
        javascript
        python
        react
        sql
        flask
        computerScience
      }
      hoursSpent
      hoursSpentType
      ## experience
      linkedIn
      workExperience {
        position
        duration
        description
      }
      ## goals
      hearAbout {
        currentStudent
        alumna
        instructorStaff
        womenWhoCode
        google
        articleBlog
        event
        advertisement
        facebookTwitter
        linkedIn
        currentEmployer
        other
      }
      goals
      acknowledgement
    }
  }
`;

export const SAVE_APPLICATION_DRAFT = gql`
  mutation SaveApplicationDraft($draft: String!) {
    saveApplicationDraft(draft: $draft)
  }
`;
