import React, { useContext } from 'react';
import StyledSection from '../Styles/StyledSection';
import DashboardNavigation from '../Shared/DashboardNavigation';
import HousingWidget from './HousingWidget';
import AgreementWidget from './AgreementWidget';
import StudentDirectoryWidget from './StudentDirectoryWidget';
import SlackSignUpWidget from './SlackSignUpWidget';
import Discord from './Discord';
import CampusInfoWidget from './CampusInfoWidget';
import TransportationWidget from './TransportationWidget';
import OnboardingTodoList from './OnboardingToDoList';
import { useCurrentApplication } from '../utils/hooks';
import { Row } from '../FinanceCenter/FinanceCenter';
import { MediaQueryContext } from '../Shared/MediaQueryProvider';
import { useApplicationId } from '../Shared/ApplicationProvider';
import _get from 'lodash/get';

export default function Onboarding() {
  const { data, cohort } = useCurrentApplication();

  const id = useApplicationId();
  const enrollmentId = _get(data, 'application.enrollment.id', '');
  const isDesktop = useContext(MediaQueryContext);
  const isRemote = _get(cohort, 'data.isRemote');
  return (
    <StyledSection variant="dashboard">
      <DashboardNavigation section="Onboarding" />
      <Row style={{ position: 'relative' }}>
        <OnboardingTodoList applicationId={id} remote={isRemote} />
        {isDesktop && <StudentDirectoryWidget applicationId={id} />}
      </Row>
      <Row>
        <AgreementWidget id={_get(data, 'application.enrollment.id', '')} />
      </Row>
      <Row>
        <SlackSignUpWidget applicationId={id} />
        {isRemote && <Discord enrollmentId={enrollmentId} applicationId={id} />}
        {!isDesktop && <StudentDirectoryWidget applicationId={id} />}
      </Row>
      {!isRemote && (
        <Row>
          <CampusInfoWidget applicationId={id} />
          <TransportationWidget applicationId={id} />
        </Row>
      )}
    </StyledSection>
  );
}
