import React from 'react';
import styled from 'styled-components';
import Card from '../Shared/Card';
import CardSection from '../Styles/CardSection';
import StyledP from '../Styles/StyledP';
import Button from '../Styles/StyledButton';
import PreCourseButton from './PreCourseButton';
// import StyledA from '../Styles/StyledA';
// import ArrowRight from '../img/ArrowRight';

export default function PreCourseWebDevCard({ applicationId }) {
  return (
    <Card
      span={1 / 2}
      header="pre-course-web development"
      defaultExpanded
      id="pre-course-web-dev"
    >
      <CardSection>
        <ParagraphTag>
          In order to help students make the most of their experience at
          Hackbright Academy, we have put together some pre-course material that will
          helplay the foundation for your learning.
        </ParagraphTag>
        <ParagraphTag>
          This content will intoduce you to the basics of HTML, CSS, and
          Javascript. It takes approximately 5 to 10 hours to work through. We
          encourage all students to complete the pre-course material before
          starting class but it is not required.
        </ParagraphTag>
        <Button
          variant="call-to-action"
          rightIcon
          href="https://precourse.devmountain.com"
          as="a"
          target="_blank"
          rel="noopener noreferrer"
        >
          Hackbright Academy Pre-course
        </Button>
        <ParagraphTag>
          Already finished your pre-course work? Good for you! Let us know about
          it.
        </ParagraphTag>
        <PreCourseButton applicationId={applicationId}>
          Pre-course Completed
        </PreCourseButton>
      </CardSection>
    </Card>
  );
}

const ParagraphTag = styled(StyledP)`
  line-height: 1.25rem;
  letter-spacing: 0.0125rem;
  font-size: 0.895rem;
  margin: 1rem 0;
`;

// const ButtonContainer = styled(StyledA)`
//   text-decoration: none;
//   color: white;
//   &:focus {
//     outline: none;
//   }
// `;

// const PreCourseButton = styled(UnstyledButton)`
//   background: ${({ theme }) => theme.palette.secondary.main};
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   border-radius: 0.1875rem;
//   color: white;
//   width: 75%;
//   padding: 0.75rem;
//   padding-left: 1.2rem;
//   margin: 1.5rem 0;
//   font-size: 0.875rem;
//   font-weight: 600;
//   line-height: 1rem;
//   letter-spacing: 0.01625rem;
// `;

// const ArrowImg = styled(ArrowRight)`
//   margin-left: 0.75rem;
// `;
