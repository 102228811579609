import React from 'react';
import styled from 'styled-components';
import gql from 'graphql-tag';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { StandardButton } from '../Styles/StyledButton';
import SuccessIcon from '../img/SuccessIcon';
import Loading from '../Shared/Loading';
import _get from 'lodash/get';

export const GET_PRECOURSE_STATUS = gql`
  query GetPreCourseStatus($applicationId: String!) {
    application(id: $applicationId) {
      id
      enrollment {
        id
        precourseCompleted
      }
    }
  }
`;

const UPDATE_PRECOURSE_COMPLETED = gql`
  mutation UpdatePreCourseCompleted($updates: EnrollmentInput!) {
    updateEnrollment(updates: $updates)
  }
`;

export default function PreCourseButton({ applicationId, children }) {
  const { loading, error, data } = useQuery(GET_PRECOURSE_STATUS, {
    variables: { applicationId },
  });
  const [updatePrecourseStatus] = useMutation(UPDATE_PRECOURSE_COMPLETED);
  if (loading) return <Loading />;
  if (error) console.dir(error);

  return (
    <PrecourseButton
      onClick={() => {
        if (!_get(data, 'application.enrollment.precourseCompleted')) {
          updatePrecourseStatus({
            variables: {
              updates: {
                id: data.application.enrollment.id,
                precourseCompleted: true,
              },
            },
            refetchQueries: ['GetApplication'],
          });
        }
      }}
      as="a"
      variant={
        _get(data, 'application.enrollment.precourseCompleted')
          ? 'complete'
          : 'secondary'
      }
    >
      <span style={{ padding: '.125rem 0.55625rem' }}>{children}</span>
      <SuccessIcon width="0.75rem" />
    </PrecourseButton>
  );
}

const PrecourseButton = styled(StandardButton)`
  padding: 0.65625rem 1rem;
  border-radius: 1.09375rem;
  font-size: 0.875rem;
  margin-bottom: 1.25rem;
`;
