import React from 'react';
import styled from 'styled-components';
import { Link } from '@reach/router';
import Card from '../Shared/Card';
import StarIcon from '../img/StarIcon';
import StyledButton from '../Styles/StyledButton';

export default function AdmissionConditionalAcceptance(props) {
  return (
    <Card header="you can do it!" collapsable={false} icon={StarIcon}>
      <CardSection>
        <Paragraph>
          Thank you for applying. There may be a few more things you&apos;ll
          want to practice before you enroll. Your admissions counselor will be
          in touch with next steps.
        </Paragraph>

        <div style={{ padding: '.5rem 0 2rem 0' }}>
          <StyledButton variant="secondary" leftIcon as={Link} to="..">
            back to dashboard
          </StyledButton>
        </div>
      </CardSection>
    </Card>
  );
}

const CardSection = styled.section`
  padding: 0 1rem;
  line-height: 1.375rem;
  font-size: 0.875rem;
  em {
    display: block;
    padding: 1rem 0;
    font-weight: 600;
    color: ${({ theme }) => theme.colors.darkestgray};
  }
`;

const Paragraph = styled.p`
  margin-bottom: 1rem;
`;
