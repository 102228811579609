import React from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import styled from 'styled-components';
import Card from '../Shared/Card';
import CardSection from '../Styles/CardSection';
import ArrowRight from '../img/ArrowRight';
import slackLogo from '../img/slack.svg';
import StyledA from '../Styles/StyledA';
import { GET_USER } from '../Graphql/queries';
import _get from 'lodash/get';

export const GET_APPLICATION_AND_ENROLLMENT_IDS = gql`
  query GetIds($applicationId: String!) {
    user {
      id
      sfId
    }
    application(id: $applicationId) {
      id
      enrollment {
        id
        cohort {
          Location_City__c
        }
      }
    }
  }
`;

const UPDATE_SLACK_USERNAME = gql`
  mutation UpdateSlackUsername($id: String!, $updates: ContactInput) {
    updateContact(id: $id, updates: $updates) {
      id
      slackUsername
    }
  }
`;

const UPDATE_ONBOARDING = gql`
  mutation UpdateOnboarding($id: String!) {
    updateOnboarding(id: $id)
  }
`;

const channels = {
  Lehi: ['#lehi-campus'],
  Dallas: ['#dallas', '#dallas-campus'],
  Phoenix: ['#phoenix'],
};

export default function SlackSignUpWidget({ applicationId }) {
  const displaySlackLogo = () => <SlackLogo src={slackLogo} />;
  const [updateOnboarding] = useMutation(UPDATE_ONBOARDING);
  const [mutate] = useMutation(UPDATE_SLACK_USERNAME, {
    update(cache, { data }) {
      const { user } = cache.readQuery({ query: GET_USER });
      cache.writeQuery({
        query: GET_USER,
        data: {
          user: { ...user, slackUsername: data.updateContact.slackUsername },
        },
      });
    },
  });
  const { data } = useQuery(GET_APPLICATION_AND_ENROLLMENT_IDS, {
    variables: { applicationId },
  });
  return (
    <Card
      span={1 / 3}
      header="join slack"
      defaultExpanded
      icon={displaySlackLogo}
      id="join-slack"
    >
      <CardSection>
        <Paragraph>
          Instructor-to-student, and student-to-student communication will be
          vital to your success at Hackbright Academy. We heavily rely on Slack for
          dispersing information you’ll need throughout your course.
        </Paragraph>
        <Paragraph>
          Please follow the link below to join the Hackbright Academy Student
          Workspace. Make sure you are added to your course channel and we also
          suggest adding
          <SlackChannel> #lobby</SlackChannel>,
          <SlackChannel> #welcome</SlackChannel>
          {(
            channels[
              _get(data, 'application.enrollment.cohort.Location_City__c', '')
            ] || []
          ).map((channel, i, arr) => (
            <React.Fragment key={`${channel}-${i}`}>
              {i === arr.length - 1 ? ', and ' : ', '}
              <SlackChannel>{channel}</SlackChannel>
            </React.Fragment>
          ))}{' '}
          too.
        </Paragraph>
        <JoinSlackButton
          onClick={() => {
            if (_get(data, 'user.sfId')) {
              mutate({
                variables: {
                  id: data.user.sfId,
                  updates: {
                    slackUsername: 'TRUE',
                  },
                },
              });
              updateOnboarding({
                variables: { id: data.application.enrollment.id },
              });
            }
          }}
          as="a"
          target="_blank"
          href="https://join.slack.com/t/devmtn/shared_invite/enQtNTM4NTU5NjIwNjExLWNhMTBiNTQ2MDc4MWU1NGI0ZjU3MWY5NDNkMmViN2VhZDA3ZjE2NWIzMTFhNTIxM2Q1ZTczMDlmZGZjNDRhZDE"
        >
          join slack
          <ArrowImg />
        </JoinSlackButton>
      </CardSection>
    </Card>
  );
}

const SlackLogo = styled.img`
  width: 2.125rem;
  margin-right: 0.1875rem;
`;

const Paragraph = styled.p`
  margin-bottom: 1rem;
  line-height: 1.25rem;
  letter-spacing: 0.00625rem;
`;

const JoinSlackButton = styled(StyledA)`
  font-family: inherit;
  font-size: 0.875rem;
  overflow: visible;
  width: 141px;
  padding: 0.65625rem 1rem;
  background: ${({ theme }) => theme.palette.secondary.main};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.1875rem;
  color: white;
  margin: 1.5rem 0;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1rem;
  letter-spacing: 0.0175rem;
  text-transform: uppercase;
  text-decoration: none;
  color: white;

  &:focus {
    outline: none;
  }
`;

const ArrowImg = styled(ArrowRight)`
  margin-left: 0.5625rem;
  width: 0.9375rem;
`;

const SlackChannel = styled.span`
  font-weight: bold;
  font-style: italic;
`;
