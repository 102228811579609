import React from 'react';
import StyledSecction from '../Styles/StyledSection';
import DashboardNavigation from '../Shared/DashboardNavigation';
import CelebrateCard from './CelebrateCard';

export default function Party(props) {
  return (
    <StyledSecction variant="dashboard">
      {/* This div is here because StyledSection stretches
      the whole height of the screen and uses space-between
      to make sure the nav buttons are always at the bottom
      of the screen. */}
      <div>
        <DashboardNavigation section="Party!" />
        <CelebrateCard />
      </div>
    </StyledSecction>
  );
}
