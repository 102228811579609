// https://github.com/jaredpalmer/formik/issues/146#issuecomment-458588962

import React from 'react';
import { connect } from 'formik';

const isObject = value =>
  value && typeof value === 'object' && value.constructor === Object;

class ErrorFocus extends React.Component {
  componentDidUpdate(prevProps) {
    const { isSubmitting, isValidating, errors } = prevProps.formik;
    const keys = Object.keys(errors);

    if (keys.length > 0 && isSubmitting && !isValidating) {
      const selectorKey = this.getKeysRecursively(errors);
      const selector = `[id="${selectorKey}"], [name="${selectorKey}"] `;
      const errorElement = document.querySelector(selector);

      if (errorElement) {
        errorElement.focus();
      }
    }
  }

  getKeysRecursively = object => {
    if (!isObject(object) && !Array.isArray(object)) {
      return '';
    }
    const currentKey = Object.keys(object)[0];
    if (!this.getKeysRecursively(object[currentKey])) {
      return currentKey;
    }
    return `${currentKey}.${this.getKeysRecursively(object[currentKey])}`;
  };

  render() {
    return null;
  }
}
export default connect(ErrorFocus);
