import React from 'react';
import ReactModal from 'react-modal';
import {
  modalStyle,
  ModalP,
  ModalButton,
  ModalButtonRow,
} from '../Styles/modalStyles';

export default function DepositModal(props) {
  const { showModal, setShowModal, submitForm } = props;
  return (
    <ReactModal
      isOpen={showModal}
      onRequestClose={() => setShowModal(false)}
      style={modalStyle}
    >
      <ModalP>
        Please acknowledge that a portion or all of your seat deposit may be
        non-refundable. Please refer to your student agreement for details.
      </ModalP>
      <ModalButtonRow>
        <ModalButton type="reset" onClick={() => setShowModal(false)}>
          Cancel
        </ModalButton>
        <ModalButton
          style={{ fontWeight: 'bold' }}
          type="submit"
          onClick={() => {
            submitForm();
            setShowModal(false);
          }}
        >
          Acknowledge
        </ModalButton>
      </ModalButtonRow>
    </ReactModal>
  );
}
