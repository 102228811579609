import React from 'react';

export default function StarIcon(props) {
  return (
    <svg
      width="40px"
      height="40px"
      viewBox="0 0 40 40"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>Group 4</title>
      <desc>Created with Sketch.</desc>
      <g
        id="StarIcon-svgs-jonathan"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="StarIcon-Artboard"
          transform="translate(-159.000000, -87.000000)"
        >
          <g id="StarIcon-Group-4" transform="translate(159.000000, 87.000000)">
            <g id="StarIcon-Group-3" fill="#0CCA8E">
              <g id="StarIcon-Group-2">
                <circle id="StarIcon-Oval-16" cx="20" cy="20" r="20" />
              </g>
            </g>
            <g
              id="StarIcon-rewards-medal-1"
              transform="translate(8.000000, 8.000000)"
            >
              <g id="StarIcon-Frames-24px">
                <rect
                  id="StarIcon-Rectangle"
                  x="0"
                  y="0"
                  width="24"
                  height="24"
                />
              </g>
              <g
                id="StarIcon-Outline"
                transform="translate(5.000000, 2.000000)"
                fill="#FFFFFF"
                fillRule="nonzero"
              >
                <g id="StarIcon-Group">
                  <path
                    d="M13.78,0.375 C13.59,0.138 13.303,0 13,0 L1,0 C0.697,0 0.41,0.138 0.22,0.375 C0.03,0.611 -0.042,0.921 0.024,1.217 L2.024,10.217 L3.976,9.783 L2.247,2 L5.2,2 L6,6 L8,6 L8.8,2 L11.754,2 L10.025,9.783 L11.977,10.217 L13.977,1.217 C14.042,0.921 13.97,0.611 13.78,0.375 Z"
                    id="StarIcon-Path"
                  />
                  <path
                    d="M11,20 C10.848,20 10.694,19.965 10.553,19.895 L7,18.119 L3.447,19.894 C3.097,20.069 2.678,20.024 2.374,19.78 C2.069,19.535 1.935,19.135 2.029,18.758 L2.922,15.187 L1.168,12.555 C0.963,12.248 0.944,11.854 1.118,11.529 C1.292,11.203 1.631,11 2,11 L4.382,11 L6.106,7.553 C6.445,6.876 7.556,6.876 7.895,7.553 L9.618,11 L12,11 C12.369,11 12.708,11.203 12.882,11.528 C13.056,11.853 13.037,12.247 12.832,12.554 L11.078,15.186 L11.971,18.757 C12.066,19.135 11.931,19.535 11.626,19.779 C11.445,19.925 11.223,20 11,20 Z M7,16 C7.153,16 7.307,16.035 7.447,16.106 L9.502,17.133 L9.029,15.242 C8.961,14.969 9.011,14.679 9.167,14.445 L10.131,13 L9,13 C8.621,13 8.275,12.786 8.105,12.447 L7,10.236 L5.895,12.447 C5.725,12.786 5.379,13 5,13 L3.869,13 L4.832,14.445 C4.988,14.679 5.039,14.969 4.97,15.242 L4.497,17.133 L6.552,16.106 C6.693,16.035 6.847,16 7,16 Z"
                    id="StarIcon-Shape"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
