import React from 'react';

export default function LockedIcon(props) {
  return (
    <svg
      width="42px"
      height="42px"
      viewBox="0 0 42 42"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>Group 6</title>
      <desc>Created with Sketch.</desc>
      <g
        id="Approach-Dashboard-Mobile"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Approach-Dashboard-2"
          transform="translate(-35.000000, -455.000000)"
        >
          <g id="Group-17" transform="translate(16.000000, 299.000000)">
            <g id="Group-16" transform="translate(20.000000, 75.000000)">
              <g id="Group-15" transform="translate(0.000000, 82.000000)">
                <g id="Group-6">
                  <g id="icon" fill="#AAAAAA" stroke="#FFFFFF">
                    <g id="Group-4">
                      <circle id="Oval-16" cx="20" cy="20" r="20" />
                    </g>
                  </g>
                  <g id="lock-1" transform="translate(8.000000, 8.000000)">
                    <g id="Frame-24px">
                      <rect id="Rectangle" x="0" y="0" width="24" height="24" />
                    </g>
                    <g
                      id="Line_Icons"
                      transform="translate(5.000000, 2.000000)"
                      fill="#FFFFFF"
                      fillRule="nonzero"
                    >
                      <g id="Group">
                        <circle id="Oval" cx="7" cy="14.5" r="1.5" />
                        <path
                          d="M12,9 L12,5 C12,2.243 9.757,0 7,0 C4.243,0 2,2.243 2,5 L2,9 C0.896,9 0,9.896 0,11 L0,18 C0,19.103 0.896,20 2,20 L12,20 C13.104,20 14,19.103 14,18 L14,11 C14,9.896 13.104,9 12,9 Z M4,5 C4,3.346 5.346,2 7,2 C8.654,2 10,3.346 10,5 L10,9 L4,9 L4,5 Z M2,18 L2,11 L12,11 L12.002,18 L2,18 Z"
                          id="Shape"
                        />
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
