import React from 'react';
// import { genRandId } from '../utils/jsFunctions';

export default function CalendarIcon(props) {
  // const uniqueId = `${genRandId(0, 1000, 'path')}`;
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 20 22"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      // xmlns:xlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      {/* <!-- Generator: Sketch 54.1 (76490) - https://sketchapp.com --> */}
      <title>icon / action / calendar_today / 24px</title>
      <desc>Created with Sketch.</desc>
      <defs>
        <path
          d="M22,3 L19,3 L19,1 L17,1 L17,3 L7,3 L7,1 L5,1 L5,3 L2,3 L2,23 L22,23 L22,3 Z M20,21 L4,21 L4,8 L20,8 L20,21 Z"
          id="CalendarIcon-path"
        />
      </defs>
      <g
        id="svgs"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        opacity="0.898972284"
      >
        <g id="Artboard" transform="translate(-51.000000, -90.000000)">
          <g
            id="icon/action/calendar_today_24px"
            transform="translate(49.000000, 89.000000)"
            style={{ color: props.active ? 'rgba(14,182,254,1)' : 'inherit' }}
          >
            <mask id="CalendarIcon-mask-2" fill="white">
              <use xlinkHref="#CalendarIcon-path" />
            </mask>
            <g fillRule="nonzero" />
            <g
              id="✱-/-Color-/-Icons-/-Blue-dm/-Active"
              mask="url(#CalendarIcon-mask-2)"
              fill="currentcolor"
              fillRule="evenodd"
            >
              <rect id="Rectangle-13" x="0" y="0" width="24" height="24" />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
