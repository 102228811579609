import React from 'react';
import StyledH2 from '../Styles/StyledH2';
import { Paragraph } from '../Styles/StyledP';
import styled from 'styled-components';
import { Content } from './Contact';
import { useCurrentApplication } from '../utils/hooks';
import _get from 'lodash/get';
import ConfirmationSkeleton from './ConfirmationSkeleton';
import ApplicationWithdrawn from './ApplicationWithdrawn';
import calendarCheck from '../img/calendar-check-1.svg';

// TODO: rip out unnecessary code and get all the status's to work
export default function ConsultationConfirmation({ details = {} }) {
  const { data: applicationData } = useCurrentApplication();

  let consultationComplete = _get(
    applicationData,
    'application.complete.consultation',
  );
  let skillReviewUnlocked = _get(
    applicationData,
    'application.skillReviewLink',
  );

  let applicationStatus = _get(applicationData, 'application.Status__c', '');

  const allowProgress = !!(consultationComplete && skillReviewUnlocked);
  const showInBetween = consultationComplete && !skillReviewUnlocked;

  if (applicationStatus === 'Withdrawn') {
    return (
      <ConfirmationSkeleton allowProgress={allowProgress}>
        <ApplicationWithdrawn />
      </ConfirmationSkeleton>
    );
  }

  return (
    <ConfirmationSkeleton allowProgress={allowProgress}>
      <StyledH2>Admissions Consultation</StyledH2>
      <Content>
        {!allowProgress && !showInBetween && (
          <>
            <CalendarCheckIcon alt="" src={calendarCheck} />
            <Paragraph variant="paragraph" >
              Your admissions phone consultation has been scheduled.
            </Paragraph>
            <Paragraph variant="paragraph">
              An email confirmation was sent to your email address.
            </Paragraph>
            <Paragraph variant="paragraph" marginBottom="2.5rem">
              <b>Interview Instructions:</b> This is a telephone interview, and
              will take place over the telephone with the number you provided.
            </Paragraph>
          </>
        )}
        {showInBetween && (
          <>
            <Paragraph variant="paragraph">
              Your admissions phone consultation has been completed.
            </Paragraph>
          </>
        )}

        <StyledH2 desktopAlign="left">
          <BlueUnderline>What's Next</BlueUnderline>...
        </StyledH2>
        {!allowProgress && !showInBetween && (
          <Paragraph variant="paragraph">
            You should go eat some ice cream or have a drink, because you've
            done all you need to do at this point. After your Consultation, the
            final section of your application will be unlocked. Talk Soon!
          </Paragraph>
        )}
        {showInBetween && (
          <Paragraph variant="paragraph">
            The final section of your application will be unlocked soon.
          </Paragraph>
        )}
      </Content>
    </ConfirmationSkeleton>
  );
}

const CalendarCheckIcon = styled.img`
  float: left;
  margin-right: 8px;
`;

const BlueUnderline = styled.span`
  position: relative;

  &:after {
    content: '';
    position: absolute;
    bottom: -3.5px;
    left: 0;
    height: 0;
    width: 100%;
    border-bottom: 2px solid ${({theme}) => theme.palette.primary.dark};
  }
`;
