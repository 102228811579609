import React from 'react';

export default function PenPaper(props) {
  return (
    <svg
      width="42px"
      height="42px"
      viewBox="0 0 42 42"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>Group 5</title>
      <desc>Created with Sketch.</desc>
      <g
        id="PenPaper-svgs-jonathan"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="PenPaper-Artboard" transform="translate(-24.000000, -86.000000)">
          <g id="PenPaper-Group-5" transform="translate(25.000000, 87.000000)">
            <g id="PenPaper-Group-4" fill="#fb3530" stroke="#fb3530">
              <circle id="PenPaper-Oval-16" cx="20" cy="20" r="20" />
            </g>
            <g
              id="PenPaper-paper-pencil"
              transform="translate(8.000000, 8.000000)"
            >
              <g id="PenPaper-Frame_-_24px">
                <rect
                  id="PenPaper-Rectangle"
                  x="0"
                  y="0"
                  width="24"
                  height="24"
                />
              </g>
              <g
                id="PenPaper-Line_Icons"
                transform="translate(1.000000, 2.000000)"
                fill="#FFFFFF"
                fillRule="nonzero"
              >
                <g id="PenPaper-Group">
                  <path
                    d="M19,0 C17.346,0 16,1.346 16,3 L16,14.303 L19,18.803 L22,14.303 L22,3 C22,1.346 20.654,0 19,0 Z M20,13.698 L19,15.198 L18,13.698 L18,6 L20,6 L20,13.698 Z M18,4 L18,3 C18,2.448 18.449,2 19,2 C19.551,2 20,2.448 20,3 L20,4 L18,4 Z"
                    id="PenPaper-Shape"
                  />
                  <path
                    d="M12,1 L2,1 C0.897,1 0,1.898 0,3 L0,17 C0,18.103 0.897,19 2,19 L12,19 C13.103,19 14,18.103 14,17 L14,3 C14,1.898 13.103,1 12,1 Z M2,17 L2,3 L12,3 L12.001,17 L2,17 Z"
                    id="PenPaper-Shape"
                  />
                  <rect
                    id="PenPaper-Rectangle"
                    x="4"
                    y="5"
                    width="6"
                    height="2"
                  />
                  <rect
                    id="PenPaper-Rectangle"
                    x="4"
                    y="9"
                    width="6"
                    height="2"
                  />
                  <rect
                    id="PenPaper-Rectangle"
                    x="4"
                    y="13"
                    width="4"
                    height="2"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
