import React from 'react';
import { Router } from '@reach/router';
import Loading from '../Shared/Loading';
import SkillReview from './SkillReview';
import PrimaryNavigation from './PrimaryNavigation';
import PersonalInfoContainer from './PersonalInfoContainer';
import YouDidIt from './YouDidIt';
import { useCurrentApplication } from '../utils/hooks';
import PersonalInfoLanding from './PersonalInfoLanding';
import ValidationProvider from '../Shared/ValidationProvider';
import styled from 'styled-components';
import _get from 'lodash/get';
import HubspotIframe from './HubspotIframe';

export default function ApplicationContent(props) {
  const { data, error, loading } = useCurrentApplication();

  if (error) return 'Error';
  if (loading) {
    return <Loading />;
  }

  // if the consultation hasn't been held yet,
  // but everything else is done, we still want
  // to show the consultation confirmation page
  // instead of YouDidIt
  const youDidIt =
    _get(data, 'application.consultation.held') &&
    _get(data, 'application.complete.personalInfo') &&
    (_get(data, 'application.complete.skillReviewSubmitted') ||
      _get(data, 'application.complete.skillReviewGraded'));

  return (
    <Container>
      <ValidationProvider>
        <Sidebar>
          <PrimaryNavigation />
        </Sidebar>
        <Main>
          {youDidIt ? (
            <YouDidIt />
          ) : (
            <>
              <Router primary={false}>
              <HubspotIframe default hidden={props['*'] !== 'consultation'}/>
              </Router>
              <Router primary={false}>
                <PersonalInfoLanding path="personal-info" />
                <PersonalInfoContainer path="personal-info/*" />
                <SkillReview path="skill-review" />
              </Router>
            </>
          )}
        </Main>
      </ValidationProvider>
    </Container>
  );
}

const Sidebar = styled.aside`
  @media (min-width: 1024px) {
    background: ${({theme}) => theme.palette.background.primary};
    border-right: 1px solid ${({theme}) => theme.palette.secondary.dark};
    padding: 2.5rem;
    padding-right: 2.625rem;
  }
`;

const Container = styled.div`
  background: ${({theme}) => theme.palette.background.primary};
  height: 100%;
  display: flex;
  flex-direction: column;
  @media (min-width: 1024px) {
    flex-direction: row;
  }
`;

const Main = styled.div`
  background: ${({theme}) => theme.palette.background.primary};
  width: 100%;
  @media (min-width: 1024px) {
    margin-right: 2.5rem;
  }
`;
