import React from 'react';
import moment from 'moment';
import ProgressItem from './ProgressItem';
import RequiredIcon from '../../Styles/RequiredIcon';
import sectionIcons from '../../img/sectionIcons';
import { useCurrentApplication } from '../../utils/hooks';
import _get from 'lodash/get';

export default function FinanceProgress(props) {
  const { data, error } = useCurrentApplication();
  if (error) {
    console.error(error);
  }
  const { method, tuition, deposit } = _get(
    data,
    'application.enrollment.payments',
  ) || {
    tuition: {},
    deposit: {},
  };
  let progress = 0;
  let items = 5;
  if (method) progress += 1;
  if (tuition.acknowledged) progress += 1;
  if (tuition.complete) progress += 1;
  if (deposit.acknowledged) progress += 1;
  if (deposit.complete) progress += 1;

  return (
    <ProgressItem
      to="finance-center"
      action={<RequiredIcon />}
      icon={sectionIcons['Finance Center']}
      unlocked={
        !!(
          _get(data, 'application.viewedDecision') &&
          _get(data, 'application.enrollment')
        )
      }
      description="Finance Center"
      progress={Math.ceil((progress / items) * 100)}
      complete={
        progress === items
          ? moment(
              _get(data, 'application.enrollment.payments.history[0].date'),
            ).format('MMMM DD, YYYY')
          : ''
      }
    />
  );
}
