import React from 'react';

export default function ArrowRight(props) {
  return (
    <svg
      width="20px"
      height="10px"
      viewBox="0 0 20 10"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    // xmlns:xlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <title>arrow-right-1</title>
      <desc>Created with Sketch.</desc>
      <g 
        id="ArrorRight-Symbols"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="ArrorRight-Button-/-Outline-/-Begin-Skill-Review-/-Blue"
          transform="translate(-289.000000, -20.000000)"
        >
          <g
            id="ArrorRight-Group-3"
          >
            <g
              id="ArrorRight-arrow-right-1"
              transform="translate(287.000000, 13.000000)"
            >
              <g
                id="ArrorRight-Frame_-_24px"
              >
                <rect
                  id="ArrorRight-Rectangle"
                  x="0"
                  y="0"
                  width="24"
                  height="24 "
                />
              </g>
              <g
                id="ArrorRight-Line_Icons"
                transform="translate(2.000000, 7.000000)"
                fill="currentColor"
                fillRule="nonzero"
              >
                <polygon
                  id="ArrorRight-Path"
                  points="14.707 0.293 13.293 1.707 15.586 4 0 4 0 6 15.586 6 13.293 8.293 14.707 9.707 19.414 5"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
