import React, { useContext } from 'react';
import styled from 'styled-components';
import StyledSection from '../Styles/StyledSection';
import StyledH2 from '../Styles/StyledH2';
import StyledP from '../Styles/StyledP';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { ApplicationContext } from '../Shared/ApplicationProvider';
import Loading from '../Shared/Loading';
import SaveAndContinue from '../Shared/SaveAndContinue';
import { SectionContainer, Content } from './Contact';
import _get from 'lodash/get';
import HackerRankSkillReview from './HackerRankSkillReview'

const GET_SKILL_REVIEW_COMPLETION = gql`
  query GetSkillReviewCompletion($id: String!) {
    application(id: $id) {
      id
      skillReviewLink
      complete {
        skillReviewSubmitted
      }
    }
  }
`;

export default function SkillReview() {
  const applicationId = useContext(ApplicationContext);
  const { loading, data } = useQuery(GET_SKILL_REVIEW_COMPLETION, {
    variables: { id: applicationId },
  });
  if (loading) {
    return <Loading />;
  }

  if (_get(data, 'application.skillReviewLink')){
      return <HackerRankSkillReview />
  }

  return (
    <>
      <StyledSection>
        <SectionContainer>
          <StyledH2>Tech Review</StyledH2>
          <Content>
            {_get(data, 'application.complete.skillReviewSubmitted') ? (
              <>
                <StyledP variant="paragraph">
                  Your application is now complete and your admissions decision
                  should be ready in 1-2 business days. You should hear from us
                  soon, and keep an eye on your Approach Dashboard for any
                  notifications.
                </StyledP>
                <StyledP variant="paragraph">
                  Good luck! (We might be just a bit biased, but we&apos;re
                  really rooting for you.)
                </StyledP>
              </>
            ) : (
              <>
                <StyledP>
                Let&apos;s get a sense for your skills and learning style.
                  Don&apos;t panic. We know most of our applicants aren&apos;t
                  experts. This is just to see where you&apos;re at.
                  </StyledP>
                  <StyledP style={{margin: '1rem 0'}}>
                  Follow the instructions from your admissions counselor
                  to complete your Tech Review. If you're not sure,
                  email your admissions counselor: <a href="mailto:admissions@hackbrightacademy.com">
                      admissions@hackbrightacademy.com
                  </a></StyledP>
                <ModifiedP marginBottom="2rem;">
                  <b>
                    The tech review is required, and your application will not
                    be complete without it.
                  </b>
                </ModifiedP>            
              </>
            )}
          </Content>
        </SectionContainer>
        <SectionContainer>
          <Content>
            <SaveAndContinue asLink to="/dashboard" />
          </Content>
        </SectionContainer>
      </StyledSection>
    </>
  );
}

const ModifiedP = styled(StyledP)`
margin: 1rem 0;
  @media (min-width: 1024px) {
    font-size: 0.9375rem;
    letter-spacing: 0.005rem;
  }
`;

