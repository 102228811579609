import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import styled from 'styled-components';
import CardSection from '../Styles/CardSection';
import Loading from '../Shared/Loading';
import _get from 'lodash/get';
import { addComma } from '../utils/jsFunctions';

export const GET_USER_INFO = gql`
  query GetUserInfo {
    user {
      id
      email
      first_name
      last_name
      applications {
        id
        phone
        mailingAddress1
        city
        state
        zipCode
      }
    }
  }
`;

export default function AccountPersonalInfo() {
  const { data, error, loading } = useQuery(GET_USER_INFO);
  if (error) console.dir(error);
  if (loading) return <Loading />;
  return (
    <CardSection style={{ borderTop: '1px solid rgba(231,231,231,1)' }}>
      <StyledHeading>Personal Info</StyledHeading>
      <ol>
        <ListItem>
          {_get(data, 'user.first_name', '')} {_get(data, 'user.last_name', '')}
        </ListItem>
        <ListItem>
          <span>{_get(data, 'user.applications[0].mailingAddress1', '')}</span>
          <p>
            {addComma(_get(data, 'user.applications[0].city', ''))}
            {_get(data, 'user.applications[0].state', '')}{' '}
            {_get(data, 'user.applications[0].zipCode', '')}
          </p>
        </ListItem>
        <ListItem>{_get(data, 'user.applications[0].phone', '')}</ListItem>
        <ListItem>{_get(data, 'user.email', '')}</ListItem>
      </ol>
    </CardSection>
  );
}

const StyledHeading = styled.h2`
  font-weight: bold;
  font-size: 0.9375rem;
  margin-bottom: 1rem;
`;

const ListItem = styled.li`
  margin-bottom: 1rem;
`;
