import moment from 'moment';
import * as Yup from 'yup';
import _get from 'lodash/get';

function genRandPrefix() {
  let prefix = '';
  while (prefix.length < 3) {
    prefix = prefix += String.fromCharCode(
      Math.floor(Math.random() * (122 - 97) + 97),
    );
  }
  return prefix;
}

export function addComma(str) {
  if (typeof str !== 'string') return '';
  return str.length ? `${str}, ` : '';
}

export function genRandId(min, max, prefix = genRandPrefix()) {
  min = Math.ceil(min);
  max = Math.floor(max);
  const id = `${prefix}-${Math.floor(Math.random() * (max - min) + min)}`;
  return id;
}

export function splitQueryString(string = '') {
  const queryVals = {};
  const split = string.substr(1).split('&');
  for (let i = 0; i < split.length; i++) {
    const pair = split[i].split('=');
    queryVals[pair[0]] = pair[1];
  }
  return queryVals;
}

export const showErrors = (section, formik) => {
  const result =
    Object.keys(formik.touched || {}).reduce(
      (acc, key) => acc || (formik.touched[key] && formik.errors[key] && true),
      false,
    ) || false;

  return result;
};

const validationSchema = Yup.object().shape({
  section: Yup.string(),
  contact: Yup.object().when('section', {
    is: section => section === 'contact' || section === 'all',
    then: Yup.object().shape({
      applicantType: Yup.string()
        .oneOf(['us', 'international'])
        .required('Choose US or International'),
      mailingAddress1: Yup.string().required('Enter a valid mailing address'),
      city: Yup.string().required('Enter a valid city'),
      region: Yup.string().required('Enter a valid state or region'),
      postalCode: Yup.string().required('Enter a valid zip or postal code'),
      phone: Yup.string()
        .matches(
          /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/,
          'Enter a valid phone number',
        )
        .required('Enter a valid phone number'),
      birthday: Yup.string()
        // .matches(
        //   /^((0|1)\d{1})\/((0|1|2)\d{1})\/((19|20)\d{2})/,
        //   'Enter a valid birthday in mm/dd/yyyy format',
        // )
        .required('Enter a valid birthday in mm/dd/yyyy format'),
      genderIdentityAcknowledgement: Yup.bool().oneOf([true]).required("Read and accept the acknowledgement"),
    }),
    otherwise: Yup.object(),
  }),
  education: Yup.object().when('section', {
    is: section => section === 'education' || section === 'all',
    then: Yup.object().shape({
      highestEducation: Yup.string().oneOf(
        [
          'High School',
          'Some College',
          'Associates',
          'Bachelors',
          'Masters',
          'PHD',
        ],
        'Select a valid level of education',
      ),
      experience: Yup.object().shape({
        htmlCss: Yup.bool().required(),
        javascript: Yup.bool().required(),
        python: Yup.bool().required(),
        react: Yup.bool().required(),
        sql: Yup.bool().required(),
        flask: Yup.bool().required(),
        computerScience: Yup.bool().required(),
      }),
      hoursSpent: Yup.string()
        .matches(/^[0-9]+$/, 'Enter a valid number of hours.')
        .required('Enter a valid number of hours.'),
    }),
    otherwise: Yup.object(),
  }),
  experience: Yup.object().when('section', {
    is: section => section === 'experience' || section === "all",
    then: Yup.object().shape({
      linkedIn: Yup.string().url().required().max(255),
    }),
    otherwise: Yup.object(),
  }),
  goals: Yup.object().when('section', {
    is: section => section === 'goals' || section === 'all',
    then: Yup.object().shape({
      goals: Yup.string().required('Enter a valid response.'),
      hearAbout: Yup.object().shape({
        currentStudent: Yup.bool(),
        alumna: Yup.bool(),
        instructorStaff: Yup.bool(),
        womenWhoCode: Yup.bool(),
        google: Yup.bool(),
        articleBlog: Yup.bool(),
        event: Yup.bool(),
        advertisement: Yup.bool(),
        facebookTwitter: Yup.bool(),
        linkedIn: Yup.bool(),
        currentEmployer: Yup.bool(),
        other: Yup.bool(),
      }),
      acknowledgement: Yup.bool().oneOf(
        [true],
        'Read and accept the acknowledgement',
      ),
    }),
    otherwise: Yup.object(),
  }),
});

function setInitialValues(data, section) {
  const application = _get(data, 'application') || {
    experience: {},
    hearAbout: {},
    workExperience: [],
  };
  const experience = _get(application, 'experience') || {};
  const hearAbout = _get(application, 'hearAbout') || {};
  return {
    navigate: true,
    section,
    contact: {
      applicantType: application.applicantType || 'us',
      mailingAddress1: application.mailingAddress1 || '',
      city: application.city || '',
      region: application.region || '',
      postalCode: application.postalCode || '',
      country: application.country || 'US',
      phone: application.phone || '',
      birthday: application.birthday
        ? moment(application.birthday, 'YYYY-MM-DD').format('MM/DD/YYYY')
        : '',
      genderIdentityAcknowledgement: application.genderIdentityAcknowledgement || '',
    },
    education: {
      highestEducation: application.highestEducation || '',
      fieldOfStudy: application.fieldOfStudy || '',
      experience: {
        htmlCss: experience.htmlCss || false,
        javascript: experience.javascript || false,
        python: experience.python || false,
        react: experience.react || false,
        sql: experience.sql || false,
        flask: experience.flask || false,
        computerScience: experience.computerScience || false,
      },
      hoursSpent: application.hoursSpent || '',
      hoursSpentType: application.hoursSpentType || '',
    },
    experience: {
      linkedIn: application.linkedIn || '',
      workExperience: application.workExperience || [
        { position: '', length: '', description: '' },
      ],
    },
    goals: {
      goals: application.goals || '',
      hearAbout: {
        currentStudent: hearAbout.currentStudent || false,
        alumna: hearAbout.alumna || false,
        instructorStaff: hearAbout.instructorStaff || false,
        womenWhoCode: hearAbout.womenWhoCode || false,
        google: hearAbout.google || false,
        articleBlog: hearAbout.articleBlog || false,
        event: hearAbout.event || false,
        advertisement: hearAbout.advertisement || false,
        facebookTwitter: hearAbout.facebookTwitter || false,
        linkedIn: hearAbout.linkedIn || false,
        currentEmployer: hearAbout.currentEmployer || false,
        other: hearAbout.other || false,
      },
      acknowledgement: application.acknowledgement || false,
    },
  };
}

export async function calculateCompletion(data) {
  const values = setInitialValues(data, 'all');
  const allValid = await validationSchema.isValid(values);
  if (allValid) {
    return {
      contact: true,
      education: true,
      experience: true,
      goals: true,
    };
  }

  const contactPromise = validationSchema.isValid(
    setInitialValues(data, 'contact'),
  );
  const educationPromise = validationSchema.isValid(
    setInitialValues(data, 'education'),
  );
  const experiencePromise =
    data &&
    data.application &&
    data.application.workExperience &&
    validationSchema.isValid(setInitialValues(data, 'experience'));
  const goalsPromise = validationSchema.isValid(
    setInitialValues(data, 'goals'),
  );

  const [contact, education, experience, goals] = await Promise.all([
    contactPromise,
    educationPromise,
    experiencePromise,
    goalsPromise,
  ]);

  return {
    contact,
    education,
    experience,
    goals,
  };
}

export const submittedApplicationTracking = (location = '') => {
  if (process.env.NODE_ENV !== 'development') {
    if (window.hasOwnProperty('fbq')) {
      window.fbq('trackCustom', 'CompleteRegistration', {
        value: 25.0,
        currency: 'USD',
      });
    }
    if (window.hasOwnProperty('gtag')) {
      window.gtag('config', 'UA-41999703-1', {
        page_title: 'student_application',
        page_path: `/sfportal?application_received=1&type=full&subject=webdev&location=${location}&newportal=true`,
      });
    }
  } else console.log(`Simulating application tracking: Location = ${location}`);
};
