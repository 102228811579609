import styled from 'styled-components';

export default styled.legend`
  margin: 0 0 1.5rem 0;
  font-size: 0.9375rem;
  letter-spacing: 0.08px;
  line-height: 1.5625rem;
  color: ${({ theme }) => theme.palette.gray[700]};
  @media (min-width: 1024px){
    margin-top: 0;
  }
`;
