import React from 'react';
import { useCurrentApplication } from '../../utils/hooks';
import sectionIcons from '../../img/sectionIcons';
import ProgressItem from './ProgressItem';
import RequiredIcon from '../../Styles/RequiredIcon';
import moment from 'moment';
import _get from 'lodash/get';

export default function OnboardingProgress(props) {
  const { data, cohort } = useCurrentApplication();

  const user = _get(data, 'user') || {};
  const application = _get(data, 'application') || {};
  const enrollment = _get(data, 'application.enrollment') || {};
  const isRemote = _get(cohort, 'data.isRemote');

  let progress = 0;
  let items = 3;
  if (user.slackUsername) progress += 1;
  if (!isRemote) {
    if (enrollment.housingStatus === 'Not requested') {
      items--;
    }
    if (enrollment.housingStatus === 'Contract signed') {
      progress += 1;
    }
  } else {
    if (enrollment.joinedDiscord) {
      progress += 1;
    }
  }
  if (enrollment.signedStudentAgreement) progress += 1;

  return (
    <ProgressItem
      to="onboarding"
      action={<RequiredIcon />}
      icon={sectionIcons.Onboarding}
      unlocked={!!(application.viewedDecision && application.enrollment)}
      description="Onboarding"
      progress={Math.ceil((progress / items) * 100)}
      complete={
        progress >= items
          ? moment(enrollment.onboardingUpdated).format('MMMM DD, YYYY')
          : ''
      }
    />
  );
}
