import styled from 'styled-components';

const Section = styled.section`
  padding: 1rem 1.5rem 4rem 1.5rem;
  position: relative;
  min-height: calc(100vh - 70px - 70px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (min-width: 1024px) {
    padding-right: 6.125rem;
    ${({ variant }) =>
      variant === 'dashboard' ? 'padding: 1.5rem 2.75rem;' : ''}
  }
`;

export const FullSection = styled(Section)`
  flex-grow: 1;
  position: relative;
`;

export default Section;
