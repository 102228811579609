import React, { useContext } from 'react';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import _get from 'lodash/get';
import CardSection from '../Styles/CardSection';
import DirectoryCard from './DirectoryCard';
import Card from '../Shared/Card';
import { useApplicationId } from '../Shared/ApplicationProvider';
import { MediaQueryContext } from '../Shared/MediaQueryProvider';

export const GET_DIRECTORY_INFO = gql`
  query GetDirectoryInfo($id: String!) {
    application(id: $id) {
      id
      enrollment {
        id
        applicationId
        leadInstructor {
          id
          firstName
          lastName
          email
          slack
          picture
          position
        }
        admissionsCounselor {
          id
          firstName
          lastName
          email
          slack
          picture
        }
        mentor {
          id
          firstName
          lastName
          email
          slack
          picture
          position
        }
        housingManager {
          id
          firstName
          lastName
          email
          slack
          picture
        }
        financeContact {
          id
          firstName
          lastName
          email
          slack
          picture
        }
      }
    }
  }
`;

export default function StudentDirectoryWidget() {
  const applicationId = useApplicationId();
  const { data, loading, error } = useQuery(GET_DIRECTORY_INFO, {
    variables: { id: applicationId },
  });
  const isDesktop = useContext(MediaQueryContext);

  if (loading) return null;
  if (error) {
    console.dir(error);
    return 'Error';
  }
  return (
    <Card
      span={1 / 3}
      header="student directory"
      defaultExpanded
      style={{ position: isDesktop ? 'absolute' : 'static', right: 0 }}
    >
      <CardSection style={{ padding: '1rem' }}>
        {_get(data, 'application.enrollment.admissionsCounselor') && (
          <DirectoryCard
            title="Admission &amp; Finance"
            staff={[data.application.enrollment.admissionsCounselor]}
          />
        )}
        {_get(data, 'application.enrollment.housingManager') && (
          <DirectoryCard
            title="Housing"
            staff={[data.application.enrollment.housingManager]}
          />
        )}
        {_get(data, 'application.enrollment.leadInstructor') ||
        _get(data, 'application.enrollment.mentor') ? (
          <DirectoryCard
            title="Instructor and Mentors"
            staff={[
              _get(data, 'application.enrollment.leadInstructor'),
              _get(data, 'application.enrollment.mentor'),
            ]}
          />
        ) : null}
      </CardSection>
    </Card>
  );
}
