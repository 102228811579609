import React from 'react';

export default function TelephoneCircle(props) {
  return (
    <svg
      width="20px"
      height="20px"
      viewBox="0 0 20 20"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
        // xmlns:xlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <title>telephone-circle</title>
      <desc>Created with Sketch.</desc>
      <g
        id="TelephoneCircle-Application_Mobile"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="TelephoneCircle-v4_8_All-Done-for-Now_Option1"
          transform="translate(-204.000000, -400.000000)"
        >
          <g
            id="TelephoneCircle-Group-6"
            transform="translate(24.000000, 385.000000)"
          >
            <g
              id="TelephoneCircle-contact-us-button"
              transform="translate(163.000000, 0.000000)"
            >
              <g id="tTelephoneCircle-elephone-circle" transform="translate(16.000000, 13.000000)">
                <g id="TelephoneCircle-Frame_-_24px">
                  <rect id="TelephoneCircle-Rectangle" x="0" y="0" width="24" height="24" />
                </g>
                <g
                  id="TelephoneCircle-Line_Icons"
                  transform="translate(1.000000, 2.000000)"
                  fill="currentColor"
                  fillRule="nonzero"
                >
                  <g
                    id="TelephoneCircle-Group"
                  >
                    <path
                      d="M10,0 C4.487,0 0,4.486 0,10 C0,15.514 4.486,20 10,20 C15.514,20 20,15.514 20,10 C20,4.486 15.514,0 10,0 Z M10,18 C5.589,18 2,14.411 2,10 C2,5.589 5.589,2 10,2 C14.411,2 18,5.589 18,10 C18,14.411 14.412,18 10,18 Z"
                      id="TelephoneCircle-Shape"
                    />
                    <path
                      d="M14.127,11.778 L11.981,11.777 C11.682,11.777 11.442,12.018 11.442,12.315 L11.443,13.362 C8.849,13.223 6.778,11.151 6.641,8.558 L7.686,8.558 C7.982,8.558 8.223,8.318 8.223,8.021 L8.222,5.873 C8.223,5.577 7.982,5.338 7.686,5.337 L5.539,5.336 C5.242,5.338 5.001,5.577 5,5.874 L5.001,8.559 L5.017,8.559 C5.154,12.043 7.957,14.847 11.444,14.986 L11.444,15 L14.127,15 C14.423,14.999 14.664,14.759 14.665,14.462 L14.664,12.315 C14.664,12.019 14.423,11.778 14.127,11.778 Z"
                      id="TelephoneCircle-Path"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
