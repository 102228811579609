import React from 'react';
import ProgressItem from './ProgressItem';
import sectionIcons from '../../img/sectionIcons';
import { useCurrentApplication } from '../../utils/hooks';
import moment from 'moment';
import StyledButton from '../../Styles/StyledButton';
import _get from 'lodash/get';

export default function AdmissionsProgress(props) {
  const { data } = useCurrentApplication();

  return (
    <ProgressItem
      to="admissions-decision"
      icon={sectionIcons['Admissions Decision']}
      unlocked={
        // the Salesforce GUI value is Admitted
        // BUT the Salesforce API value is Accepted
        !!(_get(data, 'application.Status__c') === 'Accepted') ||
        !!(_get(data, 'application.Status__c') === 'Soft_Deny')
      }
      description="Admissions Decision"
      complete={
        _get(data, 'application.viewedDecision') &&
        moment(data.application.viewedDecision).format('MMMM DD, YYYY')
      }
      action={
        <StyledButton variant="secondary" rightIcon>
          Open
        </StyledButton>
      }
    />
  );
}
