import React from 'react';
import styled from 'styled-components';

export default function RecommendedIcon(props) {
  return <StyledDiv {...props}>Recommended</StyledDiv>;
}

const StyledDiv = styled.div`
  box-sizing: border-box;
  background: rgba(254, 186, 71, 1);
  border-radius: 0.59375rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.625rem;
  color: white;
  font-weight: bold;
  padding: 0.25rem 0.5rem 0.3125rem 0.5rem;
  width: 5.625rem;
`;
