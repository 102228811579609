import React from 'react';
import styled from 'styled-components';
import CardSection from '../Styles/CardSection';
import StyledA from '../Styles/StyledA';

export default function LehiTransportation(props) {
  return (
    <CardSection>
      <PHeading>Campus Shuttle</PHeading>
      <Paragraph>
        We offer free transportation form UTA Lehi station to our campus.
        The campus shuttle runs from
        <BoldSpan> 8:30-10:00 </BoldSpan>
        am and again from
        <BoldSpan> 4:30-6:00 </BoldSpan>
        pm.
      </Paragraph>
      <PHeading>Utah Transit Authority (UTA)</PHeading>
      <Paragraph>
        To purchase a pass with UTA or for more information
        on transportation options visit
        {' '}
        <ATag as="a" href="https://www.rideuta.com">
            www.rideuta.com
        </ATag>
      </Paragraph>
    </CardSection>
  );
}

const PHeading = styled.h2`
    color: ${({ theme }) => theme.palette.secondary.main};
    font-weight: bold;
    margin-bottom: .45rem;
`;

const Paragraph = styled.p`
    margin-bottom: 1.25rem;
    font-size: 0.875rem;
    letter-spacing: 0.00625rem;
    line-height: 1.25rem;
`;

const BoldSpan = styled.span`
    font-weight: bold;
`;

const ATag = styled(StyledA)`
    color: ${({ theme }) => theme.palette.secondary.main};
    text-decoration: none;
    font-weight: bold;
`;
