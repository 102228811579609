import React, { useEffect } from 'react';
import { useCompletionEffect, useCurrentApplication } from '../utils/hooks';
import StyledSection from '../Styles/StyledSection';
import { useValidation } from '../Shared/ValidationProvider';
import _get from 'lodash/get';

export default function PersonalInfoLanding(props) {
  const errorContext = useValidation();
  const { data, error } = useCurrentApplication();
  useCompletionEffect(data);
  const personalComplete = _get(
    data,
    'application.complete.personalInfo',
    false,
  );

  useEffect(() => {
    const { contact, education, experience, goals } = errorContext.completion;

    // dont allow any sub routes to be rendered out if completed
    if (personalComplete) {
      props.navigate('submitted', { replace: true });
      return;
    }
    // default to contact if it's not complete
    if (!contact) {
      props.navigate('contact', { replace: true });
      return;
    }

    // contact is complete, so education should be "next"
    // if it isn't already done yet too
    if (!education) {
      props.navigate('education', { replace: true });
      return;
    }

    // contact and education are complete, so experience should be next
    if (!experience) {
      props.navigate('experience', { replace: true });
      return;
    }

    // contact, education and experience are complete, so goals is the only thing left
    if (!goals) {
      props.navigate('goals', { replace: true });
      return;
    }

    // if everything is complete, just send them to contact
    // as long as it is something after the :id/
    props.navigate('contact', { replace: true });
    return;
  }, [props, errorContext.completion, personalComplete]);

  if (error) {
    return (
      <StyledSection>
        An unknown error occurred. Please try again.
      </StyledSection>
    );
  } else {
    return null;
  }
}
