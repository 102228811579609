import React from 'react';
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import styled from 'styled-components';
import Card from '../Shared/Card';
import CardSection from '../Styles/CardSection';
import ArrowRight from '../img/ArrowRight';
import discordLogo from '../img/discord.svg';
import StyledA from '../Styles/StyledA';
import { GET_APPLICATION } from '../Graphql/queries';

const UPDATE_ENROLLMENT = gql`
  mutation UpdateEnrollment($updates: EnrollmentInput) {
    updateEnrollment(updates: $updates)
  }
`;

export default function Discord({ enrollmentId, applicationId }) {
  const displayDiscordLogo = () => <DiscordLogo src={discordLogo} />;

  const [mutate] = useMutation(UPDATE_ENROLLMENT, {
    update(cache, { data }) {
      const { application, user } = cache.readQuery({
        query: GET_APPLICATION,
        variables: { id: applicationId },
      });

      cache.writeQuery({
        query: GET_APPLICATION,
        data: {
          application: {
            ...application,
            enrollment: {
              ...application.enrollment,
              joinedDiscord: true,
            },
          },
          user,
        },
      });
    },
  });

  return (
    <Card
      span={1 / 3}
      header="discord"
      defaultExpanded
      icon={displayDiscordLogo}
      id="join-discord"
    >
      <CardSection>
        <Paragraph>
          Collaborative communication will be essential to your success here at
          Hackbright Academy. Discord is a great way for you to speak with fellow
          classmates as well as get one-on-one time with your mentors and
          instructors. You will utilize Discord heavily to not only get
          questions answered but also amplify your remote experience.
        </Paragraph>
        <Paragraph>
          Please follow the link below to join the Hackbright Academy Web Server. Once
          you have joined you will be automatically connected with your
          classmates and mentors. There are <b>text channels</b> that you will
          have access to and be able to communicate effectively with your
          cohort.
          <b>Voice channels</b> will also be very helpful for you to speak with
          classmates for group projects and discussing homework assignments.
        </Paragraph>
        <JoinDiscordButton
          onClick={() => {
            mutate({
              variables: {
                updates: {
                  id: enrollmentId,
                  joinedDiscord: true,
                },
              },
            });
          }}
          as="a"
          target="_blank"
          href="https://discord.gg/re2FXZK"
        >
          join discord
          <ArrowImg />
        </JoinDiscordButton>
      </CardSection>
    </Card>
  );
}

const DiscordLogo = styled.img`
  width: 2.125rem;
  margin-right: 0.1875rem;
`;

const Paragraph = styled.p`
  margin-bottom: 1rem;
  line-height: 1.25rem;
  letter-spacing: 0.00625rem;
`;

const JoinDiscordButton = styled(StyledA)`
  font-family: inherit;
  font-size: 0.875rem;
  overflow: visible;
  width: 159px;
  padding: 0.65625rem 1rem;
  background: ${({ theme }) => theme.palette.secondary.main};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.1875rem;
  color: white;
  margin: 1.5rem 0;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1rem;
  letter-spacing: 0.0175rem;
  text-transform: uppercase;
  text-decoration: none;
  color: white;

  &:focus {
    outline: none;
  }
`;

const ArrowImg = styled(ArrowRight)`
  margin-left: 0.5625rem;
  width: 0.9375rem;
`;
