import React, { useContext } from 'react';
import Card from '../Shared/Card';
import styled from 'styled-components';
import { useQuery } from '@apollo/react-hooks';
import { GET_USER } from '../Graphql/queries';
import { MediaQueryContext } from '../Shared/MediaQueryProvider';
import CardSection from '../Styles/CardSection';
import _get from 'lodash/get';
import sticker from '../img/sticker.png';

export default function IntroMessage(props) {
  const { data } = useQuery(GET_USER);

  const first_name = _get(data, 'user.first_name', '');
  const last_name = _get(data, 'user.last_name', '');

  const isDesktop = useContext(MediaQueryContext);

  return (
    <div style={{position: 'relative', width: '100%'}}>
    <div style={{position: isDesktop ? 'absolute' : 'static', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
    <img alt="Hackbright Academy" src={sticker} style={{marginBottom: '-1rem', height: '30vh'}}/>
    <Card

      header={
        <NameSpan>
          {first_name} {last_name}
        </NameSpan>
      }
      collapsable={false}
    >
      <CardSection style={{ paddingTop: 0 }}>
        Welcome
        to your Hackbright Academy Approach dashboard! Your next step will always
        appear in the Activity List below.
      </CardSection>
    </Card></div></div>
  );
}

const NameSpan = styled.span`
  color: ${({theme}) => theme.palette.primary.dark};
`
