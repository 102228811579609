import styled, { css } from 'styled-components';
import { Router } from '@reach/router';

export default styled.div`
  ${props =>
    props.flex &&
    css`
      display: flex;
      flex-direction: ${props.flexColumn ? 'column' : 'row'};
    `}
`;

export const RouterDiv = styled(Router)`
  display: flex;
  flex-direction: column;
`;

export const Spacer = styled.div`
  margin-top: ${({ top }) => `${top};`};
`;
