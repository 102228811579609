import React from 'react';
import { Link } from '@reach/router';
import styled from 'styled-components';
import Card from '../Shared/Card';
import CardSection from '../Styles/CardSection';
import GifContainer from '../Shared/GifContainer';
import StyledButton, { UnstyledButton } from '../Styles/StyledButton';
import ArrowRight from '../img/ArrowRight';
import { handleLogout } from '../utils/auth';

export default function CelebrateCard(props) {
  return (
    <Card span={2 / 3} collapsable={false} header="celebrate!">
      <GifContainer />
      <CardSection>
        <Paragraph>
          We don't mean to exaggerate, but you are most likely the best person
          in the history of this, or any, world. Well done, you magnificent
          bastard.
        </Paragraph>
        <Paragraph>
          There's nothing more for you to do but wait for class to start. In
          between now and then, what if we share with you some of the most
          pointless bounty the web has to offer?
        </Paragraph>
        <Paragraph>
          Wondering what could be at tinytuba.com? It's exactly what you think
          it is… Or is it?
        </Paragraph>
        <Paragraph>
          Staggeringbeauty.com makes us slightly uncomfortable, but in a
          mesmerizing way.
        </Paragraph>
        <Paragraph>
          Check out corndogoncorndog.com. Don't overthink this.
        </Paragraph>
        <Paragraph>Need bees? We've got beesbeesbees.com</Paragraph>
        <Paragraph>
          When you're ready for something more highbrow, check out eelslap.com
        </Paragraph>{' '}
        <ButtonFooter>
          <InverseButton as={Link} to="/dashboard">
            <ArrowLeft />
            <span>back to dashboard</span>
          </InverseButton>
          <LogoutButton
            onClick={() => {
              handleLogout();
            }}
          >
            <span>logout</span>
          </LogoutButton>
        </ButtonFooter>
      </CardSection>
    </Card>
  );
}

const Paragraph = styled.p`
  line-height: 1.375rem;
  letter-spacing: 0.0125rem;
  font-size: 0.875rem;
  margin-bottom: 1rem;
`;

export const ArrowLeft = styled(ArrowRight)`
  transform: scaleX(-1);
  width: 0.740625rem;
  margin-right: 0.31125rem;
`;

const ButtonFooter = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 1.575rem 0 1rem 0;
  width: 100%;
`;

export const InverseButton = styled(StyledButton)`
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  border: 0.075rem solid ${({ theme }) => theme.palette.secondary.main};
  color: ${({ theme }) => theme.palette.secondary.main};
  font-weight: bold;
  line-height: 0.075rem;
  padding: 0.5rem;
  margin: 0;
  font-size: 0.75rem;
  border-radius: 0.1875rem;
  margin-right: 0.9375rem;
  margin-bottom: 10px;
  text-decoration: none;

  &:active {
    background: ${({ theme }) => theme.palette.secondary.main};
    color: white;
  }
`;

const LogoutButton = styled(UnstyledButton)`
  background: ${({ theme }) => theme.palette.secondary.main};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.1875rem;
  color: white;
  padding: 0.4rem 1rem;
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 1rem;
  letter-spacing: 0.0175rem;
  text-transform: uppercase;
  text-decoration: none;
  margin-bottom: 10px;
`;
