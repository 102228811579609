import React from 'react';
import _get from 'lodash/get';
import ProgressItem from './ProgressItem';
import sectionIcons from '../../img/sectionIcons';
import { useCurrentApplication } from '../../utils/hooks';

export default function PartyProgress(props) {
  const { data } = useCurrentApplication();
  const application = _get(data, 'application') || {};
  const enrollment = application.enrollment || {};
  const payments = enrollment.payments || {};
  const deposit = payments.deposit || {};
  const tuition = payments.tuition || {};

  return (
    <ProgressItem
      to="party"
      icon={sectionIcons['Party!']}
      description="Party!"
      unlocked={
        !!(
          deposit.complete &&
          payments.method &&
          tuition.complete &&
          enrollment.precourseCompleted &&
          (enrollment.housingStatus === 'Not requested' ||
            enrollment.housingStatus === 'Contract Signed')
        )
      }
    />
  );
}
