import React from 'react';
import _get from 'lodash/get';
import Card from '../Shared/Card';
import LehiTransportation from './LehiTransportation';
import DallasTransportation from './DallasTransportation';
import PhoenixTransportation from './PhoenixTransportation';
import Loading from '../Shared/Loading';
import { useCurrentApplication } from '../utils/hooks';

export default function TransportationWidget() {
  const { loading, error, data } = useCurrentApplication();
  const city = _get(data, 'application.enrollment.cohort.Location_City__c');

  let displayTransportationInfo;
  if (loading) {
    return <Loading />;
  }
  if (error) console.dir(error);
  if (data) {
    switch (city) {
      case 'Lehi':
        displayTransportationInfo = <LehiTransportation />;
        break;
      case 'Dallas':
        displayTransportationInfo = <DallasTransportation />;
        break;
      case 'Phoenix':
        displayTransportationInfo = <PhoenixTransportation />;
        break;
      default:
        return <Loading />;
    }
  }
  return (
    <Card span={1 / 3} header={`transportation - ${city}`} defaultExpanded>
      {displayTransportationInfo}
    </Card>
  );
}
