import React from 'react';
import moment from 'moment';
import ProgressItem from './ProgressItem';
import sectionIcons from '../../img/sectionIcons';
import StyledButton from '../../Styles/StyledButton';
import _get from 'lodash/get';

import { useCurrentApplication } from '../../utils/hooks';

export default function ApplicationProgress(props) {
  const applicationQuery = useCurrentApplication();

  const application = _get(applicationQuery, 'data.application') || {};
  const enrollment = application.enrollment || {};
  const progress = Object.values(application.complete || {}).reduce(
    (acc, value) => (value === true ? acc + 1 : acc),
    0,
  );

  return (
    <ProgressItem
      unlocked
      complete={
        progress >= 3
          ? // We don't have a good way to get an accurate date
            // for the actual completion of the application. This
            // is actually the date they are admitted. If they haven't
            // been admitted yet, it will show the current date.
            moment(enrollment.createdDate).format('MMMM DD, YYYY')
          : null
      }
      to={`/apply`}
      icon={sectionIcons.Application}
      action={progress > 0 ? Action : undefined}
      description="Application"
      progress={progress > 0 ? Math.ceil((progress * 100) / 3) : undefined}
    >
      {progress === 0 && (
        <StyledButton
          variant="call-to-action"
          rightIcon
          style={{ textTransform: 'uppercase' }}
        >
          Begin Application
        </StyledButton>
      )}
    </ProgressItem>
  );
}

const Action = (
  <StyledButton variant="secondary" rightIcon>
    Continue
  </StyledButton>
);
