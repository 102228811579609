import React from 'react';

export default function ThreeDots(props) {
  return (
    <svg
      width="4px"
      height="15px"
      viewBox="0 0 4 15"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      //   xmlns:xlink="http://www.w3.org/1999/xlink"
    >
      <title>F25E272E-D6E2-4490-BC1C-E4100674B901_outlined</title>
      <desc>Created with sketchtool.</desc>
      <defs>
        <path
          d="M10,6.66666667 C10.9166667,6.66666667 11.6666667,5.91666667 11.6666667,5 C11.6666667,4.08333333 10.9166667,3.33333333 10,3.33333333 C9.08333333,3.33333333 8.33333333,4.08333333 8.33333333,5 C8.33333333,5.91666667 9.08333333,6.66666667 10,6.66666667 Z M10,8.33333333 C9.08333333,8.33333333 8.33333333,9.08333333 8.33333333,10 C8.33333333,10.9166667 9.08333333,11.6666667 10,11.6666667 C10.9166667,11.6666667 11.6666667,10.9166667 11.6666667,10 C11.6666667,9.08333333 10.9166667,8.33333333 10,8.33333333 Z M10,13.3333333 C9.08333333,13.3333333 8.33333333,14.0833333 8.33333333,15 C8.33333333,15.9166667 9.08333333,16.6666667 10,16.6666667 C10.9166667,16.6666667 11.6666667,15.9166667 11.6666667,15 C11.6666667,14.0833333 10.9166667,13.3333333 10,13.3333333 Z"
          id="three-dots-path-1"
        />
      </defs>
      <g
        id="Approach-Dashboard-Mobile"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="dashboard-one" transform="translate(-335.000000, -490.000000)">
          <g id="Group-11" transform="translate(16.000000, 467.000000)">
            <g
              id="icon/navigation/more_vert_24px"
              transform="translate(311.000000, 20.500000)"
            >
              <mask id="three-dots-mask-2" fill="white">
                <use xlinkHref="#three-dots-path-1" />
              </mask>
              <g fillRule="nonzero" />
              <g
                id="↳-Color"
                mask="url(#three-dots-mask-2)"
                fill="currentColor"
              >
                <rect id="Rectangle-13" x="0" y="0" width="20" height="20" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
