import React from 'react';
import { useMedia } from 'use-media';

export const MediaQueryContext = React.createContext(false);

export function MediaQueryProvider(props) {
  const isDesktop = useMedia('(min-width: 1024px)');
  return (
    <MediaQueryContext.Provider value={isDesktop}>
      {props.children}
    </MediaQueryContext.Provider>
  );
}
