import React from 'react';
import styled from 'styled-components';
import NavLink from '../Shared/NavLink';
import { SuccessIcon } from '../Styles/Icons';
import { useValidation } from '../Shared/ValidationProvider';

export default function SecondaryNavigation({ sideBar }) {
  const errorContext = useValidation();
  // these are used for determining if a tab is not completed
  const { contact, education, experience, goals } = errorContext.completion;

  return (
    <StyledNav id="secondary-navigation" sideBar={sideBar ? 1 : 0}>
      <NavLink to="../personal-info/contact" default>
        <Tab fullwidth={sideBar ? 1 : 0}>
          {contact ? (
            <IconDiv>
              <SuccessIcon />
            </IconDiv>
          ) : (
            ''
          )}
          <p>CONTACT</p>
          <ErrorSpan show={errorContext.errors.contact}>*</ErrorSpan>
        </Tab>
      </NavLink>
      <NavLink to="../personal-info/education" disabled={!contact}>
        <Tab fullwidth={sideBar ? 1 : 0}>
          {education ? (
            <IconDiv>
              <SuccessIcon />
            </IconDiv>
          ) : (
            ''
          )}
          <p>EDUCATION</p>
          <ErrorSpan show={errorContext.errors.education}>*</ErrorSpan>
        </Tab>
      </NavLink>
      <NavLink to="../personal-info/experience" disabled={!education}>
        <Tab fullwidth={sideBar ? 1 : 0}>
          {experience ? (
            <IconDiv>
              <SuccessIcon />
            </IconDiv>
          ) : (
            ''
          )}
          <p>EXPERIENCE</p>
          <ErrorSpan show={errorContext.errors.experience}>*</ErrorSpan>
        </Tab>
      </NavLink>
      <NavLink to="../personal-info/goals" disabled={!experience}>
        <Tab fullwidth={sideBar ? 1 : 0}>
          {goals ? (
            <IconDiv>
              <SuccessIcon />
            </IconDiv>
          ) : (
            ''
          )}
          <p>GOALS</p>
          <ErrorSpan show={errorContext.errors.goals}>*</ErrorSpan>
        </Tab>
      </NavLink>
    </StyledNav>
  );
}

const IconDiv = styled.div`
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 3px;
  @media (min-width: 1024px) {
    margin-right: 0;
    position: absolute;
    left: -1.5rem;
  }
`;

const StyledNav = styled.nav`
  display: flex;
  justify-content: space-around;
  text-transform: uppercase;
  font-family: ${({theme}) => theme.typography.primary};
  font-weight: bold;
  letter-spacing: 0.008125rem;
  border-bottom: 1px solid ${({ theme }) => theme.colors.lightgray};
  & a {
    color: #bfbfbf;
    font-size: 0.75rem;
  }
  overflow-x: auto;
  @media (max-width: 1024px) {
    & a {
      flex-grow: 1;
    }
  }

  @media (min-width: 1024px) {
    flex-direction: column;
    text-transform: capitalize;
    justify-content: space-between;
    align-items: flex-start;
    margin-left: ${({ sideBar }) => (sideBar ? '0' : '2rem')};
    border-bottom: none;
    padding-top: ${({ sideBar }) => (sideBar ? '0' : '0.8125rem')};
    padding-bottom: ${({ sideBar }) => (sideBar ? '0px' : '1px')};

    a {
      width: 100%;
      padding: 14px 0 14px 48px;
    }
  }
`;

const Tab = styled.div`
  padding: 0.5rem 0 0.3125rem 0;
  font-weight: 500;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ isMatch, theme }) =>
    isMatch
      ? `
    color: ${theme.palette.primary.main}; 
    font-weight: bold;
    `
      : ''};
      @media (max-width: 1024px) {
    ${({ isMatch, theme }) =>
      isMatch
        ? `
    border-bottom: 3px solid ${theme.palette.primary.main}; 
    `
        : ''};
  }

  @media (min-width: 1024px) {
    ${({ isMatch, theme }) => `
        position: relative;
        justify-content: flex-start;
        padding: 0;
        font-weight: 600;
        ${isMatch &&
          `
        p {
          border-bottom: 2px solid ${isMatch ? theme.palette.primary.main : 'white'};
        }
        `}
      `}
  }
`;

const ErrorSpan = styled.span`
  position: relative;
  top: -0.0125rem;
  font-size: 1.75rem;
  font-weight: bold;
  visibility: ${({ show }) => (show ? 'visible' : 'hidden')};
  color: rgba(255, 59, 48, 1);
  @media (min-width: 1024px) {
    line-height: 1rem;
    display: ${({ show }) => (show ? 'inline' : 'none')};
  }
`;
