import React from 'react';

export default function LockIcon(props) {
  return (
    <svg
      width="14px"
      height="20px"
      viewBox="0 0 14 20"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>lock-1</title>
      <desc>Created with Sketch.</desc>
      <g
        id="progress-bar"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Artboard" transform="translate(-312.000000, -88.000000)">
          <g id="Group-10" transform="translate(0.000000, 86.000000)">
            <g id="Group-34" transform="translate(275.000000, 0.000000)">
              <g id="lock-1" transform="translate(32.000000, 0.000000)">
                <g id="Frame-24px">
                  <rect
                    id="Rectangle"
                    x="0"
                    y="0"
                    width="24"
                    height="24"
                  ></rect>
                </g>
                <g
                  id="Filled_Icons"
                  transform="translate(5.000000, 2.000000)"
                  fill="currentColor"
                  fillRule="nonzero"
                  opacity="0.701102121"
                >
                  <path
                    d="M12,9 L12,5 C12,2.243 9.756,0 7,0 C4.242,0 2,2.243 2,5 L2,9 C0.897,9 0,9.896 0,11 L0,18 C0,19.103 0.897,20 2,20 L12,20 C13.103,20 14,19.103 14,18 L14,11 C14,9.896 13.103,9 12,9 Z M10,9 L4,9 L4,5 C4,3.346 5.346,2 7,2 C8.654,2 10,3.346 10,5 L10,9 Z"
                    id="Shape"
                  ></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
