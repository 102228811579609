import React from 'react';

export default function HomeIcon(props) {
  return (
    <svg
      width="15px"
      height="13px"
      viewBox="0 0 15 13"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>home_selected</title>
      <desc>Created with Sketch.</desc>
      <g
        id="HomeIcon-svgs-jonathan"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="HomeIcon-Artboard" transform="translate(-29.000000, -24.000000)">
          <g
            id="HomeIcon-home_selected"
            transform="translate(28.000000, 22.000000)"
          >
            <g id="HomeIcon-Frame_-_24px">
              <rect
                id="HomeIcon-Rectangle"
                x="0"
                y="0"
                width="17"
                height="16.32068"
              />
            </g>
            <g
              id="HomeIcon-Line_Icons"
              transform="translate(1.416667, 2.040000)"
              fill="currentColor"
              fillRule="nonzero"
            >
              <path
                d="M10.625,12.91932 L9.20833333,12.91932 C8.42704167,12.91932 7.79166667,12.31004 7.79166667,11.55932 L7.79166667,9.51932 L6.375,9.51932 L6.375,11.55932 C6.375,12.31004 5.739625,12.91932 4.95833333,12.91932 L3.54166667,12.91932 C2.760375,12.91932 2.125,12.31004 2.125,11.55932 L2.125,7.47932 L0.708333333,7.47932 C0.422166667,7.47932 0.163625,7.31408 0.0538333333,7.05976 C-0.0559583333,6.80612 0.00495833333,6.51372 0.207541667,6.31924 L6.58254167,0.19924 C6.8595,-0.06596 7.30716667,-0.06596 7.584125,0.19924 L13.959125,6.31924 C14.1617083,6.51372 14.222625,6.80612 14.1128333,7.05976 C14.0030417,7.31408 13.7445,7.47932 13.4583333,7.47932 L12.0416667,7.47932 L12.0416667,11.55932 C12.0416667,12.30936 11.4062917,12.91932 10.625,12.91932 Z M5.66666667,8.15932 L8.5,8.15932 C8.891,8.15932 9.20833333,8.46464 9.20833333,8.83932 L9.20833333,11.55932 L10.6257083,11.55932 L10.625,6.79932 C10.625,6.42464 10.9423333,6.11932 11.3333333,6.11932 L11.7484167,6.11932 L7.08333333,1.64152 L2.41825,6.11932 L2.83333333,6.11932 C3.22433333,6.11932 3.54166667,6.42464 3.54166667,6.79932 L3.54166667,11.55932 L4.95833333,11.55932 L4.95833333,8.83932 C4.95833333,8.46396 5.27495833,8.15932 5.66666667,8.15932 Z"
                id="HomeIcon-Shape"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
