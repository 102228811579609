import React from 'react';
import Card from '../Shared/Card';
import TodoItem from '../Shared/TodoItem';
import { useCurrentApplication } from '../utils/hooks';
import _get from 'lodash/get';

export default function FinanceToDo(props) {
  const query = useCurrentApplication();
  let tasks = [];
  if (_get(query, 'data.application.enrollment.payments')) {
    tasks = [
      {
        to: 'acknowledgements',
        description: 'Seat deposit acknowledgement',
        complete: !!query.data.application.enrollment.payments.deposit
          .acknowledged,
      },
      {
        to: 'acknowledgements',
        description: 'Tuition acknowledgement',
        complete: !!query.data.application.enrollment.payments.tuition
          .acknowledged,
      },
      {
        to: 'method',
        description: 'Select payment method',
        complete: !!query.data.application.enrollment.payments.method,
      },
      {
        to: 'payments',
        description: 'Pay seat deposit',
        complete: !!query.data.application.enrollment.payments.deposit.complete,
      },
      {
        to: 'payments',
        description: 'Pay tuition',
        complete: !!query.data.application.enrollment.payments.tuition.complete,
      },
    ].sort((a, b) => {
      if (a.complete && b.complete) {
        return 0;
      }
      if (a.complete && !b.complete) {
        return 1;
      }
      if (!a.complete && b.complete) {
        return -1;
      }
      if (!a.complete && !b.complete) {
        return 0;
      }
      return 0;
    });
  }
  return (
    <Card
      span={1 / 2}
      variant="todo"
      header="To Do - Finance"
      collapsable={false}
    >
      <ol>
        {tasks.map(task => (
          <TodoItem
            key={task.description}
            to={task.to}
            complete={task.complete}
          >
            {task.description}
          </TodoItem>
        ))}
      </ol>
    </Card>
  );
}
