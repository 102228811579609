import React, { useContext } from 'react';
import { Form, ErrorMessage, Formik } from 'formik';
import SaveAndContinue from '../Shared/SaveAndContinue';
import styled from 'styled-components';
import * as Yup from 'yup';
import _get from 'lodash/get';
import StyledH2 from '../Styles/StyledH2';
import Input, { ErrorSpan } from '../Shared/Input';
import StyledSection from '../Styles/StyledSection';
import Radio from '../Shared/Radio';
import CountrySelect from './CountrySelect';
import { ApplicationContextDetails } from '../Shared/ApplicationProvider';
import { showErrors } from '../utils/jsFunctions';
import Loading from '../Shared/Loading';
import ErrorScroll from '../Shared/ErrorScroll';
import { useCompletionEffect, useCurrentApplication } from '../utils/hooks';
// INFO: Leaving in case Sunny wants to reverse decision
// import WarningModal from '../Shared/WarningModal';
import { useValidation } from '../Shared/ValidationProvider';
import { useSnackbar } from '../Shared/SnackbarProvider';
import Checkbox from '../Shared/Checkbox';
import StyledLegend from '../Styles/StyledLegend';

const setInitialValues = data => {
  const application = _get(data, 'application') || {};
  return {
    applicantType: application.applicantType || 'us',
    mailingAddress1: application.mailingAddress1 || '',
    city: application.city || '',
    region: application.region || '',
    postalCode: application.postalCode || '',
    country: application.country || 'US',
    phone: application.phone || '',
    birthday: application.birthday || '',
    genderIdentityAcknowledgement: application.genderIdentityAcknowledgement || '',
  };
};

const validationObj = {
  applicantType: Yup.string()
    .oneOf(['us', 'international'])
    .required('Choose US or International'),
  mailingAddress1: Yup.string()
    .trim()
    .required('Enter a valid mailing address')
    .max(255, 'Max character limit 255'),
  city: Yup.string()
    .trim()
    .required('Enter a valid city')
    .max(40, 'Max character limit 40'),
  region: Yup.string()
    .trim()
    .required('Enter a valid state or region')
    .max(255, 'Max character limit 255'),
  postalCode: Yup.string()
    .trim()
    .required('Enter a valid zip or postal code')
    .max(10, 'Max character limit 10'),
  phone: Yup.string()
    .trim()
    .matches(
      /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/,
      'Enter a valid phone number',
    )
    .required('Enter a valid phone number'),
  birthday: Yup.date()
    .required('Enter a valid birthday in mm/dd/yyyy format')
    .typeError('Enter a valid birthday in mm/dd/yyyy format'),
  genderIdentityAcknowledgement: Yup.bool().oneOf(
    [true],
    'Read and accept the acknowledgement',
  ).required('Read and accept the acknowledgement'),
};

const validationSchema = Yup.object().shape(validationObj);

const Contact = props => {
  // INFO: Leaving in case Sunny wants to reverse decision
  // const [isOpen, setIsOpen] = useState(false);
  const errorContext = useValidation();
  const { localValues, setLocalValues } = useContext(ApplicationContextDetails);
  const { data, loading, cohort } = useCurrentApplication();
  const { openSnackbar } = useSnackbar();
  // INFO: Leaving in case Sunny wants to reverse decision
  // const closeModal = () => {
  //   setIsOpen(false);
  // };
  useCompletionEffect(data);

  if (loading) {
    return <Loading />;
  }

  const isRemote = _get(cohort, 'data.isRemote');

  return (
    <Formik
      initialValues={setInitialValues(data)}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {
        openSnackbar('Contact saved', {
          closeCb: () => {
            setSubmitting(false);
            props.navigate('../education');
          },
        });
        setLocalValues({
          ...localValues,
          application: { ...localValues.application, ...values },
        });
      }}
    >
      {formik => {
        const hasErrors = showErrors('contact', formik);
        if (hasErrors && !errorContext.errors.contact) {
          errorContext.setErrors('contact', true);
        }
        if (!hasErrors && errorContext.errors.contact) {
          errorContext.setErrors('contact', false);
        }
        // INFO: Leaving in case Sunny wants to reverse decision
        // if (formik.values.applicantType === 'international' && isRemote) {
        //   setIsOpen(true);
        // }
        return (
          <Form>
            {/* <WarningModal
              isOpen={isOpen}
              closeModal={() => {
                formik.setFieldValue('applicantType', 'us');
                closeModal();
              }}
              heading="Currently Unavailable"
              body={
                <span>
                  This remote program is currently not accepting international
                  applications. For more information please contact admissions
                  at: <b>844:433-8686</b> or <b>admissions@hackbrightacademy.com</b>
                </span>
              }
            /> */}
            <StyledSection>
              <SectionContainer>
                <StyledH2>Contact Information</StyledH2>
                <Content>
                  {!isRemote && (
                    <ApplicantType>
                      <Radio
                        labelStyle={{
                          padding: '0',
                        }}
                        name="applicantType"
                        id="us"
                        value="us"
                        label="US Applicants"
                      />
                      <Radio
                        labelStyle={{
                          padding: '0',
                        }}
                        name="applicantType"
                        id="international"
                        value="international"
                        label="International Applicants"
                      />
                    </ApplicantType>
                  )}

                  {_get(formik, 'values.applicantType') === 'international' && (
                    <CountrySelect />
                  )}
                  <Input placeholder="Mailing Address" name="mailingAddress1" />
                  <Input name="city" placeholder="City" />
                  <DivContainer>
                    <DivInputContainer>
                      <Input
                        style={{
                          textTransform: formik.values.region
                            ? 'uppercase'
                            : 'none',
                        }}
                        name="region"
                        placeholder={
                          formik.values.applicantType === 'international'
                            ? 'Region'
                            : 'State'
                        }
                      />
                    </DivInputContainer>
                    <DivInputContainer>
                      <Input
                        name="postalCode"
                        placeholder={
                          formik.values.applicantType === 'international'
                            ? 'Postal Code'
                            : 'Zip Code'
                        }
                      />
                    </DivInputContainer>
                  </DivContainer>

                  <Input
                    name="phone"
                    placeholder="Phone"
                    onChange={e => {
                      let unformatted = e.target.value.replace(/\D/g, '');
                      let formatted;

                      if (unformatted.length === 10) {
                        formatted = unformatted.replace(
                          /(\d{3})(\d{3})(\d{4})/,
                          '($1) $2-$3',
                        );
                      } else if (unformatted.length === 11) {
                        formatted = unformatted.replace(
                          /(1)(\d{3})(\d{3})(\d{4})/,
                          '$1 ($2) $3-$4',
                        );
                      }
                      formik.setFieldValue(
                        'phone',
                        formatted || e.target.value,
                      );
                    }}
                  />
                </Content>
              </SectionContainer>
              <SectionContainer>
                <StyledH2>Personal Information</StyledH2>
                <Content>
                  <BirthdayInput
                    onFocus={e => (e.target.placeholder = '')}
                    onBlur={e =>
                      !formik.values.birthday
                        ? (e.target.placeholder = 'Birthday')
                        : null
                    }
                    // TODO: finish this Adding to handle Safari not handling input date
                    // onChange={e =>
                    //   formik.setFieldValue(
                    //     'birthday',
                    //     formatDate(e.target.value),
                    //   )
                    // }
                    name="birthday"
                    placeholder="Birthday"
                    type="date"
                  />
                  <StyledLegend>
                    To support our mission of changing the ratio in tech,
                    we are currently only admitting those who identify as women,
                    including people who are transgender, agender, genderqueer, or non-binary.
                    </StyledLegend>
                  <Checkbox
                    name="genderIdentityAcknowledgement"
                    id="genderIdentityAcknowledgement"
                    label="I Understand"
                    labelStyle={{
                      color: 'black',
                      padding: '0',
                      textTransform: 'uppercase',
                      fontFamily: 'Montserrat',
                      fontWeight: 'bold',
                      letterSpacing: '0.008125rem',
                    }}
                  />
                  <div>
                    <ErrorMessage name="genderIdentityAcknowledgement">
                      {err => <ErrorSpan>{err}</ErrorSpan>}
                    </ErrorMessage>
                    <span style={{ visibility: 'hidden' }}>*</span>
                  </div>
                </Content>
              </SectionContainer>
              <SectionContainer>
                <Content>
                  <SaveAndContinue disabled={formik.isSubmitting} />
                </Content>
              </SectionContainer>
              <ErrorScroll />
            </StyledSection>
          </Form>
        );
      }}
    </Formik>
  );
};

export default Contact;

// const Disclaimer = styled.p`
//   font-family: 'Montserrat';
//   font-weight: 300;
//   font-size: 11px;
//   letter-spacing: 0.08px;
//   line-height: 15px;

//   b {
//     font-weight: 600;
//   }

//   @media (min-width: 1024px) {
//     margin-bottom: 0.75rem;
//     width: 58%;
//   }
// `;

const DivContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const ApplicantType = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
  @media (min-width: 1024px) {
    padding: 0.8125rem 0;
  }
`;

const DivInputContainer = styled.div`
  width: 100%;  
`;

const BirthdayInput = styled(Input)`
  &[value=''][placeholder='Birthday'] {
    color: rgb(117, 117, 117);
    ::before {
      padding-right: 0.5rem;
      content: 'Birthday';
    }
  }
`;

export const SectionContainer = styled.section`
  display: flex;
  flex-direction: column;
  @media (min-width: 1024px) {
    flex-direction: row;
    justify-content: flex-end;
  }
`;

export const Content = styled.div`
  @media (min-width: 1024px) {
    width: 58%;
  }
`;

// function formatDate(num) {
//   let addSlash = (str, len) => {
//     if (str && str.length === len) return str + '/';
//     return str;
//   };

//   let baseVal = num.replace(/\//g, '');
//   let mm = addSlash(baseVal.substring(0, 2), 2);
//   let dd = addSlash(baseVal.substring(2, 4), 2);
//   let yy = baseVal.substring(4, 8);

//   return mm + dd + yy;
// }
