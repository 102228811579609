import React, { useContext, useState } from 'react';
import { FieldArray, Form, Formik } from 'formik';
import styled from 'styled-components';
import _get from 'lodash/get';
import StyledSection from '../Styles/StyledSection';
import StyledH2 from '../Styles/StyledH2';
import StyledLegend from '../Styles/StyledLegend';
import ExperienceItem, { ExperienceItemHeader } from './ExperienceItem';
import { UnstyledButton } from '../Styles/StyledButton';
import PlusIcon from '../img/PlusIcon';
import Loading from '../Shared/Loading';
import { ApplicationContextDetails } from '../Shared/ApplicationProvider';
import { useSnackbar } from '../Shared/SnackbarProvider';
import SaveAndContinue from '../Shared/SaveAndContinue';
import { useCompletionEffect, useCurrentApplication } from '../utils/hooks';
import ErrorScroll from '../Shared/ErrorScroll';
import { showErrors } from '../utils/jsFunctions';
import { useValidation } from '../Shared/ValidationProvider';
import { SectionContainer, Content } from './Contact';
import * as Yup from 'yup';
import Input from '../Shared/Input';

// No reason to allow more than 10000 characters
// Some people might try to type a number in string. MAke sure it's number to match SF validation
const validationSchema = Yup.object().shape({
  workExperience: Yup.array()
    .of(
      Yup.object().shape({
        position: Yup.string().max(255, 'Max character limit 255'),
        duration: Yup.string()
          .trim()
          .matches(/\d+(\.{1}\d{1,2})?/, {
            message: 'Must be a number value with up to two decimal places',
            excludeEmptyString: true,
          }),
        description: Yup.string().max(10000, 'Max character limit 10,000'),
      }),
    )
    .max(3),
  linkedIn: Yup.string().url('Enter a valid URL').required('Enter a valid URL')
    .max(255, 'Enter a valid URL under 255 characters'),
});

const setInitialValues = data => {
  let experience = _get(data, 'application.workExperience', []);
  let linkedIn = _get(data, 'application.linkedIn') || '';
  // Make sure that experience cannot be null
  if (!experience) experience = [];
  // set a default value for expanded
  if (Array.isArray(experience) && !experience.length)
    experience = [
      {
        position: '',
        duration: '',
        description: '',
      },
    ];

  return {
    linkedIn,
    workExperience: experience,
  };
};

function Experience(props) {
  const { data, loading } = useCurrentApplication();
  const errorContext = useValidation();
  const { localValues, setLocalValues } = useContext(ApplicationContextDetails);
  const [shouldNavigate, setShouldNavigate] = useState(true);
  const [newItem, setNewItem] = useState(null);
  const { openSnackbar, updateSnackbar, isOpen } = useSnackbar();

  useCompletionEffect(data);

  if (loading) {
    return <Loading />;
  }

  return (
    <Formik
      // enableReinitialize
      initialValues={setInitialValues(data)}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {
        let snackbarMethod = openSnackbar;
        let snackOpt = {};
        if (shouldNavigate)
          snackOpt.closeCb = () => {
            if (shouldNavigate) {
              props.navigate('../goals');
            }
          };
        if (isOpen) snackbarMethod = updateSnackbar;
        snackbarMethod('Experience saved', snackOpt);

        if (!shouldNavigate) setSubmitting(false);

        setLocalValues({
          ...localValues,
          application: { ...localValues.application, ...values },
        });
      }}
    >
      {formik => {
        const hasErrors = showErrors('experience', formik);
        if (hasErrors && !errorContext.errors.experience) {
          errorContext.setErrors('experience', true);
        }
        if (!hasErrors && errorContext.errors.experience) {
          errorContext.setErrors('experience', false);
        }
        return (
          <Form>
            <StyledSection>
            <SectionContainer>
                <StyledH2>Professional Experience</StyledH2>
                <Content>
                  <Input placeholder="LinkedIn URL" name="linkedIn" />
                </Content>
              </SectionContainer>
              <SectionContainer>
                <StyledH2>Work Experience</StyledH2>
                <Content>
                  <StyledLegend style={{ marginTop: 0 }}>
                    List your work experience or any experience relevant to your
                    course (maximum of three):
                  </StyledLegend>
                  <FieldArray name="workExperience">
                    {helpers => {
                      return (
                        <>
                          {formik.values.workExperience.map(
                            (experience, i, expArr) => {
                              const {
                                position,
                                duration,
                                description,
                              } = experience;

                              const hasErrors =
                                _get(formik, `errors.workExperience[${i}]`) ||
                                {};

                              return (
                                <ExperienceItem
                                  // eslint-disable-next-line react/no-array-index-key
                                  key={i}
                                  i={i}
                                  isNew={
                                    newItem === i ||
                                    isFirstItem(experience, i, expArr.length)
                                  }
                                  errors={hasErrors}
                                  complete={position || duration || description}
                                  remove={e => {
                                    helpers.remove(i);
                                    setShouldNavigate(false);
                                    // this is a hack so that the updated values in the form
                                    // are submitted
                                    if (position || duration || description) {
                                      setTimeout(formik.submitForm, 0);
                                    }
                                  }}
                                  save={() => {
                                    // set isSubmitting to false to not disable save and continue
                                    setShouldNavigate(false);
                                    if (position || duration || description) {
                                      formik.submitForm();
                                    }
                                  }}
                                />
                              );
                            },
                          )}
                          {formik.values.workExperience.length <= 2 && (
                            <ExperienceItemHeader
                              style={{ marginBottom: '2.5rem' }}
                              type="button"
                              onClick={e => {
                                e.preventDefault();
                                helpers.insert(
                                  formik.values.workExperience.length,
                                  {
                                    position: '',
                                    duration: '',
                                    description: '',
                                  },
                                );
                                setNewItem(formik.values.workExperience.length);
                              }}
                            >
                              <StyledDiv>
                                <PlusIcon />{' '}
                                <StyledH3>
                                  Add Work Experience #
                                  {formik.values.workExperience.length + 1}
                                </StyledH3>
                              </StyledDiv>
                            </ExperienceItemHeader>
                          )}
                        </>
                      );
                    }}
                  </FieldArray>
                </Content>
              </SectionContainer>
              
              <SectionContainer>
                <Content>
                  <SaveAndContinue
                    disabled={formik.isSubmitting}
                    clickCb={() => {
                      setShouldNavigate(true);
                    }}
                    topMargin="70px"
                  />
                </Content>
              </SectionContainer>
              <ErrorScroll />
            </StyledSection>
          </Form>
        );
      }}
    </Formik>
  );
}

export default Experience;

const isFirstItem = ({ length, position, description }, index, arrLength) => {
  if (!length && !position && !description && index === arrLength - 1)
    return true;
};

const StyledDiv = styled.div`
  display: flex;
  align-items: center;
  ${({ highlighted, theme }) =>
    highlighted ? `color: ${theme.palette.secondary.dark};` : ''}
`;

const StyledH3 = styled.h3`
  padding: 0 1rem;
`;
