import React from 'react';
import styled from 'styled-components';
import Card from '../Shared/Card';
import CardSection from '../Styles/CardSection';
import ClimbImgLogo from '../img/climb-logo-black.png';
import skillsFundLogo from '../img/skillsFundLogo.png';

export default function FinancingOptions(props) {
  return (
    <Card span={1 / 2} header="Financing Options" defaultExpanded>
      <CardSection>
        <StyledP>Need help financing your coding bootcamp experience?</StyledP>
        <StyledP>
          You can use whatever financing method you'd like. If you're looking
          for guidance, you might want to try one of our recommended options.
        </StyledP>
        <ImageContainer>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://climbcredit.com/hackbrightacademy"
          >
            <StyledImg src={ClimbImgLogo} alt="climb logo" width="47" />
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://hackbrightacademy.skills.fund"
          >
            <StyledImg src={skillsFundLogo} width="67" />
          </a>
        </ImageContainer>
        <StyledP>
          For more information on financing, visit:{' '}
          <StyledA
            target="_blank"
            rel="noopener noreferrer"
            href="https://hackbrightacademy.com/payment-plans/"
          >
            hackbrightacademy.com/payment-plans/
          </StyledA>
        </StyledP>
        <StyledAside>
          With federal loans unavailable, the above lender list is designed to
          help you begin private-loan research. It is not a comprehensive list.
          Students are encouraged to find a lender that best suits their needs.
          Hackbright Academy does not endorse any particular lender nor determine their
          business practices. 
        </StyledAside>
      </CardSection>
    </Card>
  );
}

const StyledP = styled.p`
  margin-bottom: 1rem;
`;

const StyledAside = styled(StyledP)`
  font-size: 0.75rem;
  font-weight: 300;
  font-family: ${({ theme }) => theme.typography.primary};
`;

const StyledA = styled.a`
  color: ${({ theme }) => theme.palette.secondary.main};
  text-decoration: none;
  font-weight: bold;
`;

const StyledImg = styled.img`
  height: 2rem;
  width: auto;
  @media (min-width: 1024px) {
    height: 1.375rem;
  }
`;

const ImageContainer = styled.div`
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  @media (min-width: 1024px) {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }
`;
