import styled from 'styled-components';
import React from 'react';
import ArrowRight from '../img/ArrowRight';

export const UnstyledButton = styled.button`
  /* Reset default styles (from Normalize) */

  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
  overflow: visible;
  text-transform: none;
  border: none;
  background: none;
  width: ${props => (props.fullwidth ? '100%' : 'auto')};
  padding: 0;
  :hover {
    cursor: pointer;
  }
`;

const StyledButton = props => (
  <StandardButton {...props}>
    {props.leftIcon && <Arrow left variant={props.variant} />}

    {props.children}
    {props.rightIcon && <Arrow variant={props.variant} />}
  </StandardButton>
);
export default StyledButton

const Arrow = styled(ArrowRight)`
  width: 0.6875rem;
  height: 0.375rem;

  ${({ left, variant }) => {
    const margin = variant === 'call-to-action' ? '0.5rem' : '0.25rem';
    return left
      ? `
  transform: rotate(180deg);
  margin: 0 ${margin} 0 0;
  `
      : `margin: 0 0 0 ${margin};`;
  }};

  ${({ variant }) =>
    variant === 'call-to-action'
      ? `height: 0.5rem; 
  width: 0.9375rem; `
      : ''}
`;

export const StandardButton = styled(UnstyledButton)`
  /* Our styles */
  text-decoration: none;
  line-height: 3.125rem;
  font-size: 1rem;
  padding: 0 2rem;
  background: none;
  border: 1px solid ${({ theme }) => theme.colors.lightgray};
  outline: none;
  border-radius: 3px;

  ${({ theme, variant = 'standard' }) => {
    switch (variant) {
      case 'standard':
        return `
        color: ${theme.colors.darkgray};
        
        &:active, &:hover {
          color: ${theme.palette.secondary.main};
          border: 1px solid ${theme.palette.gray[400]};
          background: ${theme.palette.gray[400]};
        }
        `;

      case 'primary':
        return `
          display: inline-flex;
          justify-content: space-evenly;
          align-items: center;
          border: 1.2px solid ${theme.palette.gradient};
          background-color: ${theme.palette.gradient};
          color: white;
          font-weight: bold;
          border-radius: 3px;
          font-size: 0.75rem;
          line-height: 0.75rem;
          padding: 0.5rem;

          &:active {
            background: ${theme.palette.secondary.dark}
            outline: none;
          }

          &:hover {
            background: ${theme.palette.secondary.dark}
          }
        `;

      case 'secondary':
        return `
          display: inline-flex;
          justify-content: space-evenly;
          align-items: center;
          border: 1.2px solid ${theme.palette.primary.main};
          background: ${theme.palette.primary.main};
          color: ${theme.palette.secondary.main};
          font-weight: bold;
          border-radius: 3px;
          font-size: 0.75rem;
          line-height: 0.75rem;
          padding: 0.5rem;

          &:active {
            background: ${theme.palette.primary.dark};
            color: white;
            ouline: none;
          }
          
          &:hover {
            background: ${theme.palette.primary.dark};
            color: white;
          }
        `;
      case 'call-to-action':
        return `
          display: inline-flex;
          justify-content: space-evenly;
          align-items: center;
          border: 1.2px solid ${theme.palette.primary.main};
          background-color: ${theme.palette.primary.main};
          color: white;
          font-weight: 600;
          border-radius: 3px;
          font-size: 0.875rem;
          line-height: 0.875rem;
          padding: 0.65625rem 1rem;
          letter-spacing: 0.28pt;

          &:active {
            border-color: ${theme.palette.primary.dark};
            background: ${theme.palette.primary.dark};
            outline: none;
          }

          &:hover {
            background: ${theme.palette.primary.dark};
            border-color: ${theme.palette.primary.dark};
          }

        `;
      case 'complete':
        return `
          display: inline-flex;
          justify-content: space-evenly;
          align-items: center;
          border: 1.2px solid ${theme.colors.lightGreen};
          background-color: ${theme.colors.lightGreen};
          color: white;
          font-weight: bold;
          border-radius: 3px;
          font-size: 0.75rem;
          line-height: 0.75rem;
          padding: 0.5rem;
        `;
      default:
        return `
        border: 1px solid ${theme.palette.primary.light};
        color: white;
        font-weight: bold;
        background: ${theme.palette.gradient};

        &:active {
          background: ${theme.palette.secondary.dark};
        }

        &:hover {
          background: ${theme.palette.primary.dark};
          border-color: ${theme.palette.primary.dark};
        }
        
      `;
    }
  }};

  &:disabled, &[disabled] {
    background: ${({ theme }) => theme.colors.lightgray};
    color: ${({ theme }) => theme.colors.darkgray};
    border: ${({ theme }) => theme.colors.lightgray};
  }

  /* ${({ variant }) =>
    variant && variant.indexOf('white-and-blue') !== -1
      ? `background: white;
  color: #0EB6FE;
  border-radius: 3px;
  height: 30px;
  padding: 4px 8px;
  font-size: .8rem;
  line-height: initial;
  `
      : `
  `};

  ${({ variant }) =>
    variant && variant.indexOf('disabled') !== -1
      ? `background: rgba(191,191,191,1) !important;
      color: white !important;
      border: none;
      cursor: not-allowed;`
      : `
  `};
  ${props =>
    props.marginBottom ? `margin-bottom: ${props.marginBottom}` : null} */

  /* :hover {
    cursor: pointer;
  } */
`;
