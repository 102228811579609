import React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import _get from 'lodash/get';

export default function PaymentDueMessage(props) {
  const tuitionComplete = _get(props, 'payments.tuition.complete');
  const tuitionAmount = _get(props, 'payments.tuition.amount');
  const tuitionOutstanding = _get(props, 'payments.tuition.outstanding');
  const tuitionDate = _get(props, 'payments.tuition.due');
  const depositComplete = _get(props, 'payments.deposit.complete');
  const depositAmount = _get(props, 'payments.deposit.amount');
  const depositOutstanding = depositComplete
    ? 0
    : depositAmount - (tuitionAmount - tuitionOutstanding);
  const depositDate = _get(props, 'payments.deposit.due');

  return (
    <StyledContainer>
      <StyledP>
        {tuitionComplete &&
          depositComplete &&
          'Your tuition and deposit are fully paid.'}
        {depositComplete && !tuitionComplete && (
          <>
            Your full tuition of{' '}
            <StyledEm>
              {tuitionOutstanding.toLocaleString(undefined, {
                style: 'currency',
                currency: 'USD',
              })}
            </StyledEm>{' '}
            is due on or before{' '}
            <StyledEm>{moment(tuitionDate).format('MMMM DD, YYYY')}</StyledEm>.
          </>
        )}
        {!depositComplete && (
          <>
            Your full tuition will be due before your first day of class.
            However, your deposit of{' '}
            <StyledEm>
              {depositAmount.toLocaleString(undefined, {
                style: 'currency',
                currency: 'USD',
              })}
            </StyledEm>{' '}
            is due{' '}
            <StyledEm>
              {depositDate
                ? moment(depositDate).format('MMMM DD, YYYY')
                : 'within 7 days'}
              .
            </StyledEm>
          </>
        )}
      </StyledP>

      <StyledP>
        Deposit Balance:{' '}
        <StyledEm>
          {depositOutstanding.toLocaleString(undefined, {
            style: 'currency',
            currency: 'USD',
          })}
        </StyledEm>
      </StyledP>
      <StyledP>
        Tuition Balance:{' '}
        <StyledEm>
          {(tuitionOutstanding - depositOutstanding).toLocaleString(undefined, {
            style: 'currency',
            currency: 'USD',
          })}
        </StyledEm>
      </StyledP>

      <StyledP>
        Total Remaining Balance:{' '}
        <StyledEm>
          {tuitionOutstanding.toLocaleString(undefined, {
            style: 'currency',
            currency: 'USD',
          })}
        </StyledEm>
      </StyledP>
      <StyledP style={{ marginBottom: '2.5rem' }}>
        <strong>Credit card</strong> payments should post within 24 hours.
        <br />
        <strong>Checks</strong> or <strong>wire transfers</strong> will be
        posted shortly after they&apos;re received.
        <br />
        <strong>Loans</strong> will post within one week of final documents
        being signed.
      </StyledP>
    </StyledContainer>
  );
}

const StyledEm = styled.em`
  font-weight: bold;
  font-family: ${({ theme }) => theme.typography.primary};
`;

const StyledP = styled.p`
  margin-bottom: 1.5rem;
  line-height: 1.5rem;
  font-size: 0.875rem;
  letter-spacing: 0.1px;
  strong {
    font-weight: 500;
  }
`;

const StyledContainer = styled.div`
  padding: 0.5rem;
`;
