import React from 'react';
import styled from 'styled-components';
import Card from '../Shared/Card';
import CardSection from '../Styles/CardSection';
import _get from 'lodash/get';

import P from '../Styles/StyledP';
import { useCurrentApplication } from '../utils/hooks';

export default function AgreementWidget({ id }) {
  const application = useCurrentApplication();
  return (
    <Card
      span={2 / 3}
      header="student agreement"
      defaultExpanded
      id="student-agreement"
    >
      <CardSection>
        <P variant="paragraph">
          Before you start class, you'll need to sign your Student Agreement.
          Read it carefully. Not only does it have a riveting section about
          hygiene, it&apos;s also filled with important information.
        </P>

        <Div>
          <P variant="paragraph">
          <b>
            {_get(
              application,
              'data.application.enrollment.signedStudentAgreement',
            ) ? (
                "You're all set! Your student agreement has been completed."
              ) : ("You'll receive and complete your student agreement via email.")}
              </b>
          </P>
        </Div>
      </CardSection>
    </Card>
  );
}

const Div = styled.div`
  display: flex;
`;
