import React from 'react';
import { ErrorMessage } from 'formik';
import StyledH2 from '../Styles/StyledH2';
import StyledLegend from '../Styles/StyledLegend';
import Checkbox from '../Shared/Checkbox';
import { ErrorSpan } from '../Shared/Input';
import { SectionContainer, Content } from './Contact';

export default function Acknowledgement({ isRemote }) {
  return (
    <SectionContainer style={{ marginTop: '2rem' }}>
      <StyledH2 style={{ lineHeight: '1.5626rem', letterSpacing: '0.43px' }}>
        Acknowledgement: <br />
        Bootcamps are hard!
      </StyledH2>
      <Content>
        {isRemote ? (
          <StyledLegend>
            The course you&apos;re applying for meets for thirteen weeks, at
            least 8 hours a day, Monday through Friday, and requires additional
            studying during evenings and weekends. Students can&apos;t take
            vacations or skip class. It's not for the faint of heart.
          </StyledLegend>
        ) : (
          <StyledLegend>
            The course you&apos;re applying for meets for thirteen weeks from 9
            AM - 5 PM, Monday through Friday, and requires additional studying
            during evenings and weekends. Students can&apos;t take vacations or
            skip class. It can be wonderfully empowering, but is not for the
            faint of heart.
          </StyledLegend>
        )}

        <Checkbox
          name="acknowledgement"
          id="acknowledgement"
          label="I Understand"
          labelStyle={{
            color: 'black',
            textTransform: 'uppercase',
            fontFamily: 'Montserrat',
            fontWeight: 'bold',
            letterSpacing: '0.008125rem',
          }}
        />
        <div>
          <span style={{ visibility: 'hidden' }}>*</span>
          <ErrorMessage name="acknowledgement">
            {err => <ErrorSpan>{err}</ErrorSpan>}
          </ErrorMessage>
        </div>
      </Content>
    </SectionContainer>
  );
}
