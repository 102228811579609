import React, { useState } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import Loading from '../Shared/Loading';
import { useCurrentApplication } from '../utils/hooks';
import _get from 'lodash/get';

export default function PaymentHistory(props) {
  const [viewMore, setViewMore] = useState(false);
  const { data } = useCurrentApplication();
  const history = _get(data, 'application.enrollment.payments.history') || [];

  if (!data) return <Loading />;
  return (
    <>
      <StyledTable style={{ marginTop: '2rem' }}>
        <StyledCaption>Recent Activity</StyledCaption>

        <PanelTbody>
          {(viewMore ? history : history.slice(0, 3)).map(payment => (
            <StyledTr key={payment.id}>
              <StyledTd>
                <DateSpan>{moment(payment.date).format('MMM DD, YY')}</DateSpan>
              </StyledTd>
              <StyledTd>
                <div style={{ maxHeight: '3em', overflow: 'hidden' }}>
                  <CategoryP>{payment.category}</CategoryP>
                  <MethodP>
                    {payment.method.replace(/stripe/gi, 'Credit Card')}
                  </MethodP>
                </div>
              </StyledTd>
              <StyledTd>
                {/* Payments are listed as positive amounts in Salesforce, 
                but we decided that was confusing 
                in this ledger/payment history format, 
                so are reversing the sign 
                of all payments in the list */}
                <AmountP>
                  {(payment.amount * -1).toLocaleString(undefined, {
                    style: 'currency',
                    currency: 'USD',
                  })}
                </AmountP>
              </StyledTd>
            </StyledTr>
          ))}
        </PanelTbody>
      </StyledTable>
      <ViewMoreSpan
        onClick={() => {
          setViewMore(!viewMore);
        }}
      >
        <span>{viewMore ? 'View Less' : 'View More'}</span>
      </ViewMoreSpan>
    </>
  );
}

const ViewMoreSpan = styled.span`
  color: ${({ theme }) => theme.palette.text.secondary};
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 0.875rem;
  &:hover {
    cursor: pointer;
  }
`;

const PanelTbody = styled.tbody`
  position: relative;
  overflow: hidden;
  transition: max-height 0.5s ease;
`;

const StyledCaption = styled.caption`
  color: ${({ theme }) => theme.palette.text.secondary};
  font-family: ${({ theme }) => theme.typography.secondary};
  font-size: 13px;
  font-weight: bold;
  letter-spacing: 0.17px;
  line-height: 15px;
  text-align: left;
  padding-top: 0.5rem;
  padding-bottom: 1rem;
`;

const StyledTd = styled.td`
  overflow: hidden;
  padding: 1.3125rem 0;
  font-size: 0.75rem;
  line-height: 0.75rem;
  letter-spacing: 0.16px;
  font-family: ${({ theme }) => theme.typography.primary};
  text-align: left;
  :nth-of-type(3) {
    text-align: right;
  }
  /* p {
    line-height: 0.625rem;
  } */
  /* p:nth-of-type(2) {
    font-style: italic;
  } */
`;

const StyledTr = styled.tr`
  border-bottom: 1px solid ${({ theme }) => theme.colors.lightgray};
  :first-of-type {
    border-top: 1px solid ${({ theme }) => theme.colors.lightgray};
  }
`;

const StyledTable = styled.table`
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
`;

const DateSpan = styled.span`
  text-transform: uppercase;
`;

const CategoryP = styled.p`
  text-transform: uppercase;
`;

const MethodP = styled.p`
  font-style: italic;
`;

const AmountP = styled.p`
  font-size: 0.75rem;
`;
