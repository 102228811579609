import React from 'react';
import { Router, useLocation } from '@reach/router';
import styled from 'styled-components';
import NavLink from '../Shared/NavLink';
import FilledPersonIcon from '../img/FilledPerson';
import UserChatFilled from '../img/UserChatFilled';
import ComputerScreenFilled from '../img/ComputerScreenFilled';
import SuccessIconComponent from '../img/SuccessIcon';
import LockIconComponent from '../img/Icon_Lock.js';
import { useCurrentApplication } from '../utils/hooks';
import SecondaryNavigation from './SecondaryNavigation';
import _get from 'lodash/get';
import { useContext } from 'react';
import { MediaQueryContext } from '../Shared/MediaQueryProvider';

export default function PrimaryNavigation(props) {
  const isDesktop = useContext(MediaQueryContext);
  const location = useLocation();
  const { data, error } = useCurrentApplication();
  if (error) console.dir(error);

  const personalComplete = _get(data, 'application.complete.personalInfo');
  const consultationComplete = _get(data, 'application.complete.consultation');
  const skillReviewUnlocked = consultationComplete;

  const skillReviewComplete =
    _get(data, 'application.complete') &&
    (data.application.complete.skillReviewSubmitted ||
      data.application.complete.skillReviewGraded);
  const consultationIcon = setConsultationIcon(
    consultationComplete,
    personalComplete,
    location.pathname === '/apply/consultation',
  );
  const skillReviewIcon = setSkillReviewIcon(
    skillReviewComplete,
    skillReviewUnlocked,
    location.pathname === '/apply/skill-review',
  );
  const personalInfoOpt = {};
  if (!personalComplete || isDesktop) personalInfoOpt.borderBottom = 'none';
  return (
    <div>
      <StyledNav>
        {/* We need the match to be personal-info/*
      so it will be highlighted for all the subsections */}
        <NavLink
          match="personal-info/*"
          to="personal-info"
          style={{ width: '100%' }}
        >
          <StyledDiv
            complete={personalComplete}
            sideBar
            style={personalInfoOpt}
          >
            <LabelContainer>
              <IconDiv>
                {personalComplete ? <SuccessIcon /> : <FilledPersonIcon />}
              </IconDiv>
              <StyledP>Personal Info</StyledP>
            </LabelContainer>
          </StyledDiv>
        </NavLink>
        {isDesktop && !personalComplete && (
          <DesktopSecondary sideBar>
            <Router primary={false}>
              <SecondaryNavigation path="personal-info/*" sideBar />
            </Router>
          </DesktopSecondary>
        )}
        <NavLink
          match="consultation/*"
          to="consultation"
          style={{ width: '100%' }}
          disabled={!personalComplete}
        >
          <StyledDiv complete={consultationComplete} showBorder>
            <LabelContainer>
              <IconDiv>{consultationIcon}</IconDiv>
              <StyledP>
                <DesktopOnly>Phone </DesktopOnly>Consultation
              </StyledP>
            </LabelContainer>
          </StyledDiv>
        </NavLink>
        <NavLink
          match="skill-review/*"
          to="skill-review"
          style={{ width: '100%' }}
          disabled={!consultationComplete}
        >
          <StyledDiv complete={skillReviewComplete}>
            <LabelContainer>
              <IconDiv>{skillReviewIcon}</IconDiv>
              <StyledP>Tech Review</StyledP>
            </LabelContainer>
          </StyledDiv>
        </NavLink>
      </StyledNav>
      {!isDesktop && !personalComplete && (
        <MobileSecondary>
          <Router primary={false}>
            <SecondaryNavigation path="personal-info/*" />
          </Router>
        </MobileSecondary>
      )}
    </div>
  );
}

const setConsultationIcon = (
  consultationComplete,
  personalComplete,
  active,
) => {
  const opt = {};
  if (!active) opt.fill = '#aaaaaa';
  if (!personalComplete) return <LockIcon />;
  if (consultationComplete) return <SuccessIcon height="1.2rem" />;
  else return <UserChatFilled {...opt} />;
};

const setSkillReviewIcon = (
  skillReviewComplete,
  skillReviewUnlocked,
  active,
) => {
  const opt = {};
  if (!active) opt.fill = '#aaaaaa';
  if (!skillReviewUnlocked) return <LockIcon />;
  if (skillReviewComplete) return <SuccessIcon height="1.2rem" />;
  else return <ComputerScreenFilled {...opt} />;
};

const StyledNav = styled.nav`
  display: flex;
  justify-content: space-around;
  border-bottom: 1px solid rgba(216, 216, 216, 1);
  @media (min-width: 1024px) {
    width: 220px;
    border-radius: 0 0 3px 3px;
    box-shadow: 0 1px 11px 0 rgba(0, 0, 0, 0.1);
    flex-direction: column;
    border-bottom: none;
  }
`;

const StyledP = styled.p`
  text-transform: uppercase;
  font-family: ${({theme}) => theme.typography.primary};
  letter-spacing: 0.008125rem;
  font-weight: bold;
  font-size: 0.75rem;
  padding-top: 8px;
  @media (min-width: 1024px) {
    padding: 0;
    font-weight: 500;
  }
`;

const IconDiv = styled.div`
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0.5rem;
`;

const LockIcon = styled(LockIconComponent)`
  color: #aaaaaa;
  @media (min-width: 1024px) {
    height: 20px;
    width: 14px;
  }
`;

export const SuccessIcon = styled(SuccessIconComponent)`
  color: ${({ theme }) => theme.colors.midgreen};
  @media (min-width: 1024px) {
    height: 12px;
    width: 12px;
  }
`;

const StyledDiv = styled.div`
  color: rgba(166, 166, 166, 1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem 0;
  text-decoration: none;
  ${({ isMatch, theme }) =>
    isMatch
      ? `
      & p {
        color: black;
      }
  color: ${theme.palette.primary.main};
  border-bottom: 3px solid ${theme.palette.primary.main};
  `
      : ''};

  & ${SuccessIcon} {
    ${({ theme }) => `color: ${theme.colors.lightGreen};`}
  }

  @media (min-width: 1024px) {
    flex-direction: column;
    /* border-bottom: none; */
    border-top: ${({showBorder, theme}) => showBorder ? '1px solid ' + theme.palette.secondary.dark : 'none'};
    border-bottom: ${({showBorder, theme}) => showBorder ? '1px solid ' + theme.palette.secondary.dark : 'none'};
    background: ${({ isMatch, theme }) => (isMatch ? theme.palette.secondary.dark : theme.palette.secondary.main)};
    padding: 13px 13px 13px 16px;
    align-items: flex-start;
    // margin: 0.25rem;
    margin-bottom: 0;
    margin-top: 0;
    & ${StyledP} {
      ${({ isMatch }) => (isMatch ? `font-weight: bold;` : '')}
    }
  }
`;

const DesktopSecondary = styled.div`
  background-color: white;
  display: none;
  @media (min-width: 1024px) {
    display: block;
    // ${({ sideBar }) => sideBar && `margin: 0 .25rem 0 .25rem;`}
  }
`;

const MobileSecondary = styled.div`
  display: block;
  @media (min-width: 1024px) {
    display: none;
  }
`;

const LabelContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  @media (min-width: 1024px) {
    flex-direction: row;
  }
`;

const DesktopOnly = styled.span`
  display: none;
  @media (min-width: 1024px) {
    display: inline;
  }
`;
