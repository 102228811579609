import styled from 'styled-components';
import radioChecked from '../img/radio-checked.svg';
import radioUnchecked from '../img/radio-unchecked.svg';

export default styled.input`
  border: none;
  border-radius: 0;
  -webkit-appearance: none;
  background: none;
  box-sizing: content-box;
  appearance: none;
  display: inline-block;
  vertical-align: center;
  margin: 0 4px 0 0;
  height: 1.5rem;
  width: 1.5rem;
  ${({ variant }) => variant === "dashboard" ? `
    width: 1.25rem;
    height: 1.25rem;
  ` : ''}
  border: 0;
  background-image: url(${radioUnchecked});
  & ~ span {
    color: ${({ theme }) => theme.palette.gray[500]};
  }
  &:checked {
    background-image: url(${radioChecked});
  }
  &:checked ~ span {
    color: ${({theme}) => theme.palette.text.input};
    font-weight: bold;
  }
  &:focus {
    outline: none;
  }
  background-size: 18px;
  background-position: top 2px left 2px;
  background-repeat: no-repeat;
`;
