import React from 'react';
import styled from 'styled-components';
import CardSection from '../Styles/CardSection';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { useApplicationId } from '../Shared/ApplicationProvider';
import _get from 'lodash/get';
import { ATag } from './DallasTransportation';

export const GET_HOUSING_MANAGER = gql`
  query GetHousingManager($id: String!) {
    application(id: $id) {
      id
      enrollment {
        id
        housingManager {
          id
          email
        }
      }
    }
  }
`;

export default function DallasCampusInfo(props) {
  const id = useApplicationId();
  const { data } = useQuery(GET_HOUSING_MANAGER, { variables: { id } });
  const email = _get(data, 'application.enrollment.housingManager.email');
  return (
    <CardSection>
      <PHeading>Location</PHeading>

      <Paragraph
        as="a"
        target="_blank"
        href="https://goo.gl/maps/jC7HPM8UGMVunmSw6"
      >
        <Address>500 S. Ervay Street, Suite 101</Address>
        <br />
        <StateAndZip>
          Dallas, TX 75201
          <br />
          (in the lower level of the Butler Brother Building)
        </StateAndZip>
      </Paragraph>
      <PHeading>Parking</PHeading>
      <Paragraph>
        Garage parking is provided for daily commuters. Contact{' '}
        {email ? (
          <ATag href={`mailto:${email}`}>{email}</ATag>
        ) : (
          'your Housing Manager'
        )}{' '}
        to reserve a space.
      </Paragraph>
      <Paragraph>
        Students in housing may purchase daily or monthly parking permits in
        many nearby lots.
      </Paragraph>
      <PHeading>Access</PHeading>
      <Paragraph>
        The Dallas campus is available to students 24 hours a day. Students will
        be given a door code on the first day of class for access.
      </Paragraph>
    </CardSection>
  );
}

const Paragraph = styled.p`
  margin-bottom: 1rem;
  font-size: 0.875rem;
  letter-spacing: 0.00625rem;
  line-height: 1.25rem;
  display: block;
  text-decoration: none;
  color: unset;
`;

const Address = styled.span`
  /* margin-bottom: 0; */
  margin-bottom: 1rem;
  font-size: 0.875rem;
  letter-spacing: 0.00625rem;
  line-height: 1.25rem;
`;

const StateAndZip = styled.span`
  /* margin-bottom: 0; */
  margin-bottom: 1rem;
  font-size: 0.875rem;
  letter-spacing: 0.00625rem;
  line-height: 1.25rem;
`;

const PHeading = styled.h2`
  color: ${({ theme }) => theme.palette.secondary.main};
  font-weight: bold;
  margin-bottom: 0.5rem;
`;
