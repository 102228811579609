import React from 'react';
import PropTypes from 'prop-types';
import { Link, Match } from '@reach/router';
import styled from 'styled-components';

export default function NavLink({ partial, ...props }) {
  return (
    <Match path={`${props.match || props.to}`}>
      {match => {
        return (
          <StyledLink to={props.to} {...props}>
            {React.Children.map(props.children, child => {
              if (!child.type || typeof child.type === 'string') {
                return child;
              }
              return React.cloneElement(child, { isMatch: !!match.match });
            })}
          </StyledLink>
        );
      }}
    </Match>
  );
}

NavLink.propTypes = {
  partial: PropTypes.bool,
};

NavLink.defaultProps = {
  partial: false,
};

const StyledLink = styled(Link)`
  text-decoration: none;
  ${({ disabled }) => {
    return disabled
      ? `
    cursor: not-allowed;
    &:active {
      pointer-events: none;
    }
    `
      : '';
  }}
`;
