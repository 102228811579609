import React from 'react';
import { Formik } from 'formik';
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import Checkbox from '../Shared/Checkbox';
import Loading from '../Shared/Loading';
import { useCurrentApplication } from '../utils/hooks';
import _get from 'lodash/get';
import styled from 'styled-components';

const CHANGE_PAYMENT_METHOD = gql`
  mutation ChangePaymentMethod($updates: EnrollmentInput!) {
    updateEnrollment(updates: $updates)
  }
`;

export default function PaymentMethod(props) {
  const { data, loading, error } = useCurrentApplication();
  const paymentMethod =
    _get(data, 'application.enrollment.payments.method') || '';
    const depositAmount = _get(data, 'application.enrollment.payments.deposit.amount');
  const [mutate] = useMutation(CHANGE_PAYMENT_METHOD, {
    refetchQueries: ['GetApplication'],
  });
  if (loading && !data) return <Loading />;
  if (error) {
    console.dir(error);
    return 'Error';
  }
  if (!_get(data, 'application.enrollment')) {
    console.dir(data);
    return 'Error';
  }
  return (
    <div id="method">
      All students self-pay the first ${depositAmount} of their tuition as a seat deposit.
      How do you plan on paying for the rest of your tuition?
      <Formik
        initialValues={{
          paymentMethod,
        }}
      >
        {({ handleChange }) => {
          const handleSubmit = e => {
            handleChange(e);
            mutate({
              variables: {
                updates: {
                  id: data.application.enrollment.id,
                  paymentMethod: e.target.value,
                },
              },
            });
          };

          return (
            <>
              <Checkbox
                onChange={handleSubmit}
                variant="radio"
                name="paymentMethod"
                id="selfPay"
                value="selfPay"
                label="Self Pay"
              />
              <StyledP>
                (Credit card payments should post within 24 hours. Checks or
                wire transfers will post shortly after they're received.)
              </StyledP>
              <Checkbox
                onChange={handleSubmit}
                variant="radio"
                name="paymentMethod"
                id="financing"
                value="financing"
                label="Using a Hackbright Academy recommended lender"
              />
              <StyledP>
                (Loans typically post within one week of completion of final
                documents.)
              </StyledP>
              <Checkbox
                onChange={handleSubmit}
                variant="radio"
                name="paymentMethod"
                id="unsure"
                value="unsure"
                label="I don't know"
              />
              <StyledP>(Payment processing times will vary.)</StyledP>
              <StyledAside>
                Questions about financing? Visit our{' '}
                <a href="https://hackbrightacademy.com/payment-plans/">
                  Payment Plans page
                </a>{' '}
                for more information.
              </StyledAside>
            </>
          );
        }}
      </Formik>
    </div>
  );
}

const StyledP = styled.p`
  font-size: 0.8em;
  margin-left: 2.625rem;
  margin-top: -0.75rem;
  color: ${({ theme }) => theme.colors.darkgray};
`;

const StyledAside = styled.aside`
  margin-top: 1rem;
  a {
    color: ${({ theme }) => theme.palette.text.secondary};
    font-weight: bold;
    text-decoration: none;
  }
`;
