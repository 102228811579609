import React from 'react';

export default function TaskCompleteIcon(props) {
  return (
    <svg
      width="42px"
      height="42px"
      viewBox="0 0 42 42"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      //   xmlns:xlink="http://www.w3.org/1999/xlink"
    >
      <title>task completed</title>
      <desc>Created with Sketch.</desc>
      <defs>
        <polygon
          id="TaskComplete-path-1"
          points="5.8 10.9 1.6 6.7 0.2 8.1 5.8 13.7 17.8 1.7 16.4 0.3"
        />
      </defs>
      <g
        id="TaskComplete-svgs-jonathan"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="TaskComplete-already-uploaded"
          transform="translate(-87.000000, -86.000000)"
        >
          <g
            id="TaskComplete-task-completed"
            transform="translate(88.000000, 87.000000)"
          >
            <g id="TaskComplete-Group-2" fill="#0CCA8E" stroke="#0CCA8E">
              <circle id="TaskComplete-Oval-16" cx="20" cy="20" r="20" />
            </g>
            <g
              id="TaskComplete-icon/action/done_24px"
              transform="translate(11.000000, 13.000000)"
            >
              <mask id="TaskComplete-mask-2" fill="white">
                <use xlinkHref="#TaskComplete-path-1" />
              </mask>
              <g fillRule="nonzero" />
              <g
                id="TaskComplete-↳-Color"
                mask="url(#TaskComplete-mask-2)"
                fill="#FFFFFF"
              >
                <g
                  transform="translate(-3.000000, -5.000000)"
                  id="TaskComplete-Rectangle"
                >
                  <rect x="0" y="0" width="24" height="24" />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
