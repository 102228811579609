import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import styled from 'styled-components';
import gql from 'graphql-tag';
import moment from 'moment';
import CardSection from '../Styles/CardSection';
import Loading from '../Shared/Loading';
import _get from 'lodash/get';

export const GET_COHORT_STATUS = gql`
  query GetCohortStatus {
    user {
      id
      applications {
        id
        complete {
          personalInfo
        }
        cohort {
          Id
          Date_End__c
          Date_Start__c
          Location_City__c
          Location_State__c
          programName
          Subject__c
        }
      }
    }
  }
`;

export default function AccountStatus(props) {
  const { data, error, loading } = useQuery(GET_COHORT_STATUS);

  if (error) console.dir(error);
  if (loading) return <Loading />;

  return (
    <CardSection style={{ borderTop: '1px solid rgba(231,231,231,1)' }}>
      <StyledHeading>
        Status:{' '}
        <SubHeading>
          {_get(data, 'user.applications[0].complete.personalInfo')
            ? 'Application Submitted'
            : 'Application Started'}
        </SubHeading>
      </StyledHeading>
      <StyledHeading>
        Course:{' '}
        <SubHeading>
          {_get(data, 'user.applications[0].cohort.programName', '')}
        </SubHeading>
      </StyledHeading>
      <StyledHeading>
        Campus:{' '}
        <SubHeading>
          {_get(data, 'user.applications[0].cohort.Location_City__c', '')}
          {', '}
          {_get(data, 'user.applications[0].cohort.Location_State__c', '')}
        </SubHeading>
      </StyledHeading>
      <StyledHeading>
        Dates:{' '}
        <SubHeading>
          {moment(
            _get(data, 'user.applications[0].cohort.Date_Start__c'),
          ).format('MM/D/YY')}
          {' - '}
          {moment(_get(data, 'user.applications[0].cohort.Date_End__c')).format(
            'MM/D/YY',
          )}
        </SubHeading>
      </StyledHeading>
    </CardSection>
  );
}

const StyledHeading = styled.h2`
  font-weight: bold;
  margin-bottom: 1rem;
`;

const SubHeading = styled.span`
  font-weight: normal;
`;
