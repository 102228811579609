import React from 'react';
import styled from 'styled-components';
import { UnstyledButton } from '../Styles/StyledButton';
import phoneIcon from '../img/phoneIcon.svg';
import chat from '../img/chatIcon.svg';
import { useCurrentApplication } from '../utils/hooks';
import _get from 'lodash/get';

function Footer(props) {
  const { data } = useCurrentApplication({ fetchPolicy: 'cache-first' });

  const name = `${_get(data, 'user.first_name', '')} ${_get(
    data,
    'user.last_name',
    '',
  )}`;
  const phone = _get(data, 'application.phone', '');
  return (
    <StyledFooter>
      <StyledDiv>
        <img src={phoneIcon} alt="Phone" style={{ padding: '0 0.625rem' }} />
        <span>415-862-0595</span>
      </StyledDiv>
      <ChatButton
        onClick={() => window.PodiumWebChat.open({ name, phone })}
      >
        <img src={chat} alt="Chat" style={{ padding: '0 0.625rem' }} />
        <span>Help</span>
      </ChatButton>
    </StyledFooter>
  );
}

export default Footer;

const StyledFooter = styled.footer`
  height: 4.375rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${({ theme }) => theme.palette.gray[100]};
  font-family: ${({ theme }) => theme.typography.primary};
  color: ${({theme}) => theme.palette.text.secondary};
  font-weight: bold;
  padding: 0 2.5rem 0 1.875rem;
  @media (min-width: 1024px) {
    padding: 0 3rem 0 2.75rem;
  }
`;

const StyledDiv = styled.div`
  display: flex;
  align-items: center;
`;

const ChatButton = styled(UnstyledButton)`
  display: flex;
  align-items: center;
  color: ${({theme}) => theme.palette.text.secondary};
  font-weight: bold;
`
