import React from 'react';
import styled from 'styled-components';
import { Link } from '@reach/router';
import StyledP from '../Styles/StyledP';
import Card from '../Shared/Card';
import CardSection from '../Styles/CardSection';

export default function EquipmentCard({ remote }) {
  return (
    <Card span={1 / 2} header="equipment" defaultExpanded>
      <CardSection>
        <ParagraphTag>
          Students are expected to use their own laptop computers throughout the
          program. Mac&#39;s are the machine of choice for our Web Development
          course, but they are not required. We recommend all student machines
          have 8GB RAM, 250 GB capacity hard or solid state drives, and a
          dual-core processor.
        </ParagraphTag>
        <ParagraphTag>
          For additional questions regarding equipment please contact your
          course instructor or mentor. This information can be found in your
          <StyledLink to="../onboarding"> student directory</StyledLink>.
        </ParagraphTag>
      </CardSection>
    </Card>
  );
}

const ParagraphTag = styled(StyledP)`
  line-height: 1.25rem;
  letter-spacing: 0.0125rem;
  font-size: 0.895rem;
  margin: 1rem 0;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: ${({ theme }) => theme.palette.secondary.main};
`;
