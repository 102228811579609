import React from 'react';

export default function DownArrow(props) {
  return (
    <svg
      width="10px"
      height="8px"
      viewBox="0 0 10 8"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      //   xmlns:xlink="http://www.w3.org/1999/xlink"
    >
      <title>F6746672-88FD-4EB8-B390-270AFA08D5D4_outlined</title>
      <desc>Created with sketchtool.</desc>
      <defs>
        <polygon
          id="down-arrow-path-1"
          points="13.825 6.91250006 10 10.7291667 6.175 6.91250006 5 8.08750004 10 13.0874999 15 8.08750004"
        />
      </defs>
      <g
        id="down-arrow-Page-2"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="dashboard-one" transform="translate(-304.000000, -454.000000)">
          <g
            id="Course-Details-version100"
            transform="translate(16.000000, 427.000000)"
          >
            <g
              id="icon/navigation/expand_more_24px"
              transform="translate(283.000000, 21.000000)"
            >
              <mask id="mask-2" fill="white">
                <use xlinkHref="#down-arrow-path-1" />
              </mask>
              <g fillRule="nonzero" />
              <g id="↳-Color" mask="url(#mask-2)" fill="currentColor">
                <rect id="Rectangle-13" x="0" y="0" width="20" height="20" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
