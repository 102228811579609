import React from 'react';
import styled from 'styled-components';
import CardSection from '../Styles/CardSection';
import StyledA from '../Styles/StyledA';

export default function PhoenixTransportation(props) {
  return (
    <CardSection>
      <PHeading>Valley Metro</PHeading>
      <Paragraph>
        The Phoenix campus is located within walking distance of the 44th
        St/Washington light rail stop. To purchase a pass with Valley Metro or
        for more information on transportation options visit{' '}
        <ATag as="a" href="https://www.valleymetro.org">
          www.valleymetro.org
        </ATag>
      </Paragraph>
    </CardSection>
  );
}

const PHeading = styled.h2`
  color: ${({ theme }) => theme.palette.secondary.main};
  font-weight: bold;
  margin-bottom: 0.5rem;
`;

const Paragraph = styled.p`
  margin-bottom: 1rem;
  font-size: 0.875rem;
  letter-spacing: 0.00625rem;
  line-height: 1.25rem;
`;

const ATag = styled(StyledA)`
  color: ${({ theme }) => theme.palette.secondary.main};
  text-decoration: none;
  font-weight: bold;
`;
