import React from 'react';
import styled from 'styled-components';

export default function GifContainer(props) {
  return (
    <GifImgContainer>
      <GifImg src="https://media0.giphy.com/media/xUA7b2S7SxhM1cGdsQ/v1.Y2lkPTYxMDQ5NTVlNWQ0ODY2ZGE3NDU5NTE2NDQ5ZDFlZTc0/giphy-downsized.gif" alt="gif" />
    </GifImgContainer>
  );
}

const GifImgContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const GifImg = styled.img`
    width: 10rem;
`;
