import React from 'react';
import styled from 'styled-components';
import CardSection from '../Styles/CardSection';
import { handleLogout } from '../utils/auth';

export default function AccountProfileOptions(props) {
  return (
    <CardSection style={{ borderTop: '1px solid rgba(231,231,231,1)' }}>
      <StyledHeading>Account</StyledHeading>
      <ol>
        <ListItem>
          <a href="https://sso.hackbrightacademy.com/forgot-password">Reset Password</a>
        </ListItem>
        <ListItem
          onClick={() => {
            handleLogout();
          }}
        >
          <span>Logout</span>
        </ListItem>
      </ol>
    </CardSection>
  );
}

const StyledHeading = styled.h2`
  font-weight: bold;
  font-size: 0.9375rem;
  margin-bottom: 1rem;
`;

const ListItem = styled.li`
  margin-bottom: 1rem;
  a {
    color: unset;
    text-decoration: none;
  }
`;
