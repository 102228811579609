import React from 'react';

export default function ConsultationIcon(props) {
  return (
    <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    // xmlns:xlink="http://www.w3.org/1999/xlink"
    >
      <title>female chat</title>
      <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Progress--Bar" transform="translate(-181.000000, -675.000000)">
          <g id="Group-20" transform="translate(-1.000000, 658.000000)">
            <g id="Group-16" transform="translate(148.500000, 17.000000)">
              <g id="female-chat" transform="translate(33.500000, 0.000000)">
                <g id="Frame_-_24px">
                  <rect id="Rectangle" x="0" y="0" width="24" height="24"></rect>
                </g>
                <g id="Filled_Icons" transform="translate(0.000000, 2.000000)" fill="#FB3530" fill-rule="nonzero">
                  <g id="Group">
                    <path d="M15.001,14 C14.849,14 14.695,13.965 14.554,13.894 C14.215,13.725 14.001,13.378 14.001,13 L14.001,11 L13.001,11 L13.001,9 L15.001,9 C15.553,9 16.001,9.448 16.001,10 L16.001,11 L18.401,9.2 C18.574,9.07 18.785,9 19.001,9 L22.001,9 L22.001,2 L12.001,2 L12.001,5 L10.001,5 L10.001,2 C10.001,0.897 10.898,0 12.001,0 L22.001,0 C23.104,0 24.001,0.897 24.001,2 L24.001,9 C24.001,10.102 23.104,11 22.001,11 L19.334,11 L15.601,13.8 C15.424,13.932 15.213,14 15.001,14 Z" id="Path"></path>
                    <path d="M9.33785714,15.9698571 L8.92580952,15.830619 L6.47619048,17.7354286 L4.02657143,15.8298095 L3.61452381,15.9690476 C1.35109524,16.731619 0,18.380619 0,20.3809524 L0,22 L12.952381,22 L12.952381,20.3809524 C12.952381,18.380619 11.6004762,16.731619 9.33785714,15.9698571 Z" id="Path"></path>
                    <path d="M0.80952381,11.4761905 L0.80952381,13.0952381 L3.23809524,13.0952381 C3.97638095,14.0877143 5.1477619,14.7142857 6.47619048,14.7142857 C7.80461905,14.7142857 8.976,14.0877143 9.71428571,13.0952381 L12.1428571,13.0952381 L12.1428571,11.4761905 C12.1428571,3.38095238 0.80952381,3.38095238 0.80952381,11.4761905 Z M6.47619048,13.0952381 C5.44080952,13.0952381 4.56166667,12.4411429 4.2127619,11.526381 C4.25,11.5029048 4.29209524,11.4842857 4.32852381,11.46 C5.38495238,10.7492381 6.24385714,9.6207619 6.47619048,8.23809524 C6.70690476,9.61185714 7.56014286,10.744381 8.62385714,11.4608095 C8.66028571,11.4850952 8.70238095,11.5037143 8.73961905,11.5271905 C8.39152381,12.4411429 7.51157143,13.0952381 6.47619048,13.0952381 Z" id="Shape"></path>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
