import React, { useContext } from 'react';
import { Formik, Form } from 'formik';
import SaveAndContinue from '../Shared/SaveAndContinue';
import { useMutation, useQuery, useLazyQuery } from '@apollo/react-hooks';
import * as Yup from 'yup';
import StyledSection from '../Styles/StyledSection';
import StyledH2 from '../Styles/StyledH2';
import StyledLegend from '../Styles/StyledLegend';
import Checkbox from '../Shared/Checkbox';
import Acknowledgement from './Acknowledgement';
import Input from '../Shared/Input';
import Loading from '../Shared/Loading';
import { CREATE_APPLICATION } from '../Graphql/mutations';
import { showErrors } from '../utils/jsFunctions';
import ErrorScroll from '../Shared/ErrorScroll';
import { GET_APPLICATION, GET_USER } from '../Graphql/queries';
import { useCompletionEffect } from '../utils/hooks';
import { useSnackbar } from '../Shared/SnackbarProvider';
import { useValidation } from '../Shared/ValidationProvider';
import { ApplicationContextDetails } from '../Shared/ApplicationProvider';
import { SectionContainer, Content } from './Contact';
import { submittedApplicationTracking } from '../utils/jsFunctions';
import { useCurrentApplication } from '../utils/hooks';
import { reportCustomMessage } from '../utils/sentryUtils';
import _get from 'lodash/get';

const setInitialValues = data => {
  let goalsVal = _get(data, 'application.goals', '');
  if (!goalsVal && typeof goalsVal !== 'string') goalsVal = '';
  const hearAbout = _get(data, 'application.hearAbout', {});
  return {
    goals: goalsVal,
    hearAbout: {
      currentStudent: hearAbout.currentStudent || false,
      alumna: hearAbout.alumna || false,
      instructorStaff: hearAbout.instructorStaff || false,
      womenWhoCode: hearAbout.womenWhoCode || false,
      google: hearAbout.google || false,
      articleBlog: hearAbout.articleBlog || false,
      event: hearAbout.event || false,
      advertisement: hearAbout.advertisement || false,
      facebookTwitter: hearAbout.facebookTwitter || false,
      linkedIn: hearAbout.linkedIn || false,
      currentEmployer: hearAbout.currentEmployer || false,
      other: hearAbout.other || false,
      otherDetail: hearAbout.otherDetail || '',
    },
    acknowledgement: _get(data, 'application.acknowledgement') || false,
  };
};

const validationSchema = Yup.object().shape({
  goals: Yup.string()
    .ensure()
    .trim()
    .required('Enter a valid response.')
    .max(20000, 'Max character limit is 20,000'),
  hearAbout: Yup.object().shape({
    currentStudent: Yup.string(),
    alumna: Yup.string(),
    instructorStaff: Yup.string(),
    womenWhoCode: Yup.string(),
    google: Yup.string(),
    articleBlog: Yup.string(),
    event: Yup.string(),
    advertisement: Yup.string(),
    facebookTwitter: Yup.string(),
    linkedIn: Yup.string(),
    currentEmployer: Yup.string(),
    other: Yup.string(),
    otherDetail: Yup.string().when('other', {
      is: true,
      then: Yup.string()
        .trim()
        .required('Enter a response.'),
      otherwise: Yup.string(),
    }),
  }),
  acknowledgement: Yup.bool().oneOf(
    [true],
    'Read and accept the acknowledgement',
  ),
});

export default function Goals({ navigate }) {
  const userQuery = useQuery(GET_USER);
  const errorContext = useValidation();
  const { setLocalValues, setId } = useContext(ApplicationContextDetails);
  const { openSnackbar, updateSnackbar } = useSnackbar();

  const [saveApplication] = useMutation(CREATE_APPLICATION);
  // Just refetching applications on useMutation caused a bug with the application provider and applicationcontent. easier to get whole application then route
  const [query] = useLazyQuery(GET_APPLICATION, {
    fetchPolicy: 'network-only',
    onCompleted: result => {
      reportCustomMessage('Submitted application', 'info', {
        progressEvent: 'true',
        name: `${_get(userQuery, 'data.user.first_name')} ${_get(
          userQuery,
          'data.user.last_name',
        )}`,
        email: _get(userQuery, 'data.user.email'),
      });

      updateSnackbar('Application submitted');
      // 2. Clear local values and move on to consultation
      setLocalValues({
        application: { approachDashboard: true, cohortId: '' },
      });

      submittedApplicationTracking(
        _get(result, 'application.cohort.Location_City__c'),
      );

      navigate('/apply/consultation');
    },
    onError(err) {
      console.log('error encountered getting applications', err);
      reportCustomMessage('Error submitting application', 'error', {
        progressEvent: 'true',
        name: `${_get(userQuery, 'data.user.first_name')} ${_get(
          userQuery,
          'data.user.last_name',
        )}`,
        email: _get(userQuery, 'data.user.email'),
      });
      updateSnackbar(
        'There was an error retrieving your application. Please refresh your browser',
      );
    },
  });

  const { data, loading, cohort } = useCurrentApplication();
  useCompletionEffect(data);

  if (loading || userQuery.loading) return <Loading />;
  const isRemote = _get(cohort, 'data.isRemote');

  return (
    <Formik
      // enableReinitialize
      initialValues={setInitialValues(data)}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {
        const application = {
          ...data.application,
          workExperience: JSON.stringify(data.application.workExperience),
          ...values,
          complete: {
            personalInfo:
              errorContext.completion.contact &&
              errorContext.completion.education &&
              errorContext.completion.experience,
          },
          sfContactId: userQuery.data.user.sfId,
        };

        saveApplication({
          variables: {
            application,
          },
        })
          .then(({ data }) => {
            // Set id so consultation can query for it
            setId(data.createApplication);

            // get new id and get application
            query({ variables: { id: data.createApplication } });
          })
          .catch(() => {
            setSubmitting(false);
            updateSnackbar(
              'There was an error encountered while saving your application',
            );
          });

        openSnackbar('Goals saved');
      }}
    >
      {formik => {
        const hasErrors = showErrors('goals', formik);
        if (hasErrors && !errorContext.errors.goals) {
          errorContext.setErrors('goals', true);
        }
        if (!hasErrors && errorContext.errors.goals) {
          errorContext.setErrors('goals', false);
        }

        return (
          <Form>
            <StyledSection>
              <SectionContainer>
                <StyledH2>Goals &amp; Outcome</StyledH2>
                <Content>
                  <StyledLegend style={{ marginTop: '0.75rem' }}>
                    Why would you like to attend Hackbright?
                  </StyledLegend>
                  <Input
                    name="goals"
                    component="textarea"
                    style={{
                      lineHeight: '1rem',
                      height: 'unset',
                    }}
                    rows={8}
                  />
                </Content>
              </SectionContainer>
              <SectionContainer>
                <StyledH2>Additional Information</StyledH2>
                <Content>
                  <StyledLegend>
                    How did you hear about Hackbright? (Select all that apply.)
                  </StyledLegend>
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div>
                      <Checkbox
                        name="hearAbout.currentStudent"
                        id="hearAbout.currentStudent"
                        label="Current Student"
                      />
                      <Checkbox
                        name="hearAbout.alumna"
                        id="hearAbout.alumna"
                        label="Alumna"
                      />
                      <Checkbox
                        name="hearAbout.instructorStaff"
                        id="hearAbout.instructorStaff"
                        label="Instructor/Staff"
                      />
                      <Checkbox
                        name="hearAbout.womenWhoCode"
                        id="hearAbout.womenWhoCode"
                        label="Women Who Code"
                      />
                      <Checkbox
                        name="hearAbout.google"
                        id="hearAbout.google"
                        label="Google Search"
                      />
                      <Checkbox
                        name="hearAbout.articleBlog"
                        id="hearAbout.articleBlog"
                        label="Article/Blog"
                      />

                    </div>
                    <div>
                      <Checkbox
                        name="hearAbout.event"
                        id="hearAbout.event"
                        label="Event"
                      />
                      <Checkbox
                        name="hearAbout.advertisement"
                        id="hearAbout.advertisement"
                        label="Advertisement"
                      />
                      <Checkbox
                        name="hearAbout.facebookTwitter"
                        id="hearAbout.facebookTwitter"
                        label="Facebook/Twitter"
                      />
                      <Checkbox
                        name="hearAbout.linkedIn"
                        id="hearAbout.linkedIn"
                        label="LinkedIn"
                      />
                      <Checkbox
                        name="hearAbout.currentEmployer"
                        id="hearAbout.currentEmployer"
                        label="Current Employer"
                      />
                      <Checkbox
                        name="hearAbout.other"
                        id="hearAbout.other"
                        label="Other"
                      />
                    </div>
                  </div>
                  {formik.values.hearAbout.other && (
                    <Input name="hearAbout.otherDetail" placeholder="Other" />
                  )}
                </Content>
              </SectionContainer>
              <Acknowledgement isRemote={isRemote} />
              <SectionContainer>
                <Content>
                  <SaveAndContinue disabled={formik.isSubmitting} />
                </Content>
              </SectionContainer>
            </StyledSection>
            <ErrorScroll />
          </Form>
        );
      }}
    </Formik>
  );
}
