import React from 'react';
import styled from 'styled-components';

export default function WirePaymentInfo() {
  return (
    <StyledDiv>
      <StyledP>Devmountain wire transfer account numbers:</StyledP>
      <StyledP>
        <StyledEm>Wire Routing:</StyledEm> 026009593
        <br />
        <StyledEm>Account:</StyledEm> 8670206598
      </StyledP>
    </StyledDiv>
  );
}

const StyledEm = styled.em`
  font-weight: bold;
`;

const StyledDiv = styled.div`
  font-size: 0.875rem;
  letter-spacing: 0.1px;
  line-height: 1.625rem;
`;

const StyledP = styled.p`
  margin: 1.5rem 0;
`;
