import React, { useContext, useEffect, useState, useMemo } from 'react';
import styled from 'styled-components';

export const SnackbarContext = React.createContext({
  setContent: () => void 0,
  setOpen: () => void 0,
  setMS: () => void 0,
});

export default function SnackbarProvider(props) {
  const [open, setOpen] = useState(false);
  const [autoClose, setAutoClose] = useState(true);
  const [content, setContent] = useState('');
  const [ms, setMS] = useState(4000);
  const [closeCb, setCloseCb] = useState(null);
  const [timeoutId, setTimeoutId] = useState(null);
  const [resetTime, setResetTime] = useState(false);

  // This sets the proper offset as the screen expands to make sure the snackbar stays underneath save and continue button rows
  const leftPixelDesktop = useMemo(() => {
    if (!open) return 0;
    const buttonRow = document.querySelector('#save-continue-button-row');
    let leftPixels;
    if (buttonRow) {
      let left = buttonRow.getBoundingClientRect().left;
      leftPixels = left ? left - 10 : 0;
    }
    return leftPixels;
  }, [open]);

  useEffect(() => {
    const runSetTimeout = () => {
      // In case there is already a timeout id, clear it
      if (timeoutId) clearTimeout(timeoutId);
      setTimeoutId(
        setTimeout(() => {
          if (autoClose) {
            setOpen(false);
            setContent('');
            setTimeoutId(null);
            if (closeCb && typeof closeCb === 'function') {
              closeCb();
              // reset this here otherwise it might be run again from another component
              setCloseCb(null);
            }
          }
        }, ms),
      );
    };
    // It it was a reset request coming in, reset snackbar
    if (resetTime) {
      runSetTimeout();
      setResetTime(false);
      return;
    }
    // If we get here, open was set to true and there is no existing timeout id so set one
    if (open && !resetTime && !timeoutId) {
      runSetTimeout();
    }
  }, [open, ms, autoClose, closeCb, resetTime, timeoutId, setTimeoutId]);

  return (
    <>
      <Aside open={open} leftPixels={leftPixelDesktop}>
        {content}
      </Aside>
      <SnackbarContext.Provider
        value={{
          setContent,
          setOpen,
          setMS,
          setAutoClose,
          setCloseCb,
          isOpen: open,
          setResetTime,
        }}
      >
        {props.children}
      </SnackbarContext.Provider>
    </>
  );
}

export function useSnackbar() {
  const {
    setContent,
    setOpen,
    setMS,
    setAutoClose,
    isOpen,
    setCloseCb,
    setResetTime,
  } = useContext(SnackbarContext);

  return {
    isOpen,
    openSnackbar: (content, opt = {}) => {
      const { skipClose = false, autoCloseMS = 4000, closeCb } = opt;
      if (skipClose) setAutoClose(false);
      if (closeCb) setCloseCb(() => closeCb);
      setMS(autoCloseMS);
      setContent(content);
      setOpen(true);
    },
    updateSnackbar: (content, opt = {}) => {
      // order does matter here btw in how the useEffect runs up above. Make sure setResetTime stays above setOpen
      if (opt.closeCb) {
        setCloseCb(() => opt.closeCb);
      }
      setResetTime(true);
      if (!isOpen) setOpen(true);
      setContent(content);
      setMS(4000);
      setAutoClose(true);
    },
  };
}

const Aside = styled.aside`
  transition: transform 400ms;
  width: 327px;
  height: 48px;
  padding: 1rem;
  text-align: left;
  color: rgba(255, 255, 255, 0.87);
  position: fixed;
  bottom: 1rem;
  background: ${({ theme }) => theme.colors.black};
  border-radius: 4px;
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2),
    0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12);
  z-index: 100;
  left: 50%;
  transform: translateX(-50%);
  bottom: 78px;

  ${({ open }) => !open && 'display: none;'}

  @media(min-width: 1024px) {
    left: ${({ leftPixels }) => {
      return leftPixels ? `${leftPixels}px` : '50%';
    }};
    transform: ${({ leftPixels }) => {
      return leftPixels ? 'initial' : 'translateX(-50%)';
    }};
  }
`;
