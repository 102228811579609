import React, { useContext, useEffect, useState, useCallback } from 'react';
import { Match } from '@reach/router';
import { useQuery, useMutation, useLazyQuery } from '@apollo/react-hooks';
import { GET_APPLICATIONS, GET_APPLICATION_DRAFT } from '../Graphql/queries';
import _get from 'lodash/get';
import { SAVE_APPLICATION_DRAFT } from '../Graphql/mutations';
import { GET_USER, GET_COHORT } from '../Graphql/queries';

// I made two contexts here so that we wouldn't have to
// rewrite anything that was using the original ApplicationContext
export const ApplicationContext = React.createContext(null);
export const ApplicationContextDetails = React.createContext(null);

// I'm not sure if this will actually work right...
// It might need to go inside the component or in a useEffect
// to make sure it is updated correctly?
// also need to think through how we want to handle a case with
// a newCohortId URL param but a "draft" application with a different cohortId

// it looks like somewhere our logic is preferring the newCohortId in the
// salesforce applicationDraft over a new URL in the params, but I'm not sure where.
// so, currently, if they change the params, it won't change it in salesforce?
const searchParams = new URLSearchParams(window.location.search);
const cohortId = searchParams.get('newCohortId');
const dc = searchParams.get('dc');

const emptyApplication = {
  application: { approachDashboard: true, cohortId, dc },
};

export default function ApplicationProvider(props) {
  const [id, setId] = useState(null);
  const [draft, setDraft] = useState(null);
  const { data, loading } = useQuery(GET_APPLICATIONS, {
    fetchPolicy: 'cache-and-network',
  });
  const applicationDraft = useQuery(GET_APPLICATION_DRAFT, {
    // this query breaks if there's no sfId in context
    skip: !_get(data, 'user.sfId'),
  });
  const [
    saveDraft,
    { loading: saveDraftLoading, called: saveDraftCalled },
  ] = useMutation(SAVE_APPLICATION_DRAFT, {
    refetchQueries: applicationDraft,
    awaitRefetchQueries: true,
  });
  // getting user by cache exists to make sure temp application is not created for a user
  const { data: userData } = useQuery(GET_USER, {
    fetchPolicy: 'cache-only',
  });

  // This should only be called if it's a temp application
  // Used to display cohort details and set if cohort is remote or not
  const [
    getCohort,
    { loading: getCohortLoading, data: cohortData, called: getCohortCalled },
  ] = useLazyQuery(GET_COHORT);

  const setLocalValues = useCallback(
    values => {
      // Zach - related to the problem described below, try console logging here. Change the newCohortId in the url and reload the page. Sometimes Dashboard will see the new id, sometimes not. It's a timing thing I believe.
      setDraft(values);
      saveDraft({ variables: { draft: JSON.stringify(values) } });
    },
    [saveDraft, setDraft],
  );

  // instead of having to JSON.parse the draft each time it's used
  // any time applicationDraft changes, update the draft on state
  // as the parsed object. Maybe not necessary?
  useEffect(() => {
    const string = _get(applicationDraft, 'data.applicationDraft') || '{}';
    setDraft(JSON.parse(string));
  }, [applicationDraft]);

  // Retrieve cohort information if need be
  useEffect(() => {
    // This is optimized especially for new applications with new users. The retrieval process occurs while they're creating an account
    if (
      !loading &&
      !getCohortCalled &&
      !(_get(data, 'user.applications') || []).length &&
      (cohortId || _get(draft, 'application.cohortId'))
    ) {
      console.log('getting cohort ');
      getCohort({
        variables: { id: cohortId || _get(draft, 'application.cohortId') },
      });
    }
  }, [loading, getCohortCalled, data, draft, getCohort]);

  // if they already have an applicationDraft saved in salesforce
  // but it doesn't have a cohortId,
  // and they have the ?newCohortId query param now
  // update their applicationDraft in salesforce with the new cohort

  useEffect(() => {
    let draft;
    if (_get(applicationDraft, 'data.applicationDraft')) {
      draft = JSON.parse(_get(applicationDraft, 'data.applicationDraft'));
    }

    if (
      draft &&
      !_get(draft, 'application.cohortId', '') &&
      emptyApplication.application.cohortId &&
      !applicationDraft.loading &&
      !saveDraftCalled &&
      (userData && userData.user) &&
      !loading &&
      !newIdMatchesExisting(
        _get(data, 'user.applications'),
        emptyApplication.application.cohortId,
      )
    ) {
      setLocalValues(emptyApplication);
    }
  }, [
    applicationDraft,
    setLocalValues,
    saveDraftCalled,
    userData,
    data,
    loading,
  ]);

  // if they don't have an applicationDraft saved in salesforce
  // just create an empty one. This might not be necessary
  // but I haven't had time to test it without this.

  // Zach - This wont technically refresh the values on the dashboard. Meaning if there is an existing application id in the application draft and then the user visits student portal with a new newCohortId, it will update in salesforce but doesnt' update providers value immediately.
  useEffect(() => {
    if (
      !applicationDraft.loading &&
      !loading &&
      !_get(applicationDraft, 'data.applicationDraft') &&
      (userData && userData.user) &&
      !saveDraftCalled &&
      !newIdMatchesExisting(
        _get(data, 'user.applications'),
        emptyApplication.application.cohortId,
      )
    ) {
      setLocalValues(emptyApplication);
    }
  }, [
    applicationDraft,
    saveDraft,
    setLocalValues,
    loading,
    data,
    userData,
    saveDraftCalled,
  ]);

  // if we get any applications from salesforce,
  // use the first one from there as the id instead
  useEffect(() => {
    let existingId = _get(data, 'user.applications[0].id');
    if (existingId) {
      setId(existingId);
    }
  }, [data]);

  return (
    <Match path="/:section">
      {locationProps => (
        <ScrollContainer path={locationProps.location.pathname}>
          <ApplicationContext.Provider value={id}>
            <ApplicationContextDetails.Provider
              // maybe setId can be used to enable
              // accessing multiple applications one day
              value={{
                user: _get(userData, 'user'),
                loading: loading || applicationDraft.loading,
                setId,
                saveDraftLoading: saveDraftLoading,
                localValues: draft,
                setLocalValues,
                cohort: {
                  loading: getCohortLoading,
                  data: _get(cohortData, 'cohort', null),
                },
              }}
            >
              {props.children}
            </ApplicationContextDetails.Provider>
          </ApplicationContext.Provider>
        </ScrollContainer>
      )}
    </Match>
  );
}

const ScrollContainer = props => {
  useEffect(() => {
    // eslint-disable-next-line no-undef
    // document.body.scrollTop = 0;
    document.getElementById('root').scrollIntoView({ smooth: true });
  }, [props.path]);
  return props.children;
};

export const useApplicationId = () => {
  // in the future this might be
  // expanded to hold more than just
  // the application id
  const applicationId = useContext(ApplicationContext);
  return applicationId;
};

const newIdMatchesExisting = (applications, newCohortId) => {
  let isMatch = false;
  if (Array.isArray(applications))
    applications.forEach(app => {
      if (app.cohortId === newCohortId) isMatch = true;
    });
  return isMatch;
};
