import React, { useContext } from 'react';
import styled from 'styled-components';
import { StripeProvider } from 'react-stripe-elements';
import StyledSection from '../Styles/StyledSection';
import Payments from './Payments';
import FinanceToDo from './FinanceToDo';
import FinancingOptions from './FinancingOptions';
import DashboardNavigation from '../Shared/DashboardNavigation';
import AcknowledgementsAndPaymentMethod from './AcknowledgementsAndPaymentMethod';
import { MediaQueryContext } from '../Shared/MediaQueryProvider';
import { useApplicationId } from '../Shared/ApplicationProvider';

export default function FinanceCenter() {
  const isDesktop = useContext(MediaQueryContext);
  const id = useApplicationId();
  return (
    <StripeProvider apiKey={process.env.REACT_APP_STRIPE_PUBLIC_KEY}>
      <StyledSection variant="dashboard">
        <DashboardNavigation section="Finance Center" id={id} />
        <Row>
          <FinanceToDo id={id} />

          {isDesktop && <FinancingOptions />}
        </Row>
        <Row>
          <AcknowledgementsAndPaymentMethod id={id} />
          <Payments id={id} />
          {!isDesktop && <FinancingOptions />}
        </Row>
      </StyledSection>
    </StripeProvider>
  );
}

export const Row = styled.div`
  @media (min-width: 1024px) {
    display: flex;
    align-items: flex-start;
  }
`;
