import styled from 'styled-components';
import StyledRadio from './StyledRadio';
import checkboxUnchecked from '../img/checkbox-unchecked.svg';
// this is not the correct checked icon
import checkboxChecked from '../img/checkbox-checked.svg';

export default styled(StyledRadio)`
  background-image: url(${checkboxUnchecked});
  &:checked {
    background-image: url(${checkboxChecked});
  }
`;
