import React from 'react';
import styled from 'styled-components';
import Card from '../Shared/Card';
import CardSection from '../Styles/CardSection';
import StyledP from '../Styles/StyledP';
import { Link } from '@reach/router';

export default function FirstDay() {
  return (
    <Card
      span={1 / 2}
      header="your first day of class"
      defaultExpanded
      id="first-day"
    >
      <CardSection>
        <ParagraphTag>
          On the first day of class, log into Discord at 9am MST in your
          specific class channel that you will be added to prior to day 1. If
          you are in a different time zone be sure that you accommodate for the
          time difference. Once you are in Discord, your instructor will give
          you a link to your first GoToMeeting live lecture where you can meet
          your classmates and go over important things to know for your class.
        </ParagraphTag>
        <ParagraphTag>
          If you have questions about your first day or what to expect, feel
          free to reach out to your admissions counselor. This information can
          be found in your{' '}
          <StyledLink to="../onboarding"> student directory</StyledLink>.
        </ParagraphTag>
      </CardSection>
    </Card>
  );
}

const ParagraphTag = styled(StyledP)`
  line-height: 1.25rem;
  letter-spacing: 0.0125rem;
  font-size: 0.895rem;
  margin: 1rem 0;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: ${({ theme }) => theme.palette.secondary.main};
`;
