import React from 'react';

export default function FilledPerson() {

    return (
        <svg width="16px" height="21px" viewBox="0 0 16 21" version="1.1" xmlns="http://www.w3.org/2000/svg" >
            <title>Group</title>
            <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g id="HB_Contact-Info_Desktop_1" transform="translate(-60.000000, -119.000000)" fill="#FB3530" fill-rule="nonzero">
                    <g id="Group-4" transform="translate(40.000000, 103.000000)">
                        <g id="Group-2" transform="translate(16.000000, 13.000000)">
                            <g id="Group" transform="translate(4.000000, 3.000000)">
                                <path d="M11.535,13.551 L11.026,13.379 L8,15.732 L4.974,13.378 L4.465,13.55 C1.669,14.492 0,16.529 0,19 L0,21 L16,21 L16,19 C16,16.529 14.33,14.492 11.535,13.551 Z" id="Path"></path>
                                <path d="M1,8 L1,10 L4,10 C4.912,11.226 6.359,12 8,12 C9.641,12 11.088,11.226 12,10 L15,10 L15,8 C15,-2 1,-2 1,8 Z M8,10 C6.721,10 5.635,9.192 5.204,8.062 C5.25,8.033 5.302,8.01 5.347,7.98 C6.652,7.102 7.713,5.708 8,4 C8.285,5.697 9.339,7.096 10.653,7.981 C10.698,8.011 10.75,8.034 10.796,8.063 C10.366,9.192 9.279,10 8,10 Z" id="Shape"></path>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>);

}