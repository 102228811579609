import React from 'react';
import styled from 'styled-components';
import CardSection from '../Styles/CardSection';
import StyledA from '../Styles/StyledA';

export default function DallasTransportation(props) {
  return (
    <CardSection>
      <PHeading>Dallas Area Rapid Transit (DART)</PHeading>
      <Paragraph>
        To purchase a pass with DART or for more information on transportation
        options visit{' '}
        <ATag as="a" href="https://www.dart.org">
          www.dart.org
        </ATag>
        .
      </Paragraph>
    </CardSection>
  );
}

const PHeading = styled.h2`
  color: ${({ theme }) => theme.palette.secondary.main};
  font-weight: bold;
  margin-bottom: 0.5rem;
`;

const Paragraph = styled.p`
  margin-bottom: 1rem;
  font-size: 0.875rem;
  letter-spacing: 0.00625rem;
  line-height: 1.25rem;
`;

export const ATag = styled(StyledA)`
  color: ${({ theme }) => theme.palette.secondary.main};
  text-decoration: none;
  font-weight: bold;
`;
