import styled from 'styled-components';

const StyledA = styled.a`
  color: ${props => props.theme.palette.primary.dark};
`;

export const Dmlink = styled.a`
  color: ${({theme}) => theme.palette.primary.dark};
  text-decoration: none;
`;

export default StyledA;
