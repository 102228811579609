import React from 'react';
import Loading from '../Shared/Loading';
import Contact from './Contact';
import Education from './Education';
import Experience from './Experience';
import Goals from './Goals';
import PersonalInfoComplete from './PersonalInfoComplete';
import { RouterDiv } from '../Styles/StyledDiv';
import { useCurrentApplication } from '../utils/hooks';
import { Location } from '@reach/router';
import _get from 'lodash/get';

export default function PersonalInfoContainer() {
  const { loading, error, data } = useCurrentApplication();

  const personalComplete = _get(
    data,
    'application.complete.personalInfo',
    false,
  );

  if (error) return 'Error';
  if (loading) return <Loading />;
  if (!data || !data.application) return <Loading />;

  return (
    <Location>
      {({ location }) => {
        if (
          personalComplete &&
          new RegExp(
            '/personal-info/((contact)|(education)|(experience)|(goals))',
          ).test(location.pathname)
        ) {
          return <PersonalInfoComplete />;
        }
        return (
          <RouterDiv primary={false}>
            <Contact path="contact" />
            <Education path="education" />
            <Experience path="experience" />
            <Goals path="goals" />
            <PersonalInfoComplete path="submitted" />
          </RouterDiv>
        );
      }}
    </Location>
  );
}
