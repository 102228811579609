import React from 'react';
// import { genRandId } from '../utils/jsFunctions';

export default function PageWithArrows(props) {
  //   const uniqueId = `${genRandId(0, 1000, 'path')}`;
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 18 22"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      //   xmlns:xlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <title>files-coding-1</title>
      <desc>Created with Sketch.</desc>
      <g
        id="svgs"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        style={{ color: props.active ? 'rgba(14,182,254,1)' : 'inherit' }}
      >
        <g id="not-yet-uploaded" transform="translate(-56.000000, -232.000000)">
          <g id="files-coding-1" transform="translate(53.000000, 231.000000)">
            <g id="Frame_-_24px">
              <rect id="Rectangle" x="0" y="0" width="24" height="24" />
            </g>
            <g
              id="Line_Icons"
              transform="translate(3.000000, 1.000000)"
              fill="currentcolor"
              fillRule="nonzero"
            >
              <g id="Group">
                <polygon
                  id="Path"
                  points="7.293 9.299 3.586 13.006 7.293 16.714 8.707 15.299 6.414 13.006 8.707 10.714"
                />
                <polygon
                  id="Path"
                  points="9.293 10.714 11.586 13.006 9.293 15.299 10.707 16.714 14.414 13.006 10.707 9.299"
                />
                <path
                  d="M13.414,0.003 L2,0.003 C0.897,0.003 0,0.899 0,2.003 L0,20.003 C0,21.106 0.897,22.003 2,22.003 L16,22.003 C17.103,22.003 18,21.106 18,20.003 L18,4.59 L13.414,0.003 Z M2,20.003 L2,2.003 L12,2.003 L12,6.003 L16,6.003 L16.001,20.003 L2,20.003 Z"
                  id="Shape"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
