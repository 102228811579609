import React, { useContext } from 'react';
import StyledSection from '../Styles/StyledSection';
import IntroMessage from './IntroMessage';
import CohortWidget from './CohortWidget';
import ActivityList from './ActivityList/ActivityList';
import { useCurrentApplication } from '../utils/hooks';
import { MediaQueryContext } from '../Shared/MediaQueryProvider';
import DashboardNavigation from '../Shared/DashboardNavigation';
import { Row } from '../FinanceCenter/FinanceCenter';
import _get from 'lodash/get';
import WithdrawnApplication from './WithdrawnApplication';
import Card from '../Shared/Card';
import { ReactComponent as YellowAlert } from '../img/YellowAlert.svg';
import StyledP from '../Styles/StyledP';

const oldPortal = {
  development: 'http://localhost:1337/sfportal',
  production: 'https://devmountain.com/sfportal',
};

export default function DashboardContent(props) {
  const { data, cohort } = useCurrentApplication({
    fetchPolicy: 'cache-first',
  });

  const isDesktop = useContext(MediaQueryContext);

  const status = _get(data, 'application.Status__c');
  const approachDashboard = _get(data, 'application.approachDashboard');

  // Need to be confident its an old app meaning we have to wait for data
  const oldPortalApp = !approachDashboard && data && !!Object.keys(data).length;

  const setSelectedCohort = () => {
    return {
      loading: cohort.loading,
      cohort: cohort.data || {},
    };
  };

  return (
    <StyledSection variant="dashboard">
      <DashboardNavigation section="Dashboard" />
      <Row>
        {isDesktop && (
          <>
            <CohortWidget span={1 / 2} {...setSelectedCohort()} />
            <IntroMessage />
          </>
        )}
        {!isDesktop && (
          <>
            <IntroMessage />
            <CohortWidget {...setSelectedCohort()} />
          </>
        )}
      </Row>
      {approachDashboard && (
        <>
          {status === 'Withdrawn' ? <WithdrawnApplication /> : <ActivityList />}
        </>
      )}
      {oldPortalApp && <OldPortalApplication />}
    </StyledSection>
  );
}

const OldPortalApplication = ({ approachDashboard }) => {
  console.warn(
    'Reached DashboardContent with a non-approach dashboard application',
  );
  return (
    <Card icon={YellowAlert} header="Alert" collapsable={false} span={1 / 2}>
      <StyledP style={{ padding: '1.5rem' }}>
        This application was created in an older version of the Approach
        Dashboard. Click{' '}
        <a href={oldPortal[process.env.NODE_ENV] + '?force_old=true'}>here</a>{' '}
        to switch to that version. Please note, you may have to log in again.
      </StyledP>
    </Card>
  );
};
