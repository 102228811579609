import React, { useContext } from 'react';
import styled from 'styled-components';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import _get from 'lodash/get';
import StyledH2 from '../Styles/StyledH2';
import Checkbox from '../Shared/Checkbox';
import Input from '../Shared/Input';
import StyledSection from '../Styles/StyledSection';
import StyledLegend from '../Styles/StyledLegend';
import { useCompletionEffect, useCurrentApplication } from '../utils/hooks';
import Select from '../Shared/Select';
import { ApplicationContextDetails } from '../Shared/ApplicationProvider';
import SaveAndContinue from '../Shared/SaveAndContinue';
import { showErrors } from '../utils/jsFunctions';
import ErrorScroll from '../Shared/ErrorScroll';
import { useValidation } from '../Shared/ValidationProvider';
import { SectionContainer, Content } from './Contact';
import { useSnackbar } from '../Shared/SnackbarProvider';

const validationSchema = Yup.object().shape({
  highestEducation: Yup.string()
    .oneOf(
      [
        'High School',
        'Some College',
        'Associates',
        'Bachelors',
        'Masters',
        'PHD',
      ],
      'Select a valid level of education',
    )
    .required('Select a valid level of education'),
  experience: Yup.object().shape({
    python: Yup.bool().required(),
    javascript: Yup.bool().required(),
    sql: Yup.bool().required(),
    htmlCss: Yup.bool().required(),
    react: Yup.bool().required(),
    flask: Yup.bool().required(),
    computerScience: Yup.bool().required(),
  }),
  hoursSpent: Yup.string()
    .trim()
    .matches(/^[0-9]+$/, 'Enter a valid number of hours.')
    .required('Enter a valid number of hours.'),
});

const setInitialValues = data => {
  const application = _get(data, 'application') || {};
  const experience = _get(application, 'experience') || {};
  return {
    highestEducation: application.highestEducation || '',
    fieldOfStudy: application.fieldOfStudy || '',
    experience: {
      python: experience.python || false,
      javascript: experience.javascript || false,
      sql: experience.sql || false,
      htmlCss: experience.htmlCss || false,
      react: experience.react || false,
      flask: experience.flask || false,
      computerScience: experience.computerScience || false,
    },
    hoursSpent: application.hoursSpent || '',
    hoursSpentType: application.hoursSpentType || '',
  };
};

export default function Education(props) {
  const errorContext = useValidation();
  const { localValues, setLocalValues } = useContext(ApplicationContextDetails);
  const { openSnackbar } = useSnackbar();

  // this does need to get the whole application
  // because of the useCompletionEffect
  const { data } = useCurrentApplication();

  useCompletionEffect(data);

  return (
    <Formik
      // enableReinitialize
      initialValues={setInitialValues(data)}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {
        openSnackbar('Education saved', {
          closeCb: () => {
            setSubmitting(false);
            props.navigate('../experience');
          },
        });
        setLocalValues({
          ...localValues,
          application: { ...localValues.application, ...values },
        });
      }}
    >
      {formik => {
        const hasErrors = showErrors('education', formik);
        if (hasErrors && !errorContext.errors.education) {
          errorContext.setErrors('education', true);
        }
        if (!hasErrors && errorContext.errors.education) {
          errorContext.setErrors('education', false);
        }

        return (
          <Form>
            <StyledSection>
              <SectionContainer>
                <StyledH2>Education</StyledH2>
                <Content>
                  <Select
                    name="highestEducation"
                    invalid={formik.errors.highestEducation && formik.touched.highestEducation}
                    // the value prop allows for some styling magic
                    // to allow a "placeholder" style on a select
                    value={formik.values.highestEducation}
                  >
                    <option value="" hidden disabled>
                      Highest Level of Education
                    </option>
                    <option value="High School">High School</option>
                    <option value="Some College">Some College</option>
                    <option value="Associates">Associate&apos;s</option>
                    <option value="Bachelors">Bachelor&apos;s</option>
                    <option value="Masters">Master&apos;s</option>
                    <option value="PHD">Doctorate's</option>
                  </Select>

                  <Input name="fieldOfStudy" placeholder="Field of Study" />
                  <StyledFieldset>
                    <StyledLegend>
                      Select any that you have experience with:
                    </StyledLegend>
                    <Checkbox
                      name="experience.python"
                      id="experience.python"
                      label="Python"
                    />
                    <Checkbox
                      name="experience.javascript"
                      id="experience.javascript"
                      label="Javascript"
                    />
                    <Checkbox
                      name="experience.sql"
                      id="experience.sql"
                      label="SQL"
                    />
                    <Checkbox
                      name="experience.htmlCss"
                      id="experience.htmlCss"
                      label="HTML &amp; CSS"
                    />
                    <Checkbox
                      name="experience.react"
                      id="experience.react"
                      label="React"
                    />
                    <Checkbox
                      name="experience.flask"
                      id="experience.flask"
                      label="Flask"
                    />
                    <Checkbox
                      name="experience.computerScience"
                      id="experience.computerScience"
                      label="Computer Science"
                    />
                  </StyledFieldset>
                  <StyledFieldset>
                    <StyledLegend style={{ paddingTop: '1rem' }}>
                      Approximately how many hours have you spent learning to
                      code?
                    </StyledLegend>
                    {/* This styling works on Chrome but it seems pretty fragile. */}
                    {/* It should fail relatively gracefully and just not display the hours placeholder */}
                    <Input
                      placeholder="6"
                      name="hoursSpent"
                      endAdornment="hours"
                    />
                  </StyledFieldset>
                  {+formik.values.hoursSpent > 5 && (
                    <StyledFieldset>
                      <StyledLegend>
                        List all the resources — classes, tutorials, friends, or
                        more — you used in your {formik.values.hoursSpent} hour
                        {+formik.values.hoursSpent !== 1 && 's'} learning to
                        code.
                      </StyledLegend>
                      <Input
                        name="hoursSpentType"
                        component="textarea"
                        style={{
                          lineHeight: '1.4375rem',
                          height: 'unset',
                        }}
                        rows={5}
                      />
                    </StyledFieldset>
                  )}
                  <SaveAndContinue disabled={formik.isSubmitting} />
                </Content>
              </SectionContainer>
            </StyledSection>
            <ErrorScroll />
          </Form>
        );
      }}
    </Formik>
  );
}

const StyledFieldset = styled.fieldset`
  display: flex;
  flex-direction: column;
`;

const HoursInput = styled(Input)`

`

const StyledDiv = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

const StyledSpan = styled.span`
  /* this is here because the Input has an
ErrorSpan below it that throws off the 
flex align-items: center on the parent */
  color: ${({ theme }) => theme.palette.text.input};
  top: 1.25rem;
  left: 4.5rem;
  position: absolute;
`;
