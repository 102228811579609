import React from 'react';

export default function PlusIcon(props) {
  return (
    <svg
      width="14px"
      height="14px"
      viewBox="0 0 14 14"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      //   xmlns:xlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      {/* <!-- Generator: Sketch 54.1 (76490) - https://sketchapp.com --> */}
      <title>icon / content / add / selected / 24px</title>
      <desc>Created with Sketch.</desc>
      <defs>
        <polygon
          id="path-1-plus"
          points="19 13 13 13 13 19 11 19 11 13 5 13 5 11 11 11 11 5 13 5 13 11 19 11"
        />
      </defs>
      <g id="svgs" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="SVG'S" transform="translate(-222.000000, -235.000000)">
          <g
            id="icon/content/add_24px"
            transform="translate(217.000000, 230.000000)"
          >
            <mask id="mask-2-plus" fill="white">
              <use xlinkHref="#path-1-plus" />
            </mask>
            <g fillRule="nonzero" />
            <g
              id="✱-/-Color-/-Icons-/-Blue-dm/-Active"
              mask="url(#mask-2-plus)"
              fill="currentcolor"
              fillRule="evenodd"
            >
              <rect id="Rectangle-13" x="0" y="0" width="24" height="24" />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
