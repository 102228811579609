import React from 'react';
import styled from 'styled-components';

export default function CheckPaymentInfo(props) {
  return (
    <StyledDiv>
      <StyledP>Please send a check or money order to:</StyledP>
      <StyledP>
        <StyledEm>DEVMOUNTAIN LLC</StyledEm>
        <br />
        Attn: {props.cohort.Location_City__c} {props.cohort.programName} (
        {props.cohort.Date_Start__c}
        )
        <br />
        P.O. Box 841739
        <br />
        Dallas, TX 75284-1739
      </StyledP>
    </StyledDiv>
  );
}

const StyledEm = styled.em`
  font-weight: bold;
`;

const StyledDiv = styled.div`
  font-size: 0.875rem;
  letter-spacing: 0.1px;
  line-height: 1.625rem;
`;

const StyledP = styled.p`
  margin: 1.5rem 0;
`;
