import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from '@reach/router';
import LockedIcon from '../../img/LockedIcon';
import ProgressBar from '../../Shared/ProgressBar';
import TaskCompleteIcon from '../../img/TaskCompleteIcon';
import { MediaQueryContext } from '../../Shared/MediaQueryProvider';

export default function ProgressItem(props) {
  const isDesktop = useContext(MediaQueryContext);

  let Icon;

  if (props.complete) {
    Icon = TaskCompleteIcon;
  } else if (props.unlocked) {
    Icon = props.icon;
  } else {
    Icon = LockedIcon;
  }

  const Container =
    props.to && props.unlocked /* && !props.complete */ ? Link : ContainerDiv;

  return (
    <Container to={props.to} style={{ textDecoration: 'none', color: 'unset' }}>
      <StyledLi onClick={props.onClick}>
        <StyledDiv>
          <Icon />
          <StyledSpan unlocked={props.unlocked}>
            {props.description}{' '}
            {props.unlocked &&
              !props.complete &&
              (isDesktop ? props.children || props.action : props.action)}
          </StyledSpan>
        </StyledDiv>
        <ContentSpan>
          {!props.children &&
            props.unlocked &&
            !props.complete &&
            props.progress !== undefined && (
              <ProgressBar percentage={props.progress} />
            )}
          {!props.children && props.complete && (
            <StyledEm>
              Completed <span>{props.complete}</span>
            </StyledEm>
          )}
          {!isDesktop && props.children}
        </ContentSpan>
      </StyledLi>
    </Container>
  );
}

const StyledEm = styled.em`
  color: ${({ theme }) => theme.colors.black};
  font-size: 0.6875rem;
  font-weight: bold;
  font-family: ${({ theme }) => theme.typography.primary};
  text-transform: uppercase;
`;
const ContainerDiv = styled.div``;
const StyledLi = styled.li`
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 0.8125rem 0;
  font-size: 0.9375rem;
  &::after {
    content: '';
    position: absolute;
    top: 41px;
    width: 1px;
    height: 100%;
    left: 20px;
    background: ${({ theme }) => theme.colors.lightestgray};
  }
  &:last-child::after {
    display: none;
  }
  @media (min-width: 1024px) {
    /* flex-direction: row; */
    /* justify-content: space-between; */
    margin-right: 3rem;
  }
`;

const StyledDiv = styled.div`
  display: flex;
  align-items: center;
`;

const StyledSpan = styled.span`
  flex-grow: 1;
  padding-left: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &:hover,
  &:active {
    color: ${({ theme, unlocked }) =>
      unlocked ? theme.palette.primary.dark : 'inherit'};
  }
`;

const ContentSpan = styled.span`
  height: 2.25rem;
  margin-left: 3.5626rem;
`;

ProgressItem.propTypes = {
  complete: PropTypes.string,
  unlocked: PropTypes.bool,
  icon: PropTypes.func,
  description: PropTypes.string,
  action: PropTypes.element,
  progress: PropTypes.number,
  to: PropTypes.string,
};

ProgressItem.defaultProps = {
  complete: '',
  unlocked: false,
  icon: LockedIcon,
  description: '',
  action: null,
  progress: undefined,
  to: '',
};
