import React from 'react';
import Card from '../Shared/Card';
import CardSection from '../Styles/CardSection';
import { ReactComponent as YellowAlert } from '../img/YellowAlert.svg';
import styled from 'styled-components';

export default function WithdrawnApplication() {
  return (
    <Card
      header="Application Withdrawn"
      collapsable={false}
      icon={YellowAlert}
      span={2 / 3}
    >
      <CardSection>
        <StyledP>
          This application has been withdrawn. Please contact admissions at{' '}
          <a href="mailto:admissions@hackbrightacademy.com">
            admissions@hackbrightacademy.com
          </a>{' '}
          or{' '}
          <a href="tel:+14158620595" style={{ textDecoration: 'none' }}>
            1-415-862-0595
          </a>
          .
        </StyledP>
      </CardSection>
    </Card>
  );
}

const StyledP = styled.p`
  a {
    color: ${({ theme }) => theme.palette.secondary.main};
  }
`;
