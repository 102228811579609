import React from 'react';

export default function DollarIcon(props) {
  return (
    <svg
      width="34px"
      height="34px"
      viewBox="0 0 34 34"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      //   xmlns:xlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <title>Group</title>
      <desc>Created with Sketch.</desc>
      <defs>
        <path
          d="M11.1697727,9.82651515 C9.12333333,9.29462121 8.46522727,8.74469697 8.46522727,7.88825758 C8.46522727,6.90560606 9.37575758,6.22045455 10.8993182,6.22045455 C12.5040152,6.22045455 13.0990152,6.98674242 13.1531061,8.11363636 L15.1454545,8.11363636 C15.0823485,6.5630303 14.1357576,5.13863636 12.2515909,4.67886364 L12.2515909,2.70454545 L9.54704545,2.70454545 L9.54704545,4.65181818 C7.79810606,5.03045455 6.39174242,6.16636364 6.39174242,7.90628788 C6.39174242,9.98878788 8.11363636,11.0255303 10.6288636,11.6295455 C12.8826515,12.1704545 13.3334091,12.9637879 13.3334091,13.802197 C13.3334091,14.4242424 12.8916667,15.4159091 10.8993182,15.4159091 C9.04219697,15.4159091 8.3119697,14.5865152 8.21280303,13.5227273 L6.2294697,13.5227273 C6.33765152,15.4970455 7.81613636,16.6059091 9.54704545,16.9755303 L9.54704545,18.9318182 L12.2515909,18.9318182 L12.2515909,16.9935606 C14.0095455,16.66 15.4068939,15.6412879 15.4068939,13.7931818 C15.4068939,11.2328788 13.2162121,10.3584091 11.1697727,9.82651515 Z"
          id="DollarIcon-path-1"
        />
      </defs>
      <g
        id="DollarIcon-svgs-jonathan"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="DollarIcon-Artboard"
          transform="translate(-285.000000, -93.000000)"
        >
          <g id="DollarIcon-Group" transform="translate(285.000000, 93.000000)">
            <circle
              id="DollarIcon-Oval"
              fill="#004581"
              cx="17"
              cy="17"
              r="17"
            />
            <g
              id="DollarIcon-icon/editor/attach_money_24px"
              transform="translate(6.181818, 6.181818)"
            >
              <mask id="DollarIcon-mask-2" fill="white">
                <use xlinkHref="#DollarIcon-path-1" />
              </mask>
              <g fillRule="nonzero" />
              <g
                id="DollarIcon-✱-/-Color-/-Icons-/-White-/-Active"
                mask="url(#DollarIcon-mask-2)"
                fill="#FFFFFF"
                fillRule="evenodd"
              >
                <rect
                  id="DollarIcon-Rectangle-13"
                  x="0"
                  y="0"
                  width="22.0128205"
                  height="22.0128205"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
