import { useEffect, useContext } from 'react';
import { calculateCompletion } from './jsFunctions';
import {
  useApplicationId,
  ApplicationContextDetails,
} from '../Shared/ApplicationProvider';
import { GET_APPLICATION } from '../Graphql/queries';
import { useQuery } from '@apollo/react-hooks';
import { useValidation } from '../Shared/ValidationProvider';
import _get from 'lodash/get';

export const useCompletionEffect = data => {
  const validation = useValidation();
  const setCompletion = validation.setCompletion;

  useEffect(() => {
    if (data && data.application) {
      calculateCompletion(data).then(result => {
        setCompletion(result);
      });
    }
  }, [data, setCompletion]);
};

export const useCurrentApplication = (config = {}) => {
  const id = useApplicationId();

  const local = useContext(ApplicationContextDetails);

  // moved skip down below to avoid getting an application without an id regardless of what config says
  // This will get an application if there is an id from useApplicationId
  const remote = useQuery(GET_APPLICATION, {
    variables: { id },
    ...config,
    skip: !id,
  });

  if (local.loading || _get(local, 'localValues.application.cohortId', '')) {
    return {
      loading: local.loading,
      data: local.localValues,
      cohort: local.cohort,
      user: local.user,
    };
  }

  if (id) {
    let cohortObj;
    if (remote.loading) cohortObj = { loading: true, data: null };
    else if (_get(remote, 'data.application.cohort', null))
      cohortObj = { data: remote.data.application.cohort, loading: false };
    else cohortObj = { data: null, loading: false };
    return { ...remote, cohort: cohortObj, user: local.user };
  }

  return {
    loading: local.loading,
    data: local.localValues,
    cohort: local.cohort,
    user: local.user,
  };
};
