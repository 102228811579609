import React from 'react';

export default function MinusIcon(props) {
  return (
    <svg
      width="14px"
      height="2px"
      viewBox="0 0 14 2"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      // xmlns:xlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      {/* <!-- Generator: Sketch 54.1 (76490) - https://sketchapp.com --> */}
      <title>icon / content / remove / selected / 24px</title>
      <desc>Created with Sketch.</desc>
      <defs>
        <polygon id="path-1" points="19 13 5 13 5 11 19 11" />
      </defs>
      <g id="svgs" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="SVG'S" transform="translate(-222.000000, -271.000000)">
          <g
            id="icon/content/remove_24px"
            transform="translate(217.000000, 260.000000)"
          >
            <mask id="mask-2" fill="white">
              <use xlinkHref="#path-1" />
            </mask>
            <g fillRule="nonzero" />
            <g
              id="✱-/-Color-/-Icons-/-Blue-dm/-Active"
              mask="url(#mask-2)"
              fill="currentcolor"
              fillRule="evenodd"
            >
              <rect id="Rectangle-13" x="0" y="0" width="24" height="24" />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
