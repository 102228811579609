import React from 'react';
import styled from 'styled-components';
import CardSection from '../Styles/CardSection';

export default function SFCampusInfo(props) {
  return (
    <CardSection>
      <PHeading>Location</PHeading>
      <Paragraph
        as="a"
        target="_blank"
        href="https://goo.gl/maps/HsQoDHsbYQUHjgKj9"
      >
        <Address>4100 North Chapel Ridge Road,</Address>
        <br />
        <StateAndZip>Lehi, UT 84043</StateAndZip>
      </Paragraph>
      <PHeading>Parking</PHeading>
      <Paragraph>
        Parking is open at the Lehi campus besides the numbered spaces or
        &quot;visitor&quot; delegated spaces in front.
      </Paragraph>
      <PHeading>Access</PHeading>
      <Paragraph>
        The Lehi campus is available to students 24 hours a day. The campus is
        open weekdays from
        <BoldSpan> 9am - 5pm </BoldSpan>. Access at any other time outside of
        the designated hours requires a keycard. Student key cards are issued
        the first day of class.
      </Paragraph>
    </CardSection>
  );
}

const Paragraph = styled.p`
  margin-bottom: 1rem;
  font-size: 0.875rem;
  letter-spacing: 0.00625rem;
  line-height: 1.25rem;
  display: block;
  text-decoration: none;
  color: unset;
`;

const Address = styled.span`
  /* margin-bottom: 0; */
  margin-bottom: 1rem;
  font-size: 0.875rem;
  letter-spacing: 0.00625rem;
  line-height: 1.25rem;
`;

const StateAndZip = styled.span`
  /* margin-bottom: 0; */
  margin-bottom: 1rem;
  font-size: 0.875rem;
  letter-spacing: 0.00625rem;
  line-height: 1.25rem;
`;

const PHeading = styled.h2`
  color: ${({ theme }) => theme.palette.secondary.main};
  font-weight: bold;
  margin-bottom: 0.5rem;
`;

const BoldSpan = styled.span`
  font-weight: bold;
`;
