import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import Card from '../Shared/Card';
import TodoItem from '../Shared/TodoItem';
import { GET_PRECOURSE_STATUS } from './PreCourseButton';
import _get from 'lodash/get';

export default function PreCourseTodo({ applicationId }) {
  const { data, error } = useQuery(GET_PRECOURSE_STATUS, {
    variables: { applicationId },
  });
  if (error) console.dir(error);
  return (
    <Card span={2 / 3} variant="todo" header="to do-pre-course" defaultExpanded>
      <TodoItem
        complete={_get(data, 'application.enrollment.precourseCompleted')}
        to="pre-course-web-dev"
      >
        Hackbright Academy Pre-Course
      </TodoItem>
    </Card>
  );
}
