import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ErrorMessage } from 'formik';
import StyledInput from '../Styles/StyledInput';
import StyledLabel from '../Styles/StyledLabel';

export default function Input({ hideErrors, ...props }) {
  return (
    <StyledLabel htmlFor={props.name} grow={props.grow}>
      <ScreenReaderText>{props.placeholder}</ScreenReaderText>
      <StyledInput
        name={props.name}
        placeholder={props.placeholder}
        {...props}
      />
      {!hideErrors && (
        <>
          <ErrorMessage name={props.name}>
            {error => <ErrorSpan>{error}</ErrorSpan>}
          </ErrorMessage>
          <SpacingSpan>*</SpacingSpan>
        </>
      )}
    </StyledLabel>
  );
}

const ScreenReaderText = styled.span`
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
  white-space: nowrap; /* added line */
`;

export const SpacingSpan = styled.span`
  display: inline-block;
  visibility: hidden;
  margin-bottom: 0.75rem;
`;

export const ErrorSpan = styled.span`
  color: ${({ theme }) => theme.palette.warning.main};
  font-size: 0.75rem;
`;

Input.propTypes = {
  name: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  grow: PropTypes.string,
};

Input.defaultProps = {
  name: '',
  placeholder: '',
  grow: 'initial',
};
