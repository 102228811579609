import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import Loading from '../Shared/Loading';
import Card from '../Shared/Card';
import CardSection from '../Styles/CardSection';
import { useCurrentApplication } from '../utils/hooks';
import { GET_HOUSING_MANAGER } from './DallasCampusInfo';
import { useApplicationId } from '../Shared/ApplicationProvider';
import { useQuery } from '@apollo/react-hooks';
import _get from 'lodash/get';

export default function HousingWidget({ applicationId }) {
  const { loading, data, error } = useCurrentApplication();
  const id = useApplicationId();
  const housingManagerQuery = useQuery(GET_HOUSING_MANAGER, {
    variables: { id },
  });

  const email = _get(
    housingManagerQuery,
    'data.application.enrollment.housingManager.email',
  );
  if (loading) {
    return <Loading />;
  }
  if (error) {
    console.dir(error);
    return 'Error';
  }
  if (!_get(data, 'application.enrollment')) {
    console.dir('No housing info found for application.');
    return 'Error';
  }

  return (
    <Card span={1 / 3} header="Housing" defaultExpanded id="housing-agreement">
      <CardSection>
        <StyledDiv>
          <StyledHeading>Housing Status: </StyledHeading>
          <StyledSubHeading fontStyle="italic">
            {_get(data, 'application.enrollment.housingStatus')}
          </StyledSubHeading>
        </StyledDiv>
        {_get(data, 'application.enrollment.housingStatus') === 'Approved' && (
          <>
            <StyledDiv>
              <StyledHeading>Dates: </StyledHeading>
              <StyledSubHeading>{`${moment(
                _get(data, 'application.cohort.Date_Start__c'),
              ).format('MMMM D, YYYY')} - ${moment(
                _get(data, 'application.cohort.Date_End__c'),
              ).format('MMMM D, YYYY')}`}</StyledSubHeading>
            </StyledDiv>
            <StyledDiv>
              <StyledHeading>Location: </StyledHeading>
              <StyledSubHeading>{`${_get(
                data,
                'application.cohort.Location_City__c',
              )}, ${_get(
                data,
                'application.cohort.Location_State__c',
              )}`}</StyledSubHeading>
            </StyledDiv>
          </>
        )}
        <StyledParagraph>
          If you requested housing, you should receive confirmation within one
          week of your acceptance.
        </StyledParagraph>
        <StyledParagraph>
          If it&apos;s been more than a week since you were accepted and you
          have not received a confirmation email, please contact{' '}
          {email ? (
            <StyledA href={`mailto:${email}`}>{email}</StyledA>
          ) : (
            'your Housing Manager'
          )}{' '}
          with any questions or concerns.
        </StyledParagraph>
      </CardSection>
    </Card>
  );
}

const StyledHeading = styled.h2`
  font-weight: bold;
`;

const StyledSubHeading = styled.div`
  font-style: ${({ fontStyle }) => fontStyle};
  line-height: 1rem;
  padding-left: 0.25rem;
  font-size: 0.875rem;
`;

const StyledA = styled.a`
  color: ${({ theme }) => theme.palette.secondary.main};
  font-weight: bold;
  text-decoration: none;
`;

const StyledDiv = styled.div`
  display: flex;
  align-items: center;
  padding: 0.4rem 0;
`;

const StyledParagraph = styled.p`
  letter-spacing: 0.1px;
  line-height: 1.25rem;
  margin: 1rem 0;
`;
