import React from 'react';
import styled from 'styled-components';
import { connect, Field } from 'formik';
import { ReactComponent as ApplicationErrorIcon } from '../img/ApplicationErrorIcon.svg';
import _get from 'lodash/get';

const StyledInput = styled.input`
::placeholder {
  color: ${({ theme }) => theme.palette.gray[400]};
}
  -webkit-appearance: none;
  display: block;
  width: 100%;
  border: none;
  height: 3.125rem;
  padding: 1.0625rem 1rem;
  color: ${({ theme }) => theme.palette.text.input};
  background-color: unset;
  :focus {
    border-color: ${({ theme }) => theme.palette.gray[500]};
    background: ${({ theme }) => theme.palette.gray[50]};
    outline: none;
  }

  &[type="date"]::-webkit-inner-spin-button, &[type="date"]::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
}
`;

export const InputContainer = styled.div`
  border-radius: 2px;
  display: flex;
  align-items: center;
  border: 0.0625rem solid ${({ theme }) => theme.palette.gray[300]};
  font-size: 1rem;
  /* the focus-within pseudo-selector doesn't have
  good enough browser support, so we are using a 
  polyfill in App.js  */
  :focus-within, &.focus-within {
    border-color: ${({ theme }) => theme.palette.gray[500]};
    background: ${({ theme }) => theme.palette.gray[50]};
    outline: none;
  }
  width: 100%;
  color: ${({ theme }) => theme.palette.text.input};
  ${({ invalid, theme }) => invalid ? `
    border: 1px solid ${theme.palette.warning.main};
    background-color: ${theme.palette.gray[50]};
  ` : ''}
`

export const ErrorIconContainer = styled.span`
  height: 3.125rem;
  padding: 1.0625rem 1.375rem 1.0625rem 0;
`
export const EndAdornmentContainer = styled.span`
  height: 3.125rem;
  padding: 1.0625rem 1.375rem 1.0625rem 0;
`

function SmartStyledInput({ formik, endAdornment, ...props }) {
  const invalid = _get(formik, `errors.${props.name}`) && _get(formik, `touched.${props.name}`);
  return <InputContainer invalid={invalid} {...props}>
    {Object.keys(formik).length > 0 ? (
      <StyledInput as={Field} invalid={invalid} {...props} />
    ) : (
        <StyledInput {...props} invalid={invalid} />
      )}
    {endAdornment && <EndAdornmentContainer>{endAdornment}</EndAdornmentContainer>}
    {invalid && <ErrorIconContainer><ApplicationErrorIcon /></ErrorIconContainer>}
  </InputContainer>
}

export default connect(SmartStyledInput);
