import { ApolloProvider } from '@apollo/react-hooks';
import { defaultDataIdFromObject, InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloClient } from 'apollo-client';
import LogRocket from 'logrocket';
import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider } from 'styled-components';
import App from './App';
import * as serviceWorker from './serviceWorker';
import './Styles/reset.css';
import './Styles/globals.css';
import theme from './Styles/theme';
import { ApolloLink } from 'apollo-link';
import { onError } from 'apollo-link-error';
import { HttpLink } from 'apollo-link-http';
import * as Sentry from '@sentry/browser';

if (process.env.NODE_ENV === 'production') {
  LogRocket.init('9bvehh/dashboard-staging');
}

// Sentry setup
Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
});

// We may want to implement cache redirects at some point
// If we have one query that gets a list of items (ex. all a user's applications)
// and another query that gets just one of those items (ex. a user's webdev application)
// Apollo won't use the data from the cache automatically. This can fix that problem.
// Dropping the link here for easy access
// https://www.apollographql.com/docs/react/advanced/caching/#cache-redirects-with-cacheredirects
const cache = new InMemoryCache({
  freezeResults: true,
  dataIdFromObject: object => {
    if (object.Id) {
      return `${object.__typename}:${object.Id}`;
    }
    return defaultDataIdFromObject(object);
  },
  cacheRedirects: {
    Query: {
      application: (_, args, { getCacheKey }) => {
        return getCacheKey({ __typename: 'Application', id: args.id });
      },
      cohort: (_, args, { getCacheKey }) => {
        return getCacheKey({ __typename: 'Cohort', Id: args.id });
      },
    },
  },
});

const client = new ApolloClient({
  link: ApolloLink.from([
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        graphQLErrors.forEach(({ message, locations, path }) =>
          console.log(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
          ),
        );
      }
      if (networkError) console.log(`[Network error]: ${networkError}`);
    }),
    new HttpLink(),
  ]),
  cache,
  assumeImmutableResults: true,
});

ReactDOM.render(
  <ApolloProvider client={client}>
    <ThemeProvider theme={theme}>
      {/* <ScrollToTop> */}
      <App />
      {/* </ScrollToTop> */}
    </ThemeProvider>
  </ApolloProvider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
