import React from 'react';

export default function UpArrow(props) {
  return (
    <svg
      width="10px"
      height="7px"
      viewBox="0 0 10 7"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>F45AD607-17AA-418C-84AD-5CFA0C25DEF3_outlined</title>
      <desc>Created with sketchtool.</desc>
      <defs>
        <polygon
          id="path-1"
          points="13.825 6.82876039 10 10.599191 6.175 6.82876039 5 7.98952613 10 12.9289548 15 7.98952613"
        />
      </defs>
      <g
        id="Current-dashboards"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="onboarding_1_DONE"
          transform="translate(-332.000000, -3304.000000)"
        >
          <g id="Group-27" transform="translate(16.000000, 3276.000000)">
            <g id="Group-16">
              <g id="Group-6" transform="translate(0.000000, 1.000000)">
                <g
                  id="icon/navigation/expand_more_24px"
                  transform="translate(321.000000, 30.878858) rotate(-180.000000) translate(-321.000000, -30.878858) translate(311.000000, 21.000000)"
                >
                  <mask id="mask-2" fill="white">
                    <use xlinkHref="#path-1" />
                  </mask>
                  <g fillRule="nonzero" />
                  <g id="↳-Color" mask="url(#mask-2)" fill="currentColor">
                    <rect
                      id="Rectangle-13"
                      x="0"
                      y="0"
                      width="20"
                      height="19.7577152"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
