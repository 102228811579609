import React from 'react';
import styled from 'styled-components';
import * as Sentry from '@sentry/browser';
import { isRedirect } from '@reach/router';

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: isRedirect(error) ? false : true };
  }

  componentDidCatch(error, errorInfo) {
    // Log error using sentry
    if (isRedirect(error)) {
      throw error;
    } else {
      Sentry.captureException(error);
    }
  }

  render() {
    if (this.state.hasError) {
      return (
        <ErrorContainer>
          <ErrorText>
            Oops, something went wrong. Our development team has been notified.
            If this error persists, please email{' '}
            <a href="mailto:admissions@hackbrightacademy.com">
              admissions@hackbrightacademy.com
            </a>
            .
          </ErrorText>
        </ErrorContainer>
      );
    }

    return this.props.children;
  }
}

const ErrorContainer = styled.main`
  /* 100vh - header - footer */
  min-height: calc(100vh - 70px - 70px);
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
`;

const ErrorText = styled.p`
  text-align: center;
  font-size: 1.13em;
  line-height: 1.5em;
`;
