import React, { useContext, useState } from 'react';
import ReactModal from 'react-modal';
import styled from 'styled-components';
import StyledSection from '../Styles/StyledSection';
import StyledH2 from '../Styles/StyledH2';
import StyledButton, { UnstyledButton } from '../Styles/StyledButton';
import StyledP from '../Styles/StyledP';
import bullet from '../img/bullet.svg';
import ArrowRight from '../img/ArrowRight';
import StyledA from '../Styles/StyledA';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { ApplicationContext } from '../Shared/ApplicationProvider';
import Loading from '../Shared/Loading';
import SaveAndContinue from '../Shared/SaveAndContinue';
import { SectionContainer, Content } from './Contact';
import WarningModal from '../Shared/WarningModal';
import MinusIcon from '../img/MinusIcon';
import PlusIcon from '../img/PlusIcon';
import _get from 'lodash/get';

const GET_SKILL_REVIEW_COMPLETION = gql`
  query GetSkillReviewCompletion($id: String!) {
    application(id: $id) {
      id
      skillReviewLink
      complete {
        skillReviewSubmitted
      }
    }
  }
`;

export default function SkillReview() {
  const applicationId = useContext(ApplicationContext);
  const [isOpen, setIsOpen] = useState(false);
  const [showFAQ, setShowFAQ] = useState(false);
  const { loading, data } = useQuery(GET_SKILL_REVIEW_COMPLETION, {
    variables: { id: applicationId },
  });
  if (loading) {
    return <Loading />;
  }

  ReactModal.setAppElement('#root');

  const closeModal = () => setIsOpen(false);

  return (
    <>
      <WarningModal
        isOpen={isOpen}
        closeModal={closeModal}
        heading="Oops...Mobile not supported"
        body="HackerRank does not support testing on a mobile phone. Please continue with a laptop or desktop computer using Google Chrome or Mozilla Firefox."
      />
      <StyledSection>
        <SectionContainer>
          <StyledH2>Tech Review</StyledH2>
          <Content>
            {_get(data, 'application.complete.skillReviewSubmitted') ? (
              <>
                <StyledP variant="paragraph">
                  Your application is now complete and your admissions decision
                  should be ready in 1-2 business days. You should hear from us
                  soon, and keep an eye on your Approach Dashboard for any
                  notifications.
                </StyledP>
                <StyledP variant="paragraph">
                  Good luck! (We might be just a bit biased, but we&apos;re
                  really rooting for you.)
                </StyledP>
              </>
            ) : (
              <>
                <StyledP>
                  Let&apos;s get a sense for your skills and learning style.
                  Don&apos;t panic. We know most of our applicants aren&apos;t
                  experts. This is just to see where you&apos;re at. For this
                  you&apos;ll need:
                </StyledP>
                <StyledUl>
                  <StyledLi>A laptop or desktop computer</StyledLi>
                  <StyledLi>30 minutes without interruptions</StyledLi>
                  <StyledLi>Scratch paper</StyledLi>
                </StyledUl>
                <ModifiedP marginBottom="2rem;">
                  <b>
                    The tech review is required, and your application will not
                    be complete without it.
                  </b>
                </ModifiedP>
                <DesktopDiv>
                  <StyledA
                    as="a"
                    href={_get(data, 'application.skillReviewLink')}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ textDecoration: 'none' }}
                  >
                    <ModifiedButton>
                      <img
                        alt=""
                        src={bullet}
                        style={{ visibility: 'hidden' }}
                      />
                      <span>Begin Tech Review</span>
                      <ArrowRight />
                    </ModifiedButton>
                  </StyledA>
                </DesktopDiv>
                <MobileDiv>
                  <ModifiedButton onClick={() => setIsOpen(true)}>
                    <img alt="" src={bullet} style={{ visibility: 'hidden' }} />
                    <span style={{ marginRight: '1rem' }}>
                      Begin Tech Review
                    </span>
                    <ArrowRight />
                  </ModifiedButton>
                </MobileDiv>
                <FAQ>
                  <FAQHeader>
                    <h2>Tech Review FAQ</h2>
                    <FAQButton onClick={() => setShowFAQ(show => !show)}>
                      {showFAQ ? <MinusIcon /> : <PlusIcon />}
                    </FAQButton>
                  </FAQHeader>
                  {showFAQ && (
                    <FAQContent>
                      <p>
                        The link above will take you to an external site,
                        HackerRank, where you will complete a short skill
                        review.
                      </p>
                      <p>
                        Before you begin you'll need to create an account with
                        HackerRank. During this process be sure to select "share
                        my profile with Hackbright Academy."{' '}
                      </p>
                      <p>
                        You will be automatically directed back to your student
                        dashboard after submitting your tech review.
                      </p>
                      <p style={{ color: 'rgba(1,119,169,1)' }}>
                        We believe in you. Relax and do your best.{' '}
                      </p>
                    </FAQContent>
                  )}
                </FAQ>
              </>
            )}
          </Content>
        </SectionContainer>
        <SectionContainer>
          <Content>
            <SaveAndContinue asLink to="/dashboard" />
          </Content>
        </SectionContainer>
      </StyledSection>
    </>
  );
}

const DesktopDiv = styled.div`
  @media (max-width: 1024px) {
    display: none;
  }
`;

const MobileDiv = styled.div`
  @media (min-width: 1024px) {
    display: none;
  }
`;

const FAQ = styled.aside`
  background: rgba(247, 247, 247, 1);
  border-radius: 1px;
  margin: 3rem 0;
`;

const FAQContent = styled.section`
  color: rgba(166, 166, 166, 1);
  font-size: 14px;
  line-height: 22px;
  padding: 1.5rem;
  p {
    margin: 1.5rem 0;
  }
  p:first-child {
    margin-top: 0;
  }
  p:last-child {
    margin-bottom: 0;
  }
`;

const FAQHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: rgba(1, 119, 169, 1);
  padding: 0.8125rem 1rem;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: -0.08px;
  & ~ ${FAQContent} {
    border-top: 1px solid rgba(231, 231, 231, 1);
  }
`;

const FAQButton = styled(UnstyledButton)`
  height: 24px;
  color: rgba(1, 119, 169, 1);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledLi = styled.li`
  // display: flex;
  color: ${({ theme }) => theme.colors.darkestgray};
  list-style-type: disc;
  padding: 0.5rem 0;
  line-height: 1.375rem;
  font-size: 15px;
  // &:before {
  //   content: '';
  //   margin-right: 0.5rem;
  //   background-repeat: no-repeat;
  //   background-position: center;
  //   height: 1.5rem;
  //   width: 1.5rem;
  //   display: block;
  //   background-image: url(${bullet});
  // }
`;

const ModifiedP = styled(StyledP)`
  @media (min-width: 1024px) {
    font-size: 0.9375rem;
    letter-spacing: 0.005rem;
  }
`;

const ModifiedButton = styled(StyledButton)`
  width: 100%;
  display: flex;
  background: ${({theme}) => theme.palette.primary.dark};
  border: 0.09375rem solid ${({ theme }) => theme.palette.primary.dark};
  color: ${({ theme }) => theme.palette.secondary.main};
  font-weight: bold;
  justify-content: space-between;
  align-items: center;

  &:not(:disabled):active {
    background: ${({ theme }) => theme.palette.primary.light};
    border: 0.09375rem solid ${({ theme }) => theme.palette.primary.light};
  }

  &:not(:disabled):hover {
    background: ${({ theme }) => theme.palette.primary.light};
    border: 0.09375rem solid ${({ theme }) => theme.palette.primary.light};
  }
`;

const StyledUl = styled.ul`
  /* margin-left: 2rem; */
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding-inline-start: 2rem;
`;
