const primary = {
  main: '#fb3530',
  light: '#ff4004',
  dark: '#ee1431',
}

const gray = {
  50: '#fafafa',
  100: '#f0f0f0',
  300: '#e2e2e2',
  400: '#b2b2b2',
  500: '#999999',
  700: '#57585b',
  900: '#252525'
}

const theme = {
  palette: {
    background: {
      primary: '#ffffff',
    },
    gray,
    secondary: {
      main: '#ffffff',
      dark: gray[300],
    },
    text: {
      primary: '#808080',
      secondary: gray[500],
      input: gray[700],
    },
    primary,
    gradient: `linear-gradient(-225deg, ${primary.light} 0%, ${primary.dark} 100%)`,
    warning: {
      main: '#d50000'
    }
  },
  typography: {
    primary: 'Lato',
    secondary: 'Montserrat'
  },

  colors: {
    black: 'rgba(37, 37, 37, 1)',
    blue: 'rgba(1,119,169,1)',
    darkblue: 'rgba(0, 48, 90, 1)', // payment button
    darkgray: 'rgba(135,135,135,1)',
    darkestgray: 'rgba(85,85,85,1)',
    lightblue: 'rgba(14,182,254,1)', // *
    lightgray: 'rgba(216, 216, 216, 1)',
    lightestgray: 'rgba(231,231,231,1)',
    midgray: 'rgba(166,166,166,1)',
    red: 'rgba(204, 18, 23, 1)',
    lightGreen: 'rgba(12,202,142,1)',
    midgreen: 'rgba(139,216,50,1)',
    white: 'rgba(255,255,255,1)',
    mediumgray: 'rgba(248,248,248,1)',
    mediumBlue: 'rgba(1,146,208,1)', // active lightblue button
  },
  fonts: {
    libFrank: 'Lato',
    rale: 'Montserrat',
  },
};
export default theme;
