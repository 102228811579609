import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import styled from 'styled-components';
import StyledCheckbox from '../Styles/StyledCheckbox';
import StyledRadio from '../Styles/StyledRadio';

export default function Checkbox({ label, variant, labelStyle, ...props }) {
  return (
    <StyledLabel htmlFor={props.id || props.name} style={labelStyle}>
      <Field {...props}>
        {({ field }) => {
          const Component = variant === 'radio' ? StyledRadio : StyledCheckbox;

          return (
            <Component
              {...field}
              {...props}
              type={variant || 'checkbox'}
              checked={
                variant === 'radio' ? field.value === props.value : field.value
              }
            />
          );
        }}
      </Field>
      {label && <StyledSpan style={props.labelStyle}>{label}</StyledSpan>}
    </StyledLabel>
  );
}

const StyledLabel = styled.label`
  display: flex;
  align-items: center;
  padding: 0.625rem 0;
  line-height: 1.125rem;
  font-size: 0.9375rem;
  letter-spacing: 0;
`;

const StyledSpan = styled.span`
`;

Checkbox.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  variant: PropTypes.string,
  labelStyle: PropTypes.objectOf(PropTypes.string),
};

Checkbox.defaultProps = {
  label: '',
  name: '',
  variant: '',
  labelStyle: {},
};
