import React, { useContext, useEffect } from 'react';
import DashboardNavigation from '../Shared/DashboardNavigation';
import AdmissionAcceptance from './AdmissionAcceptance';
import AdmissionConditionalAcceptance from './AdmissionConditionalAcceptance';
import StyledSection from '../Styles/StyledSection';
import _get from 'lodash/get';
import { ApplicationContext } from '../Shared/ApplicationProvider';
import { useQuery, useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';

const VIEW_ADMISSIONS_DECISION = gql`
  mutation ViewAdmissionsDecision($id: String!) {
    viewAdmissionsDecision(id: $id)
  }
`;

const VIEWED_ADMISSIONS_DECISION = gql`
  query ViewedAdmissionsDecision($id: String!) {
    application(id: $id) {
      id
      Status__c
      viewedDecision
    }
  }
`;

export default function AdmissionsDecision(props) {
  // const { data } = useCurrentApplication();
  // const status = _get(data, 'application.Status__c', '');

  const applicationId = useContext(ApplicationContext);
  const { data } = useQuery(VIEWED_ADMISSIONS_DECISION, {
    variables: { id: applicationId },
  });

  const status = _get(data, 'application.Status__c');

  const [mutate] = useMutation(VIEW_ADMISSIONS_DECISION, {
    variables: { id: applicationId },
    update: cache => {
      const previous = cache.readQuery({
        query: VIEWED_ADMISSIONS_DECISION,
        variables: { id: applicationId },
      });

      cache.writeQuery({
        query: VIEWED_ADMISSIONS_DECISION,
        variables: { id: applicationId },
        data: {
          application: { ...previous.application, viewedDecision: Date.now() },
        },
      });
    },
  });

  // We want to mark the admissions decision
  // as viewed as soon as they access this page
  useEffect(() => {
    // but only if it hasn't already been set in the past
    // this query should get the result
    if (!_get(data, 'application.viewedDecision')) {
      mutate();
    }
  }, [applicationId, data, mutate]);

  return (
    <>
      <StyledSection variant="dashboard">
        <div>
          <DashboardNavigation section="Admissions Decision" />
          {/* Conditionally render admission decision based on acceptance status */}
          {status === 'Accepted' && <AdmissionAcceptance />}
          {status === 'Soft_Deny' && <AdmissionConditionalAcceptance />}
        </div>
      </StyledSection>
    </>
  );
}
