import React from 'react';

export default function OpenLetter(props) {
  return (
    <svg
      width="42px"
      height="42px"
      viewBox="0 0 42 42"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>Group 11</title>
      <desc>Created with Sketch.</desc>
      <g
        id="OpenLetter-svgs-jonathan"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="OpenLetter-Artboard"
          transform="translate(-87.000000, -140.000000)"
        >
          <g
            id="OpenLetter-Group-11"
            transform="translate(88.000000, 141.000000)"
          >
            <circle
              id="OpenLetter-Oval-16"
              stroke="#fb3530"
              fill="#fb3530"
              cx="20"
              cy="20"
              r="20"
            />
            <g
              id="OpenLetter-email-document"
              transform="translate(8.000000, 8.000000)"
            >
              <g id="OpenLetter-Frame_-_24px">
                <rect
                  id="OpenLetter-Rectangle"
                  x="0"
                  y="0"
                  width="24"
                  height="24"
                />
              </g>
              <g
                id="OpenLetter-Line_Icons"
                transform="translate(1.000000, 2.000000)"
                fill="#FFFFFF"
                fillRule="nonzero"
              >
                <g id="OpenLetter-Group">
                  <path
                    d="M18.998,20 L2.998,20 C1.895,20 0.998,19.103 0.998,18 L0.998,9 C0.998,8.612 1.223,8.259 1.574,8.095 C1.924,7.93 2.341,7.984 2.638,8.232 L8.36,13 L13.636,13 L19.358,8.231 C19.656,7.983 20.07,7.93 20.422,8.094 C20.773,8.258 20.9970008,8.612 20.9970008,8.999 L20.9970008,17.999 C20.998,19.103 20.102,20 18.998,20 Z M2.998,11.135 L2.998,18 L19,18 L18.999,11.135 L14.639,14.769 C14.459,14.918 14.232,15 13.998,15 L7.998,15 C7.765,15 7.538,14.918 7.358,14.769 L2.998,11.135 Z"
                    id="OpenLetter-Shape"
                  />
                  <polygon
                    id="OpenLetter-Path"
                    points="18.998 7 16.998 7 16.998 2 4.998 2 4.998 7 2.998 7 2.998 0 18.998 0"
                  />
                  <rect
                    id="OpenLetter-Rectangle"
                    x="6.998"
                    y="4"
                    width="8"
                    height="2"
                  />
                  <rect
                    id="OpenLetter-Rectangle"
                    x="6.998"
                    y="7"
                    width="6"
                    height="2"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
