import React from 'react';

export default function SuccessIcon(props) {
  return (
    <svg
      width="18px"
      height="14px"
      viewBox="0 0 18 14"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="success-icon"
      // xmlns:xlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      {/* <!-- Generator: Sketch 54.1 (76490) - https://sketchapp.com --> */}
      <title>icon / navigation / check / success / 24px</title>
      <desc>Created with Sketch.</desc>
      <defs>
        <polygon
          id="path-1-success"
          points="9 16.17 4.83 12 3.41 13.41 9 19 21 7 19.59 5.59"
        />
      </defs>
      <g id="svgs" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="SVG'S" transform="translate(-220.000000, -295.000000)">
          <g
            id="icon/navigation/check_24px"
            transform="translate(217.000000, 290.000000)"
          >
            <mask id="mask-2-success" fill="white">
              <use xlinkHref="#path-1-success" />
            </mask>
            <g fillRule="nonzero" />
            <g
              id="✱-/-Color-/-Icons-/-Green-/-Success"
              mask="url(#mask-2-success)"
              fill="currentColor"
              fillRule="evenodd"
            >
              <rect id="Rectangle" x="0" y="0" width="24" height="24" />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
