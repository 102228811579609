import React from 'react';

export default function ComputerScreenFilled({ fill = 'currentColor' }) {
  return (
    <svg width="20px" height="18px" viewBox="0 0 20 18" version="1.1">
      <title>computer-screen-1</title>
      <desc>Created with Sketch.</desc>
      <g
        id="Application-Mobile-4"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="New-Progress-Bar-Skill-Review"
          transform="translate(-309.000000, -89.000000)"
        >
          <g id="Group-6" transform="translate(0.000000, 86.000000)">
            <g id="Group-5" transform="translate(263.000000, 0.000000)">
              <g id="Group-2" transform="translate(11.500000, 0.000000)">
                <g
                  id="computer-screen-1"
                  transform="translate(32.500000, 0.000000)"
                >
                  <g id="Frame_-_24px">
                    <rect
                      id="Rectangle"
                      x="0"
                      y="0"
                      width="24"
                      height="24"
                    ></rect>
                  </g>
                  <g
                    id="Filled_Icons"
                    transform="translate(2.000000, 3.000000)"
                    fill={fill}
                    fillRule="nonzero"
                  >
                    <g id="Group">
                      <path
                        d="M20,11.1428571 L20,1.71428571 C20,0.768857143 19.2525,0 18.3333333,0 L1.66666667,0 C0.7475,0 0,0.768857143 0,1.71428571 L0,11.1428571 L20,11.1428571 Z"
                        id="Path"
                      ></path>
                      <path
                        d="M0,12 L0,12.8571429 C0,13.8025714 0.7475,14.5714286 1.66666667,14.5714286 L9.16666667,14.5714286 L9.16666667,16.2857143 L5.83333333,16.2857143 L5.83333333,18 L14.1666667,18 L14.1666667,16.2857143 L10.8333333,16.2857143 L10.8333333,14.5714286 L18.3333333,14.5714286 C19.2525,14.5714286 20,13.8025714 20,12.8571429 L20,12 L0,12 Z"
                        id="Path"
                      ></path>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
