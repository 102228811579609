import React from 'react';
import ReactModal from 'react-modal';
import {
  modalStyle,
  ModalP,
  ModalButton,
  ModalButtonRow,
  ModalX,
  ModalIconContainer,
  ModalHeading,
} from '../Styles/modalStyles';
import YellowAlert from '../img/YellowAlert.svg';

export default function WarningModal({ isOpen, closeModal, heading, body }) {
  return (
    <ReactModal style={modalStyle} isOpen={isOpen} onRequestClose={closeModal}>
      <ModalX onClick={closeModal} />
      <ModalIconContainer>
        <img src={YellowAlert} alt="Oops" />
        <ModalHeading>{heading}</ModalHeading>
      </ModalIconContainer>
      <ModalP>{body}</ModalP>
      <ModalButtonRow>
        <ModalButton onClick={closeModal}>OK</ModalButton>
      </ModalButtonRow>
    </ReactModal>
  );
}
