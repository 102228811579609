import styled from 'styled-components';
import { Field } from 'formik';
import arrow from '../img/arrow.svg';

export default styled(Field)`
  background: url(${arrow});
  background-repeat: no-repeat;
  background-position: calc(100% - 16px) center;
  border-radius: 0;
  border: 0.0625rem solid ${({ theme, invalid }) => invalid ? theme.palette.warning.main : theme.palette.gray[300]};
  font-size: 1rem;
  line-height: 3.125rem;
  padding: 0 1rem;
  width: 100%;
  height: 3.125rem;
  appearance: none;
  color: ${({ theme }) => theme.palette.text.input};
  &[data-value=''] {
    color: ${({ theme }) => theme.palette.gray[400]};
  }
  &:focus {
    border-color: ${({ theme }) => theme.palette.gray[500]};
    background-color: ${({theme}) => theme.palette.gray[50]};
    outline: none;
  }
`;
