import React from 'react';
import styled from 'styled-components';
import { UnstyledButton } from './StyledButton';
import closeModal from '../img/close_24px_outlined.svg';
import StyledH2 from './StyledH2';

export const modalStyle = {
  overlay: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(255,255,255,0.9)',
  },
  content: {
    maxWidth: '450px',
    position: 'static',
    margin: '2.5rem',
    padding: 0,
    borderRadius: '0.0625rem',
    boxShadow:
      '0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2)',
  },
};

export const ModalP = styled.p`
  font-size: 0.875rem;
  padding: 1.5rem 1rem;
  border-bottom: 1px solid ${({ theme }) => theme.colors.lightestgray};
  color: ${({ theme }) => theme.colors.darkestgray};
  line-height: 1.375rem;
  letter-spacing: -0.005rem;
`;

export const ModalButton = styled(UnstyledButton)`
  font-size: 0.875rem;
  line-height: 2rem;
  margin: 0.25rem 1rem;
  color: ${({ theme }) => theme.palette.secondary.dark};
`;

export const ModalButtonRow = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export function ModalX(props) {
  return (
    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
      <ModifiedButton type="button" {...props}>
        <img
          src={closeModal}
          alt="close modal"
          style={{ width: '1.1875rem' }}
        />
      </ModifiedButton>
    </div>
  );
}

const ModifiedButton = styled(UnstyledButton)`
  padding: 0.75rem;
  font-family: ${({ theme }) => theme.typography.primary};
  color: ${({ theme }) => theme.colors.darkestgray};
`;

export const ModalIconContainer = styled.div`
  text-align: center;
`;
export const ModalHeading = styled(StyledH2)`
  margin-bottom: 0;
  @media (min-width: 1024px) {
    text-align: unset;
    margin-top: 1.5rem;
    margin-right: 1rem;
    padding: unset;
  }
`;
