import React from 'react';
import styled from 'styled-components';

const Span = styled.span`
  display: ${({ flex }) => (flex ? 'flex' : 'initial')};
  align-items: center;
  justify-content: center;
`;

const Bar = styled.span`
  height: 0.375rem;
  flex-grow: 1;
  border-radius: 0.25rem;
  background-color: #eaeaea;
  display: flex;
  margin-left: 0.25rem;
`;

const Filled = styled.span`
  transition: width 300ms;
  border: 0.0625rem solid ${({theme})=>theme.palette.primary.main};
  border-radius: 0.25rem;
  background-color: ${({theme})=>theme.palette.primary.main};
  width: ${({ width }) => width}%;
`;

export default function ProgressBar(props) {
  const { percentage } = props;
  return (
    <Span
      flex
      role="progressbar"
      aria-valuenow={props.percentage}
      aria-valuemin="0"
      aria-valuemax="100"
    >
      {`${props.percentage}%`}
      <Bar>
        <Filled width={percentage} />
      </Bar>
    </Span>
  );
}
